import { makeSelection, calculateTime, removeMarkDeleted, moveCursorToBoundary } from '../util/utility'

/**
 * user should be able to restore ; remove deleted mark
 */
export function restore (state, dispatch, view) {
  makeSelection(view.state, view.dispatch)
  const slice = view.state.selection.content()
  calculateTime(slice, 1)
  for (let i = 0; i < view.state.selection.ranges.length; i++) {
    let { $from, $to } = view.state.selection.ranges[i]
    removeMarkDeleted(view.state, view.dispatch, $from.pos, $to.pos)
  }
  moveCursorToBoundary(view)
}
