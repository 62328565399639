import { Plugin, TextSelection } from 'prosemirror-state'
import './selectionToolbar.css'
import { removeThisWord } from '../commands'
import crel from 'crel' // crel is used for creating dom elements
import store from '@/services/store'
import { myProseEditor } from '@/view/voiceEditor/proseEditor/util/utility'

// Step:1. Make a view component to tie to editor's life cycle
// used when pluign needs to interact with the editor view, or set something up in the DOM
let musicIconHovered = false
export const musicHoverToolbar = new Plugin({
  view (editorView) {
    return new MusicHoverTooltip(items, editorView)
  },
  props: {
    handleDOMEvents: {
      // hover
      mouseover: (view, event) => {
        return false
        // let loc = view.posAtCoords({
        //   left: event.clientX,
        //   top: event.clientY
        // })
        // if (loc) {
        //   let resolvePos = view.state.doc.resolve(loc.pos)
        //   // baisc validation for edge case
        //   if ((resolvePos.nodeBefore != null && resolvePos.nodeBefore.attrs != null && resolvePos.nodeBefore.attrs.fadeIn != null) ||
        //     (resolvePos.nodeAfter != null && resolvePos.nodeAfter.attrs != null && resolvePos.nodeAfter.attrs.fadeIn != null)) {
        //     musicIconHovered = true
        //     let newPos = 0
        //     // get music id and duration
        //
        //     // music added in background
        //     if (resolvePos.nodeBefore != null && resolvePos.nodeBefore.attrs != null && resolvePos.nodeBefore.attrs.fadeIn != null) {
        //       window.selectedMusicUID = resolvePos.nodeBefore.marks[0].attrs.uid
        //       window.musicDuration = resolvePos.nodeBefore.marks[0].attrs.aend - resolvePos.nodeBefore.marks[0].attrs.astart
        //
        //       // music added inline -> all the nodes after with music attr
        //     } else if (resolvePos.nodeAfter != null && resolvePos.nodeAfter.attrs != null && resolvePos.nodeAfter.attrs.fadeIn != null) {
        //       resolvePos.nodeAfter.marks.forEach(mark => {
        //         if (mark.attrs.uid != null) {
        //           window.selectedMusicUID = mark.attrs.uid
        //           window.musicDuration = mark.attrs.aend - mark.attrs.astart
        //         }
        //       })
        //       newPos = resolvePos.nodeAfter.nodeSize
        //     }
        //     newPos = view.state.doc.resolve(loc.pos + newPos)
        //     // set position when blockmode is open
        //     if (view.dispatch && view.state.selection.empty) {
        //       view.dispatch(
        //         view.state.tr.setSelection(new TextSelection(newPos, newPos))
        //       )
        //     }
        //   } else {
        //     musicIconHovered = false
        //   }
        // }
      }
    }
  }
})

class MusicHoverTooltip {
  constructor (items, view) {
    this.items = items
    this.view = view

    this.tooltip = document.createElement('div')
    // this.tooltip.className = 'tooltip'
    this.tooltip.className = 'selection-toolbar'
    this.tooltip.id = 'musicHoverToolbar'
    items.forEach(({ dom }) => this.tooltip.appendChild(dom))
    if (view.dom.parentNode) {
      view.dom.parentNode.appendChild(this.tooltip)
    }
    this.update(view, null)

    this.tooltip.addEventListener('mousedown', e => {
      e.preventDefault()
      view.focus()
      items.forEach(({ command, dom }) => {
        if (dom.contains(e.target)) { command(view.state, view.dispatch, view) }
      })
    })

    // hide toolbar when editor not focused
    this.view.dom.addEventListener('blur', this.hide.bind(this))
  }

  // Step:2. Use prosemirror's update cycle to make sure tooltip stays in sync with editor
  // called when views's state is updated
  update (view, lastState) {
    // TODO: remove use of store
    if (myProseEditor && myProseEditor.audioControl && myProseEditor.audioControl.isPlaying) {
      this.hide()
      return
    }
    if (!musicIconHovered) {
      this.hide()
      return
    }
    let state = view.state
    // Don't do anything if the document/selection didn't change
    if (lastState && lastState.doc.eq(state.doc) &&
          lastState.selection.eq(state.selection)) return

    // Hide the tooltip if the selection is empty
    if (!state.selection.empty) {
      this.hide()
      return
    }

    // Otherwise, reposition it and update its content
    this.tooltip.style.display = ''
    let { from } = state.selection
    // These are in screen coordinates
    let start = view.coordsAtPos(from)
    // let end = view.coordsAtPos(to)
    // console.log('start,end', start, end)
    // The box in which the tooltip is positioned, to use as base
    // let box = this.tooltip.offsetParent.getBoundingClientRect()
    // console.log('box', box)
    // Find a center-ish x position from the selection endpoints (when
    // crossing lines, end may be more to the left)
    // let left = Math.max((start.left + end.left) / 2, start.left + 3)
    // this.tooltip.style.left = (left - box.left) + 'px'
    // this.tooltip.style.bottom = (box.bottom - start.top) + 'px'
    // this.tooltip.textContent = to - from

    // check if item is to be made active or not
    // this.items.forEach(({command, dom}) => {
    //   let active = command(this.view.state, null, this.view)
    //   dom.style.display = active ? '' : 'none'
    // })
    let left = start.left + 20
    let top = (start.top - 40)
    // console.log('setting left,top', left, top)
    this.tooltip.style.left = left + 'px'
    this.tooltip.style.top = top + 'px'
  }

  hide (event) {
    if (event && event.relatedTarget) {
      return
    }
    this.tooltip.style.display = 'none'
  }

  destroy () {
    this.view.dom.removeEventListener('blur', this.hide)
    this.tooltip.remove()
  }
}

const items = [
  { command: showTrackMode, dom: crel('div', { 'class': 'text-white px-3 py-0 bg-primary hover:bg-primary-900 first:rounded-l-lg last:rounded-r-lg' }, 'Arrange') },
  { command: removeThisWord, dom: icon('toolbarIcon toolbarDelete', 'Remove Audio') }
]

// Helper function to create menu icons
function icon (text, name) {
  let element = crel('div', { 'class': 'text-base text-white px-2 py-2.5 bg-primary hover:bg-primary-900 first:rounded-l-lg last:rounded-r-lg', 'title': name },
    crel('div', { 'class': text })
  )
  return element
}
function showTrackMode () {
  store.dispatch('modal/showBlockMode')
}
