import { Decoration, DecorationSet } from 'prosemirror-view'
import { Plugin } from 'prosemirror-state'
import { hasMark } from '../util/utility'
// Words you probably shouldn't use
const badWords = /\b(uh|um)\s/ig
/**
 * decoration plugin
 * When we have uh, um -> they will be marked in red
 */

function lint (doc) {
  let result = []

  function record (msg, from, to, fix, marks) {
    result.push({ msg, from, to, fix, marks })
  }

  // For each node in the document
  doc.descendants((node, pos) => {
    // push all the words and their location in result
    if (node.isText) {
      // Scan text nodes for suspicious patterns
      let m = badWords.exec(node.text)
      while (m) {
        record(`Try not to say '${m[0]}'`,
          pos + m.index, pos + m.index + m[0].length, '', node.marks)
        m = badWords.exec(node.text)
      }
    }
  })

  return result
}

function lintDeco (doc) {
  let decos = []
  lint(doc).forEach(prob => {
    if (window.mode === 'editTranscript') {
      decos.push(Decoration.inline(prob.from, prob.to, { class: 'hide' }))
    } else if (hasMark(prob.marks, 'deleted')) {
      // do nothing
    } else {
      decos.push(Decoration.inline(prob.from, prob.to, { class: 'filler' })) // filler class assigned to uh/um
    }
  })
  return DecorationSet.create(doc, decos)
}

/**
 * decoration plugin
 */
export function colorFillerWords () {
  return new Plugin({
    state: {
      init (_, { doc }) { return lintDeco(doc) },
      apply (tr, old) { return tr.docChanged ? lintDeco(tr.doc) : old }
    },
    props: {
      decorations (state) { return this.getState(state) }
    }
  })
}
