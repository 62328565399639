/**
 * interact js provide us access to drag
 * create a constraint env
 * we can mark eliment interactive
 */
import interact from 'interactjs'

import { hideVolumeControls, showVolumeControls, updateVolumeControlsPosition } from './volumeLevel'
const getEl = idName => document.getElementById(idName)
// creating svg content standards
const sns = 'http://www.w3.org/2000/svg'
const xns = 'http://www.w3.org/1999/xlink'

const polygons = []
let rootMatrix = []
let circleHandles = []
let lineHandles = []
let volumeHandles = []
let volumeEnvelopeDataList = []
let blockList = []
let fullBlockList = []
let proseView = null

export function setVolumeEnvelopeDataList (newVolumeEnvelopeDataList) {
  // console.log('setVolumeEnvelopeDataList', JSON.stringify(newVolumeEnvelopeDataList))
  volumeEnvelopeDataList = newVolumeEnvelopeDataList
}
export function setBlockList (newBlockList) {
  blockList = newBlockList
}
export function setFullBlockList (newFullBlockList) {
  fullBlockList = newFullBlockList
}
export function setProseView (view) {
  proseView = view
}
export function resetPoints () {
  rootMatrix = []
  circleHandles = []
  lineHandles = []
}
export function hideArrowHandles () {
  let arrowHandleElements = document.getElementsByClassName('.arrowHandle')
  Array.from(arrowHandleElements).forEach(function (element) {
    element.parentNode.removeChild(element)
  })
}
export function createPoints (svgIndex) {
  /**
   * create a polygon with 4 points for a index
   */
  let polygon = getEl('poly-' + svgIndex) // polygon has information about points  check Blockmode.vue
  polygons[svgIndex] = polygon
  circleHandles[svgIndex] = []
  // originalPoints[svgIndex] = []
  lineHandles[svgIndex] = []
  volumeHandles[svgIndex] = []
  let svg = getEl('svg-' + svgIndex) // polygon.parentElement
  let polygonPoints = []
  for (let i = 0, len = polygon.points.numberOfItems; i < len; i++) {
    let polygonPoint = polygon.points.getItem(i)
    polygonPoints[i] = polygonPoint
    let circleHandle = document.createElementNS(sns, 'use')
    circleHandle.x.baseVal.value = polygonPoint.x
    circleHandle.y.baseVal.value = polygonPoint.y
    circleHandle.setAttributeNS(xns, 'href', '#circle-handle') // create points

    let volumeHandle = document.createElementNS(sns, 'use')
    volumeHandle.x.baseVal.value = polygonPoint.x
    volumeHandle.y.baseVal.value = polygonPoint.y
    volumeHandle.setAttributeNS(xns, 'href', '#volumeControl') // point for volume
    volumeHandle.setAttribute('display', 'none')

    let arrowHandle = document.createElementNS(sns, 'use')
    arrowHandle.x.baseVal.value = polygonPoint.x
    arrowHandle.y.baseVal.value = polygonPoint.y
    arrowHandle.setAttributeNS(xns, 'href', '#arrow-handle')
    arrowHandle.classList.add('.arrowHandle')

    if (i === 0 || i === 3) { // || i === 4 || i === 7) { // end corner circles
      circleHandle.setAttribute('class', 'circle-handle end-circle-handle')
    } else {
      circleHandle.setAttribute('class', 'circle-handle')
    }
    circleHandle.setAttribute('circle-index', i + '')
    circleHandle.setAttribute('svg-index', svgIndex)
    if (i !== 1 && i !== 2) {
      circleHandle.setAttribute('display', 'none')
      arrowHandle.setAttribute('display', 'none')
    }
    if (i !== 2) {
      arrowHandle.setAttribute('display', 'none')
    }

    circleHandles[svgIndex][i] = circleHandle
    volumeHandles[svgIndex][i] = volumeHandle

    svg.appendChild(circleHandle)
    svg.appendChild(arrowHandle)
    svg.appendChild(volumeHandle)

    // let newPoint = polygon.parentElement.createSVGPoint()
    // newPoint.x = point.x
    // newPoint.y = point.y
    // originalPoints[svgIndex].push(newPoint)

    lineHandles[svgIndex] = [
      svg.querySelector('.up-line'),
      svg.querySelector('.down-line')
    ]
    lineHandles[svgIndex][0].setAttribute('svg-index', svgIndex)
    lineHandles[svgIndex][1].setAttribute('svg-index', svgIndex)
  }

  interact(svg)
    .on('mousedown', applyTransforms(svg, svgIndex))
    .on('touchstart', applyTransforms(svg, svgIndex))
}
function applyTransforms (svg, svgIndex) {
  rootMatrix[svgIndex] = svg.getScreenCTM()
}

export function transformToEnvelop (svgIndex) {
  /*
  {
                   volumeEnvelopeData:{
                    fadeIn: {
                      start: 0,
                      end: 80,
                      duration: (block.duration.toFixed(1) * 0.05)
                    },
                    fadeOut: {start: 80, end: 0, duration: (block.duration.toFixed(1) * 0.05)}
                    }
   */
  let volumeEnvelopeData = volumeEnvelopeDataList[svgIndex]

  let polygon = polygons[svgIndex]

  let polygonXLeft = Number(polygon.getAttribute('data-x-left'))
  let polygonXRight = Number(polygon.getAttribute('data-x-right'))
  let polygonHeight = Number(polygon.getAttribute('data-height'))
  let polygonWidth = Number(polygon.getAttribute('data-width'))

  // ///
  let point0 = polygon.points.getItem(0)
  let circleHandle0 = circleHandles[svgIndex][0]

  point0.y = (polygonHeight / 2) - ((polygonHeight / 2) * volumeEnvelopeData.fadeIn.start / 100) + 5
  circleHandle0.y.baseVal.value = point0.y

  let point7 = polygon.points.getItem(7)
  let circleHandle7 = circleHandles[svgIndex][7]

  point7.y = (polygonHeight / 2) + ((polygonHeight / 2) * volumeEnvelopeData.fadeIn.start / 100) + 5
  circleHandle7.y.baseVal.value = point7.y

  let point3 = polygon.points.getItem(3)
  let circleHandle3 = circleHandles[svgIndex][3]

  point3.y = (polygonHeight / 2) - ((polygonHeight / 2) * volumeEnvelopeData.fadeOut.end / 100) + 5
  circleHandle3.y.baseVal.value = point3.y

  let point4 = polygon.points.getItem(4)
  let circleHandle4 = circleHandles[svgIndex][4]

  point4.y = (polygonHeight / 2) + ((polygonHeight / 2) * volumeEnvelopeData.fadeOut.end / 100) + 5
  circleHandle4.y.baseVal.value = point4.y
  // ///

  // ///
  let point1 = polygon.points.getItem(1)
  let circleHandle1 = circleHandles[svgIndex][1]

  point1.y = (polygonHeight / 2) - ((polygonHeight / 2) * volumeEnvelopeData.fadeIn.end / 100) + 5
  circleHandle1.y.baseVal.value = point1.y

  point1.x = (polygonXLeft) + ((polygonWidth * volumeEnvelopeData.fadeIn.duration) / 100) + 5
  circleHandle1.x.baseVal.value = point1.x

  let point6 = polygon.points.getItem(6)
  let circleHandle6 = circleHandles[svgIndex][6]

  point6.y = (polygonHeight / 2) + ((polygonHeight / 2) * volumeEnvelopeData.fadeIn.end / 100) + 5
  circleHandle6.y.baseVal.value = point6.y

  point6.x = point1.x
  circleHandle6.x.baseVal.value = point6.x

  //

  let point2 = polygon.points.getItem(2)
  let circleHandle2 = circleHandles[svgIndex][2]

  point2.y = point1.y
  circleHandle2.y.baseVal.value = point2.y

  point2.x = (polygonXRight) - ((polygonWidth * volumeEnvelopeData.fadeOut.duration) / 100) - 5
  circleHandle2.x.baseVal.value = point2.x

  let point5 = polygon.points.getItem(5)
  let circleHandle5 = circleHandles[svgIndex][5]

  point5.y = point6.y
  circleHandle5.y.baseVal.value = point5.y

  point5.x = point2.x
  circleHandle5.x.baseVal.value = point5.x

  lineHandles[svgIndex][0].x1.baseVal.value = point1.x
  lineHandles[svgIndex][0].y1.baseVal.value = point1.y
  lineHandles[svgIndex][0].x2.baseVal.value = point2.x
  lineHandles[svgIndex][0].y2.baseVal.value = point2.y

  lineHandles[svgIndex][1].x1.baseVal.value = point6.x
  lineHandles[svgIndex][1].y1.baseVal.value = point6.y
  lineHandles[svgIndex][1].x2.baseVal.value = point5.x
  lineHandles[svgIndex][1].y2.baseVal.value = point5.y

  // ///
}

function updateEnvelop (svgIndex) {
  let volumeEnvelopeData = volumeEnvelopeDataList[svgIndex]

  let polygon = polygons[svgIndex]
  let polygonXLeft = Number(polygon.getAttribute('data-x-left'))
  let polygonXRight = Number(polygon.getAttribute('data-x-right'))
  let polygonHeight = Number(polygon.getAttribute('data-height'))
  let polygonWidth = Number(polygon.getAttribute('data-width'))

  let point0 = polygon.points.getItem(0)
  let point1 = polygon.points.getItem(1)
  let point2 = polygon.points.getItem(2)
  let point3 = polygon.points.getItem(3)

  volumeEnvelopeData.fadeIn.start = (((polygonHeight / 2) + 5 - point0.y) * 100) / (polygonHeight / 2)
  volumeEnvelopeData.fadeIn.end = (((polygonHeight / 2) + 5 - point1.y) * 100) / (polygonHeight / 2)
  volumeEnvelopeData.fadeIn.duration = ((point1.x - polygonXLeft - 5) * 100) / polygonWidth

  volumeEnvelopeData.fadeOut.end = (((polygonHeight / 2) + 5 - point3.y) * 100) / (polygonHeight / 2)
  volumeEnvelopeData.fadeOut.duration = ((polygonXRight - point2.x - 5) * 100) / polygonWidth

  updateMusicBlockEnvelopeData(svgIndex)
  updatePlayerFade(svgIndex)
}
// update node markup to save in  db
function updateMusicBlockEnvelopeData (svgIndex) {
  fullBlockList.forEach((block) => {
    if (block.id === 'music' && svgIndex === block.musicIndex) {
      if (block.node.attrs.fadeIn !== null && block.node.attrs.fadeOut !== null) {
        if (volumeEnvelopeDataList[block.musicIndex] == null) {
          volumeEnvelopeDataList[block.musicIndex] = {
            fadeIn: {
              start: 0,
              end: block.node.attrs.fadeIn.end,
              duration: block.node.attrs.fadeIn.duration
            },
            fadeOut: {
              end: 0,
              duration: block.node.attrs.fadeOut.duration
            }
          }
        }
        let volumeEnvelope = volumeEnvelopeDataList[block.musicIndex]
        let attr = {
          fadeIn: {
            start: 0,
            end: volumeEnvelope.fadeIn.end,
            duration: volumeEnvelope.fadeIn.duration
          },
          fadeOut: {
            end: 0,
            duration: volumeEnvelope.fadeOut.duration
          }
        }
        if (proseView != null) {
          proseView.dispatch(proseView.state.tr.setNodeMarkup(block.location - 1, null, attr))
        }
      }
    }
  })
}

export function updatePlayerFade (svgIndex) {
  /**
   * Tell Tonejs about fade values of a block
   */
  // console.log(blockList)
  const calcDb = (perc) => {
    const maxPerc = 120
    const minPerc = 0
    const range = maxPerc - minPerc
    const newPerc = (range / 100) * perc
    return Math.round(1000000000 * 20 * (Math.log(newPerc * 0.01) / Math.log(10))) / 1000000000
  }
  blockList.forEach((block) => {
    if (block.id === 'music' && (!svgIndex || svgIndex === block.musicIndex)) {
      // console.log(block.node)
      if (block.node.attrs.fadeIn != null) {
        if (block.tonejs.trackVolume == null) {
          block.tonejs.trackVolume = 80
        }
        block.tonejs.volumePercentage = block.node.attrs.fadeIn.end + 20
        block.tonejs.volume.value = calcDb(block.tonejs.volumePercentage) // ((80 + block.tonejs.trackVolume) * block.tonejs.volumePercentage / 100) - 90
        // console.log('asdsadsad', block.tonejs.volume.value, block.tonejs.trackVolume, block.tonejs.volumePercentage)
        block.tonejs.fadeIn = block.duration * block.node.attrs.fadeIn.duration / 100
        block.tonejs.fadeOut = block.duration * block.node.attrs.fadeOut.duration / 100
      }
    }
  })
}

export let blockDragging = false

export function applyInteraction () {
  let lineHandleElements = document.getElementsByClassName('.line-handle')
  let circleHandleElements = document.getElementsByClassName(".circle-handle'")
  Array.from(lineHandleElements).forEach(function (element) {
    element.addEventListener('mousedown', (event) => {
      blockDragging = true
    })
  })
  Array.from(circleHandleElements).forEach(function (element) {
    element.addEventListener('mousedown', (event) => {
      blockDragging = true
    })
  })
  Array.from(lineHandleElements).forEach(function (element) {
    element.addEventListener('mouseup', (event) => {
      blockDragging = false
    })
  })
  Array.from(circleHandleElements).forEach(function (element) {
    element.addEventListener('mouseup', (event) => {
      blockDragging = false
    })
  })

  interact('.line-handle').draggable({
    onmove: function (event) {
      event.stopImmediatePropagation()
      blockDragging = true
      let svgIndex = event.target.getAttribute('svg-index') // | 0

      let polygon = polygons[svgIndex]
      let polygonYTop = Number(polygon.getAttribute('data-y-top'))
      let polygonYBottom = Number(polygon.getAttribute('data-y-bottom'))

      let i = event.target.getAttribute('line-index') | 0
      let j = Math.abs(i - 1)

      let diffy =
        lineHandles[svgIndex][i].y1.baseVal.value +
        event.dy / rootMatrix[svgIndex].d

      if (
        (i === 0 && diffy >= polygonYTop && diffy <= polygonYTop + (polygonYBottom - polygonYTop) / 2) ||
        (i === 1 && diffy >= polygonYTop + (polygonYBottom - polygonYTop) / 2 && diffy <= polygonYBottom)
      ) {
        lineHandles[svgIndex][i].y1.baseVal.value += event.dy
        lineHandles[svgIndex][i].y2.baseVal.value += event.dy
        let point1, point2, point3, point4
        let dot1, dot2, dot3, dot4
        if (i === 0) {
          point1 = polygon.points.getItem(1)
          point2 = polygon.points.getItem(2)
          point3 = polygon.points.getItem(5)
          point4 = polygon.points.getItem(6)
          dot1 = circleHandles[svgIndex][1]
          dot2 = circleHandles[svgIndex][2]
          dot3 = circleHandles[svgIndex][5]
          dot4 = circleHandles[svgIndex][6]
        } else if (i === 1) {
          point1 = polygon.points.getItem(5)
          point2 = polygon.points.getItem(6)
          point3 = polygon.points.getItem(1)
          point4 = polygon.points.getItem(2)
          dot1 = circleHandles[svgIndex][5]
          dot2 = circleHandles[svgIndex][6]
          dot3 = circleHandles[svgIndex][1]
          dot4 = circleHandles[svgIndex][2]
        }
        point1.y += event.dy / rootMatrix[svgIndex].d
        point2.y += event.dy / rootMatrix[svgIndex].d
        point3.y -= event.dy / rootMatrix[svgIndex].d
        point4.y -= event.dy / rootMatrix[svgIndex].d
        dot1.y.baseVal.value = point1.y
        dot2.y.baseVal.value = point2.y
        dot3.y.baseVal.value = point3.y
        dot4.y.baseVal.value = point4.y
        lineHandles[svgIndex][j].y2.baseVal.value = point3.y
        lineHandles[svgIndex][j].y1.baseVal.value = point3.y
      }
    },
    onend: function (event) {
      event.stopImmediatePropagation()
      blockDragging = false
    }
  })

  interact('.circle-handle')
    .draggable({
      onstart: event => {
        blockDragging = true
        let circleHandle = event.target
        let svgIndex = circleHandle.getAttribute('svg-index') // | 0
        let circleIndex = circleHandle.getAttribute('circle-index')
        let polygon = polygons[svgIndex]
        let svg = polygon.parentElement
        // hide arrow Handles
        hideArrowHandles()
        // hide volume level
        showVolumeControls(volumeHandles, svgIndex, circleIndex)
        svg.setAttribute('class', 'dragging')
      },
      onmove: function (event) {
        event.stopImmediatePropagation()
        blockDragging = true
        let circleHandle = event.target
        let svgIndex = circleHandle.getAttribute('svg-index') // | 0

        let polygon = polygons[svgIndex]
        let polygonHeight = polygon.getAttribute('data-height')
        let polygonXLeft = polygon.getAttribute('data-x-left')
        let polygonXRight = polygon.getAttribute('data-x-right')
        let polygonWidth = polygon.getAttribute('data-width')
        let duration = polygon.getAttribute('data-duration')

        let circleIndex = circleHandle.getAttribute('circle-index') | 0
        // console.log('log: applyInteraction -> circleIndex', circleIndex)
        let mirrorCircleIndex = Math.abs(circleIndex - 7)

        if (circleIndex === 0 || circleIndex === 3 || circleIndex === 4 || circleIndex === 7) { // end corner circles
          let polygonPoint = polygon.points.getItem(circleIndex)
          let mirrorPolygonPoint = polygon.points.getItem(mirrorCircleIndex)
          let mirrorCircleHandle = circleHandles[svgIndex][mirrorCircleIndex]

          let diff =
            polygonPoint.y +
            event.dy / rootMatrix[svgIndex].d -
            (mirrorPolygonPoint.y - event.dy / rootMatrix[svgIndex].d)

          if (
            ((circleIndex === 0 || circleIndex === 3) && diff < 0 && diff >= -1 * polygonHeight) ||
            ((circleIndex === 4 || circleIndex === 7) && diff > 0 && diff <= polygonHeight)
          ) {
            polygonPoint.y += event.dy / rootMatrix[svgIndex].d
            mirrorPolygonPoint.y -= event.dy / rootMatrix[svgIndex].d

            circleHandle.y.baseVal.value = polygonPoint.y
            mirrorCircleHandle.y.baseVal.value = mirrorPolygonPoint.y
          }
        } else {
          let lineIndexI, lineIndexJ
          let j = 0
          let otherj = 0
          if (circleIndex === 1) {
            lineIndexI = 0
            lineIndexJ = 1
            mirrorCircleIndex = 2
            j = 6
            otherj = 5
          } else if (circleIndex === 2) {
            lineIndexI = 0
            lineIndexJ = 1
            mirrorCircleIndex = 1
            j = 5
            otherj = 6
          } else if (circleIndex === 5) {
            lineIndexI = 1
            lineIndexJ = 0
            mirrorCircleIndex = 6
            j = 2
            otherj = 1
          } else if (circleIndex === 6) {
            lineIndexI = 1
            lineIndexJ = 0
            mirrorCircleIndex = 5
            j = 1
            otherj = 2
          }

          let point = polygon.points.getItem(circleIndex)
          let point2 = polygon.points.getItem(mirrorCircleIndex)

          let point3 = polygon.points.getItem(j)
          let point4 = polygon.points.getItem(otherj)

          let originalpoint2 = circleHandles[svgIndex][mirrorCircleIndex]
          let originalpointj = circleHandles[svgIndex][j]

          let originalpoint3 = circleHandles[svgIndex][otherj]

          let diffy =
            point.y +
            event.dy / rootMatrix[svgIndex].d -
            (point3.y - event.dy / rootMatrix[svgIndex].d)
          let diffx = point.x + event.dx / rootMatrix[svgIndex].a - point2.x
          if (
            ((circleIndex === 1 || circleIndex === 2) && diffy < 0 && diffy >= -polygonHeight) ||
            ((circleIndex === 5 || circleIndex === 6) && diffy > 0 && diffy <= polygonHeight)
          ) {
            let leftBound =
              point.x + event.dx / rootMatrix[svgIndex].a - polygonXLeft
            let rightBound =
              polygonXRight - point.x - event.dx / rootMatrix[svgIndex].a

            if (
              ((circleIndex === 1 || circleIndex === 6) && diffx < 0 && leftBound > 0) ||
              ((circleIndex === 2 || circleIndex === 5) && diffx > 0 && rightBound > 0)
            ) {
              point.x += event.dx / rootMatrix[svgIndex].a
              point3.x += event.dx / rootMatrix[svgIndex].a
              event.target.x.baseVal.value = point.x
              originalpoint2.x.baseVal.value = point2.x
              originalpointj.x.baseVal.value = point3.x
              originalpoint3.x.baseVal.value = point4.x

              lineHandles[svgIndex][lineIndexI].x1.baseVal.value = point.x
              lineHandles[svgIndex][lineIndexI].x2.baseVal.value = point2.x
              lineHandles[svgIndex][lineIndexJ].x2.baseVal.value = point3.x
              lineHandles[svgIndex][lineIndexJ].x1.baseVal.value = point4.x
            }
            point.y += event.dy / rootMatrix[svgIndex].d
            point2.y += event.dy / rootMatrix[svgIndex].d

            point3.y -= event.dy / rootMatrix[svgIndex].d
            point4.y -= event.dy / rootMatrix[svgIndex].d

            event.target.y.baseVal.value = point.y
            originalpoint2.y.baseVal.value = point2.y
            originalpointj.y.baseVal.value = point3.y
            originalpoint3.y.baseVal.value = point4.y

            lineHandles[svgIndex][lineIndexI].y2.baseVal.value = point.y
            lineHandles[svgIndex][lineIndexI].y1.baseVal.value = point.y
            lineHandles[svgIndex][lineIndexJ].y2.baseVal.value = point3.y
            lineHandles[svgIndex][lineIndexJ].y1.baseVal.value = point3.y
          }
          let volume = (((polygonHeight / 2) + 5 - point.y) * 100) / (polygonHeight / 2)
          let fadeDuration = 0
          if (circleIndex === 1) {
            fadeDuration = duration * (((point.x - polygonXLeft - 5) * 100) / polygonWidth) / 100
          } else {
            fadeDuration = duration * (((polygonXRight - point.x - 5) * 100) / polygonWidth) / 100
          }

          updateVolumeControlsPosition(volumeHandles, circleHandles, svgIndex, volume, fadeDuration)
        }
      },
      onend: event => {
        event.stopImmediatePropagation()
        blockDragging = false

        let svgIndex = event.target.getAttribute('svg-index') // | 0

        let polygon = polygons[svgIndex]
        let svg = polygon.parentElement
        hideVolumeControls(volumeHandles, svgIndex)
        svg.setAttribute('class', '')
        updateEnvelop(svgIndex) // store this info in the prosedoc
      },
      restrict: { restriction: document.rootElement }
    })
    .styleCursor(false)

  updatePlayerFade()
}
