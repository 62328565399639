/**
 * Only used in transcript mode
 * Work in progress to separate out from utility
 */
import {
  TextSelection
} from 'prosemirror-state'

export function moveCursorToLeftCharacter (view) {
  let newPosition = view.state.doc.resolve(view.state.selection.anchor - 1)
  view.dispatch(
    view.state.tr.setSelection(new TextSelection(newPosition, newPosition))
  )
}

export function addMarkHideTranscript (
  state,
  dispatch,
  startPos,
  endPos
) {
  if (dispatch) {
    dispatch(
      state.tr
        .addMark(startPos, endPos, state.schema.marks.hideTranscript.create())
    )
  }
}

export function addMarkTranscript (
  state,
  dispatch,
  startPos,
  endPos
) {
  if (dispatch) {
    dispatch(
      state.tr
      // .setMeta(transactionMeta.key, transactionMeta.value)
        .addMark(startPos, endPos, state.schema.marks.transcript.create())
    )
  }
}
