<template>
  <div class="w-full">
    <!-- #TODO: viewBox = 0 0 this.width this.height -->
    <svg :viewBox="`0 0 ${width} ${height}`" class="chart">
      <path
        id="wave3"
        :d="generatedPath1"
        :fill="`${waveColor}4D`"
        :stroke="`${waveColor}E6`"
        stroke-width="1px"
      ></path>
      <path
        id="wave3"
        :d="generatedPath2"
        :fill="`${waveColor}4D`"
        :stroke="`${waveColor}E6`"
        stroke-width="1px"
      ></path>
      <path
        id="wave3"
        :d="generatedPath3"
        :fill="`${waveColor}4D`"
        :stroke="`${waveColor}E6`"
        stroke-width="1px"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Wave3v2',
  data() {
    return {
      generatedPath1: `M -5 0L -5 25Q 0 27.972572185621747 33.333333333333336
               29.328574692986923Q 66.66666666666667 30.684577200352102 100
               32.32991065433161Q 133.33333333333334 33.975244108311124 166.66666666666669
               31.28096719578345Q 200 28.586690283255777 233.33333333333334
               27.591895629548276 L 200 0M 200 0`,
      generatedPath2: `M -5 0L -5 25Q 0 17.28031721177703 33.333333333333336
               16.260490357561572Q 66.66666666666667 15.240663503346113 100
               21.606617844483928Q 133.33333333333334 27.972572185621747 166.66666666666669
               23.643997492634824Q 200 19.315422799647898 233.33333333333334
               21.32781270134557 L 200 0M 200 0`,
      generatedPath3: ` M -5 0L -5 25Q 0 17.495641051266404 33.333333333333336
               23.59731209584003Q 66.66666666666667 29.69898314041366 100
               23.058480309940762Q 133.33333333333334 16.417977479467872 166.66666666666669
               16.65200591297118Q 200 16.886034346474485 233.33333333333334
               16.909736568992493 L 200 0M 200 0`
    }
  },
  props: {
    /*
    scales ~ amplitude array for the wave
    waveformData ~ audio's sampled data
    */
    waveColor: {
      type: String,
      default: '#E86B56'
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 50
    },
    scale: {
      type: Number,
      default: 0.21111
    },
    scales1: {
      type: Array,
      default: () => new Array(15).fill(0)
    },
    scales2: {
      type: Array,
      default: () => new Array(15).fill(0)
    },
    scales3: {
      type: Array,
      default: () => new Array(15).fill(0)
    },
    waveformData: {
      type: Array,
      default: () => []
    }
  },
  methods: {},
  computed: {
    waveMax: function () {
      return Math.max(...this.waveformData) || 50
    }
  },
  watch: {
    waveformData() {
      this.scales1.shift()
      this.scales1.push(
        // -1 or +1  * (audiodata* wavemax) * (streched on 30% of height)
        (((Math.random() > 0.5 ? -1 : 1) * this.waveformData[0]) /
          this.waveMax) *
          (this.height * 0.2)
      )

      this.scales2.shift()
      this.scales2.push(
        // -1 or +1  * (audiodata* wavemax) * (streched on 30% of height)
        (((Math.random() > 0.5 ? -1 : 1) * this.waveformData[3]) /
          this.waveMax) *
          (this.height * 0.2)
      )

      this.scales3.shift()
      this.scales3.push(
        // -1 or +1  * (audiodata* wavemax) * (streched on 30% of height)
        (((Math.random() > 0.5 ? -1 : 1) * this.waveformData[11]) /
          this.waveMax) *
          (this.height * 0.2)
      )

      let d1 = ''
      d1 += `M -5 0`
      d1 += `L -5 ${this.height / 2}`

      // Currently 15 Quadraticcurves,
      // Quadratic Bezier Curve
      // Q cx , cy , x, y
      // cx =   curveNo *width/15
      // cy =   baseheight + (amplitude)
      // x --> ( x = cx + (cxnext - cx) / 2)
      // y --> ( y = cy + (cynext - cy) / 2)
      this.scales1.slice(0, 4).forEach((scale, i) => {
        d1 += `Q ${(i * this.width) / 3} ${this.height / 2 + scale} ${
          i * (this.width / 3) + this.width / 6
        }
               ${this.height / 2 + scale + (this.scales1[i + 1] - scale) / 2}`
      })

      d1 += ` L ${this.width} 0`
      d1 += `M ${this.width} 0`

      let d2 = ''
      d2 += `M -5 0`
      d2 += `L -5 ${1 + this.height / 2}`

      let diff = 5 - Math.random() * 10

      this.scales2.slice(0, 4).forEach((scale, i) => {
        d2 += `Q ${(i * this.width) / 3} ${
          this.height / 2 + 1 + diff + scale
        } ${i * (this.width / 3) + this.width / 6}
               ${
  this.height / 2 +
                 1 +
                 diff +
                 scale +
                 (this.scales2[i + 1] - scale) / 2
}`
      })

      d2 += ` L ${this.width} 0`
      d2 += `M ${this.width} 0`

      let d3 = ''
      d3 += `M -5 0`
      d3 += `L -5 ${3 + this.height / 2}`

      diff = 5 - Math.random() * 10

      this.scales3.slice(0, 4).forEach((scale, i) => {
        d3 += `Q ${(i * this.width) / 3} ${
          this.height / 2 + 3 + diff + scale
        } ${i * (this.width / 3) + this.width / 6}
               ${
  this.height / 2 +
                 3 +
                 diff +
                 scale +
                 (this.scales3[i + 1] - scale) / 2
}`
      })

      d3 += ` L ${this.width} 0`
      d3 += `M ${this.width} 0`

      this.generatedPath1 = d1
      this.generatedPath2 = d2
      this.generatedPath3 = d3
    }
  }
}
</script>

<style scoped lang="css">
svg {
  transition: 0.2s;
  transform: scale(-1, -1);
}
#wave3 {
  transition: all 0.2s ease;
}
</style>
