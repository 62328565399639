import { Plugin, TextSelection } from 'prosemirror-state'
import { calculatePositionAndSelect } from '../util/utility'
/**
 * Press left and right should skip the whole word
 */
export function handleCursor () {
  return new Plugin({
    props: {
      // This solution does not work with KeyPress
      handleKeyDown: function (view, event) {
        // console.log(event)
        // shift is not pressed
        if (!event.shiftKey) {
          // arrow keys
          if (event.keyCode === 37) {
            // Arrow Left
            event.preventDefault()
            if (view.state.selection.anchor !== view.state.selection.head) {
              if (document.getElementById('plusButton')) document.getElementById('plusButton').style.display = ''

              view.dispatch(
                view.state.tr.setSelection(new TextSelection(view.state.doc.resolve(view.state.selection.anchor), view.state.doc.resolve(view.state.selection.anchor)))
              )
            }
            calculatePositionAndSelect(view, 'left')
          } else if (event.keyCode === 39) {
            // Arrow Right
            event.preventDefault()
            if (view.state.selection.anchor !== view.state.selection.head) {
              if (document.getElementById('plusButton')) document.getElementById('plusButton').style.display = ''

              view.dispatch(
                view.state.tr.setSelection(new TextSelection(view.state.doc.resolve(view.state.selection.head), view.state.doc.resolve(view.state.selection.head)))
              )
            }
            calculatePositionAndSelect(view, 'right')
          }
        }
      }
    }
  })
}
