<template>
  <div class="linewaves w-full h-full">
    <div
      v-for="(s, i) in waveScales"
      :key="i"
      class="waveLine"
      :style="{
        'min-height': `${s * 100 / waveMax}%`,
        'max-height': `${s * 100 / waveMax}%`,
        'min-width': `${14 * scale}px`,
        'max-width': `${14 * scale}px`,
        'margin-right': `${18 * scale}px`,
        'border-radius': `${8 * scale}px`,
        'background': waveColor
      }"
    />
    <div
      v-for="(s, i) in waveScales"
      :key="i"
      class="waveLine"
      :style="{
        'min-height': `${s * 100 / waveMax}%`,
        'max-height': `${s * 100 / waveMax}%`,
        'min-width': `${14 * scale}px`,
        'max-width': `${14 * scale}px`,
        'margin-right': `${18 * scale}px`,
        'border-radius': `${8 * scale}px`,
        'background': waveColor
      }"
    />
  </div>
</template>

<script>
export default {
  name: 'Wave1',
  props: {
    waveColor: {
      type: String,
      default: '#fff'
    },
    scale: {
      type: Number,
      default: 0.21111
    },
    waveformData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    waveMax: function () {
      return Math.max(...this.waveformData) || 50
    },
    waveScales: function () {
      let scales = []
      for (let i = 0; i < this.waveformData.length; i++) {
        scales.push(Math.random() * 1 * this.waveMax)
      }
      return scales
    }
  }
}
</script>

<style scoped lang="css">
.linewaves {
  display: flex;
  align-items: center;
  justify-content: center;
}

.waveLine {
  transition: 0.1s;
  background: #949ba8;
  width: 13px;
  margin-right: 1px;
  border-radius: 16px;
}
</style>
