<template>
  <div
    class="transition ease-in-out duration-300"
    v-show="element.value && !element.hidden"
    :style="style"
  >
    <div>{{element.value}}</div>
    <div :style="element.separatorStyle" />
  </div>
</template>

<script>
export default {
  name: 'HeadingBlock',
  props: {
    element: Object
  },
  computed: {
    meta: function() {
      return this.element.meta
    },
    style: function() {
      const { size, position, cover, style } = this.element
      const { x, y } = position
      let transform = ''
      if (x) transform += 'translateX(-50%) '
      if (y) transform += 'translateY(50%)'
      const styles = {
        position: 'absolute',
        ...x ? { left: `${x}%` } : {},
        ...y ? { bottom: `${y}%` } : {},
        ...size,
        ...cover ? {} : { transform },
        ...style
      }

      return styles
    }
  }

}
</script>

<style>

</style>
