<template>
  <div>
    <!-- elementMountOn here -->
    <div id="editor" :class="{ 'is-demo': isDemo }" />
    <div v-show="freshDoc && !totalTime" class="freshDocPlaceholder">
      Start by adding a transcribed recording below. Add media files/ other
      recordings in the order you want them.
    </div>
    <SideBar v-if="!isDemo && !barebones" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import ProseEditor from '../../proseEditor/ProseEditor'
import store from '@/services/store'
import { setModeInLocalStorage } from '@/components/common/browserStorageUtility'
import * as firebase from 'firebase/app'
import 'firebase/database'
import { tippyTourHearTheCut } from './tippy-popovers.js'
import { initProsEditor } from '@/view/voiceEditor/proseEditor/util/utility'
import uuidv1 from 'uuid/v1'
export default {
  name: 'NewProseEditor',
  components: {
    SideBar: () => import(/* webpackChunkName: "SideBar" */ './SideBar')
  },
  props: {
    barebones: {
      type: Boolean,
      default: false
    },
    isAutoScroll: {
      type: Boolean,
      default: true
    },
    owner: String,
    podId: String,
    preventMountFlag: Boolean
  },
  data() {
    return {
      loading: 'true'
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile',
      isDemo: 'editor/isDemo',
      editMode: 'editor/editMode',
      user: 'app/user',
      freshDoc: 'editor/freshDoc',
      totalTime: 'editor/totalTime',
      showDeleted: 'editor/showDeleted',
      newParagraph: 'doc/newParagraph',
      updateCount: 'blockmode/updateCount',
      blockmodeConfig: 'blockmode/blockmodeConfig',
      isBlockmodeOpen: 'editor/isBlockmodeOpen',
      musicDeleted: 'blockmode/musicDeleted'
    })
  },
  methods: {
    ...mapActions({
      setSwitching: 'editor/setSwitching'
    }),
    init() {
      let vm = this
      let elementToMountOn = document.querySelector('#editor')
      let clientID = this.user.uid
      let ownerId = this.owner || vm.$route.params.userId
      let podId = this.podId || vm.$route.params.podId
      let firebaseRef = firebase
        .database()
        .ref(ownerId + '/podcast/' + podId + '/realtime/')
      this.proseEditor = new ProseEditor(
        elementToMountOn,
        vm.editMode,
        firebaseRef,
        clientID,
        firebase,
        this.isDemo,
        this.barebones,
        this.isAutoScroll,
        this.preventMountFlag
      )
      initProsEditor(this.proseEditor)
      /**
       * Follwing functions will be used by prosemirror to communicate to vue
       * All are callback kind
       */
      this.proseEditor.registerNotifyCopied(() =>
        store.dispatch('editor/notifyCopied')
      )
      this.proseEditor.registerNotifyClipped((start, end, title, location) => {
        vm.$emit('addClip', { id: uuidv1(), start, end, title, location })
      }
      )
      this.proseEditor.registerNotifyCut(() =>
        store.dispatch('editor/notifyCut')
      )
      this.proseEditor.registerNotifyEdit(() =>
        store.dispatch('editor/notifyEdit', 0.1)
      )
      this.proseEditor.registerNotifyRemove(function(time) {
        store.dispatch('editor/notifyEdit', time)
        if (vm.isMobile || vm.isDemo) {
          tippyTourHearTheCut()
        }
      })
      this.proseEditor.registerNotifyPaste(time =>
        store.dispatch('editor/notifyEdit', time)
      )
      this.proseEditor.registerNotifyTotalTime(time =>
        store.commit('editor/setTotalTime', time)
      )
      this.proseEditor.registerNotifyTimeAtCursor(time =>
        store.commit('doc/setCurrentTime', time)
      )
      this.proseEditor.registerTextAddAttempt(time =>
        store.commit('editor/setTextAddAttempt')
      )
      // after 50 changes, prosemirror will emit the current state. json here is prosemirror doc,
      // we save it in firebase directly -> this becomes our checkpoint.
      this.proseEditor.registerEmitUpdate(json => vm.$emit('json', json))
      this.proseEditor.registerAudioSourceUpdate((sources, mediaSources) =>
        vm.$emit('sources', sources, mediaSources)
      )
      this.proseEditor.registerNotifycurrentPara(paraIndex =>
        store.commit('doc/setCurrentPara', paraIndex)
      )
      this.proseEditor.registerParagraphMetaUpdate(paragraphMeta =>
        store.commit('doc/setParagraphMeta', paragraphMeta)
      )
      this.proseEditor.registerShowBlockMode(() =>
        store.dispatch('modal/showBlockMode')
      )

      store.commit('editor/setProseEditorInitialised')
      this.proseEditor.registerCommentThreadsAndPositionsUpdate(
        commentThreadsAndPositions =>
          store.commit(
            'comment/setCommentThreadsAndPositions',
            commentThreadsAndPositions
          )
      )
      this.proseEditor.registerLoadingState(loadingState => {
        vm.loading = loadingState
        vm.$emit('loadingState', loadingState)
      })
      this.proseEditor.registerMasterVolume(volume =>
        store.commit('editor/setMasterVolume', volume)
      )
      this.proseEditor.registerDirty(isDirty =>
        store.commit('editor/setSavingStep', isDirty)
      )

      // TODO: emit add clip
      // this.proseEditor.registerAddclip(this.$emit('addClip'))
    },

    /***
     * Following functions are used to communicate to prosemirror from vue
     */

    /**
     * homeprose fetch from firebase and then
     * this function is being called from there
     * and it updates the content on prosemirror
     */
    setContent(content = {}, next) {
      this.init()
      this.proseEditor.IsDemo = this.isDemo
      this.proseEditor.setContent(content, next)
      this.proseEditor.isDeletedHidden = !this.showDeleted
    },
    migrateDoc() {
      this.proseEditor.migrateDoc()
    },
    // setState (content = {}) {
    //   this.proseEditor.setState(content)
    // },
    goToPara(index) {
      this.proseEditor.goToPara(index)
      store.commit('editor/setCurrentPara', index)
    },
    goToTime(time) {
      this.proseEditor.goToTime(time)
      // store.commit('editor/setCurrentPara', index)
    },
    toggleReduceNoise() {
      this.proseEditor.isReduceNoise = !this.proseEditor.isReduceNoise
      store.commit('editor/setReduceNoise', !this.proseEditor.isReduceNoise)
    },
    insertDocAtPos(doc, ownerId, podId) {
      this.proseEditor.insertDocAtPos(doc, ownerId, podId)
    },
    switchToTranscriptMode() {
      this.proseEditor.switchToTranscriptMode()
    },
    switchToVoiceMode() {
      this.proseEditor.switchToVoiceMode()
    },
    toggleMode() {
      if (this.loading === 'true') {
        return
      }

      this.setSwitching(true)
      setTimeout(() => {
        if (this.editMode === 'editMedia') {
          this.switchToVoiceMode()
        } else if (this.editMode === 'editTranscript') {
          this.switchToTranscriptMode()
        }

        this.setSwitching(false)
      }, 400)
    }
  },
  watch: {
    showDeleted(newVal) {
      this.proseEditor.isDeletedHidden = !newVal
    },
    newParagraph(val) {
      if (val) {
        this.proseEditor.addNewParagraph()
        store.commit('doc/addNewParagraph', false)
      }
    },
    editMode(mode) {
      // TODO
      window.mode = mode
      let fileId = this.$route.params.podId
      if (fileId) {
        setModeInLocalStorage(fileId, mode)
      }
      this.toggleMode()
    },
    updateCount(newVal) {
      console.log('updateCount')
      if (this.blockmodeConfig.uid && this.blockmodeConfig.uid.length > 0) {
        this.proseEditor.audioDataChanged(this.blockmodeConfig)
      }
    },
    isBlockmodeOpen(newVal) {
      if (!newVal) {
        this.proseEditor.pillPinnedCleanUp()
      }
    },
    musicDeleted(newVal) {
      if (newVal) {
        this.proseEditor.deleteMusic(this.blockmodeConfig)
      }
    }
  },
  mounted() {},
  beforeDestroy() {
    if (this.proseEditor) this.proseEditor.destroy()
  }
}
</script>
<style>
@font-face {
  font-family: "wavefont";
  font-weight: normal;
  src: url("~wavefont/font/wavefont-bars-reflected-400.otf");
}
.freshDocPlaceholder {
  position: absolute;
  top: 4px;
  pointer-events: none;
  color: grey;
}
</style>
