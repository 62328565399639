/**
 * this function is used for exporting the audio
 * This fn is called from AudioControl.js file, exportWAV function
 * It returns buffer which can be written as a file.
 * @param {} buffer
 * @param {*} opt
 */
export function toWav (buffer, opt) {
  opt = opt || {}

  let numChannels = buffer.numberOfChannels
  let sampleRate = buffer.sampleRate
  let format = opt.float32 ? 3 : 1
  let bitDepth = format === 3 ? 32 : 16

  // use pro method for 2 channels
  if (numChannels === 2) {
    let resultArray = interleave(buffer.getChannelData(0), buffer.getChannelData(1))
    return encodeWAVPro(resultArray, format, sampleRate, numChannels, bitDepth)
  } else {
    let result = buffer.getChannelData(0)
    return encodeWAV(result, format, sampleRate, numChannels, bitDepth)
  }
}

function encodeWAV (samples, format, sampleRate, numChannels, bitDepth) {
  let bytesPerSample = bitDepth / 8
  let blockAlign = numChannels * bytesPerSample

  let buffer = new ArrayBuffer(44 + samples.length * bytesPerSample)
  let view = new DataView(buffer)

  /* RIFF identifier */
  writeString(view, 0, 'RIFF')
  /* RIFF chunk length */
  view.setUint32(4, 36 + samples.length * bytesPerSample, true)
  /* RIFF type */
  writeString(view, 8, 'WAVE')
  /* format chunk identifier */
  writeString(view, 12, 'fmt ')
  /* format chunk length */
  view.setUint32(16, 16, true)
  /* sample format (raw) */
  view.setUint16(20, format, true)
  /* channel count */
  view.setUint16(22, numChannels, true)
  /* sample rate */
  view.setUint32(24, sampleRate, true)
  /* byte rate (sample rate * block align) */
  view.setUint32(28, sampleRate * blockAlign, true)
  /* block align (channel count * bytes per sample) */
  view.setUint16(32, blockAlign, true)
  /* bits per sample */
  view.setUint16(34, bitDepth, true)
  /* data chunk identifier */
  writeString(view, 36, 'data')
  /* data chunk length */
  view.setUint32(40, samples.length * bytesPerSample, true)
  if (format === 1) { // Raw PCM
    floatTo16BitPCM(view, 44, samples)
  } else {
    writeFloat32(view, 44, samples)
  }

  return buffer
}

function encodeWAVPro (samples, format, sampleRate, numChannels, bitDepth) {
  let totalLength = 0
  for (let i = 0; i < samples.length; i++) {
    totalLength += samples[i].length
  }
  let bytesPerSample = bitDepth / 8
  let blockAlign = numChannels * bytesPerSample

  let buffer = new ArrayBuffer(44 + totalLength * bytesPerSample)
  let view = new DataView(buffer)

  /* RIFF identifier */
  writeString(view, 0, 'RIFF')
  /* RIFF chunk length */
  view.setUint32(4, 36 + totalLength * bytesPerSample, true)
  /* RIFF type */
  writeString(view, 8, 'WAVE')
  /* format chunk identifier */
  writeString(view, 12, 'fmt ')
  /* format chunk length */
  view.setUint32(16, 16, true)
  /* sample format (raw) */
  view.setUint16(20, format, true)
  /* channel count */
  view.setUint16(22, numChannels, true)
  /* sample rate */
  view.setUint32(24, sampleRate, true)
  /* byte rate (sample rate * block align) */
  view.setUint32(28, sampleRate * blockAlign, true)
  /* block align (channel count * bytes per sample) */
  view.setUint16(32, blockAlign, true)
  /* bits per sample */
  view.setUint16(34, bitDepth, true)
  /* data chunk identifier */
  writeString(view, 36, 'data')
  /* data chunk length */
  view.setUint32(40, totalLength * bytesPerSample, true)
  if (format === 1) { // Raw PCM
    floatTo16BitPCMPro(view, 44, samples)
  } else {
    writeFloat32Pro(view, 44, samples)
  }

  return buffer
}
function writeFloat32Pro (output, offset, input) {
  for (let j = 0; j < input.length; j++) {
    for (let i = 0; i < input[j].length; i++, offset += 4) {
      output.setFloat32(offset, input[j][i], true)
    }
  }
}

function floatTo16BitPCMPro (output, offset, input) {
  for (let j = 0; j < input.length; j++) {
    for (let i = 0; i < input[j].length; i++, offset += 2) {
      let s = Math.max(-1, Math.min(1, input[j][i]))
      output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true)
    }
  }
}

function interleave (inputL, inputR) {
  /**
   * Merge two channles into one and return the array
   */
  const maxLength = 536871936
  const totalLength = inputL.length + inputR.length
  let numOfArrayNeeded = Math.floor(totalLength / (maxLength + 1)) + 1
  let resultArray = []
  // let length = Math.min(inputL.length + inputR.length, 536871936)
  for (let i = 0; i < numOfArrayNeeded - 1; i++) {
    resultArray.push(new Float32Array(maxLength))
  }
  resultArray.push(new Float32Array(totalLength % maxLength))
  let index = 0
  let inputIndex = 0
  while (index < totalLength) {
    let arrayIndex = Math.floor(index / (maxLength + 1))
    resultArray[arrayIndex][index % maxLength] = inputL[inputIndex]
    index += 1
    arrayIndex = Math.floor(index / (maxLength + 1))
    resultArray[arrayIndex][index % maxLength] = inputR[inputIndex]
    index += 1
    inputIndex++
  }
  return resultArray
}

function writeFloat32 (output, offset, input) {
  for (let i = 0; i < input.length; i++, offset += 4) {
    output.setFloat32(offset, input[i], true)
  }
}

function floatTo16BitPCM (output, offset, input) {
  for (let i = 0; i < input.length; i++, offset += 2) {
    let s = Math.max(-1, Math.min(1, input[i]))
    output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true)
  }
}

function writeString (view, offset, string) {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i))
  }
}
