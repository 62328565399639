/* eslint-disable one-var */
/* eslint-disable eqeqeq */

/**
 * When inside transcript editor mode
 * Add a mark and move cursor to left char
 */
import { moveCursorToLeftCharacter } from '../util/utilityTranscript'

export function deleteSelection (state, dispatch, view) {
  if (state.selection.empty) {
    // no selection case
    // paragraph starts just before character
    if (state.selection.$anchor.nodeBefore === null) {
      return false
    }
    try {
      // from and to would be same, so just delete the from -1 char
      if (dispatch) dispatch(state.tr.addMark(state.selection.from - 1, state.selection.to, state.schema.marks.hideTranscript.create()))
    } catch (e) {
      console.log('error', e)
      return false
    }
    moveCursorToLeftCharacter(view)
    return true
  } else {
    // when user has selected something
    if (dispatch) dispatch(state.tr.addMark(state.selection.from, state.selection.to, state.schema.marks.hideTranscript.create()))
  }
  moveCursorToLeftCharacter(view)
  return true
}
