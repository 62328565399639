<template>
   <div class="w-full h-full" ref="box" v-if="width>0 && height>0">
     <VueVideoThumbnail
      v-if="src"
      :video-src="src"
      :snapshot-at-duration-percent="percentage > 100 ? 75 : percentage"
      snapshot-scale-type="cover"
      :snapshot-format="'image/jpeg'"
      background-fill-color="black"
      :snapshot-quality="1"
      :width="width"
      :height="height"
      :chunks-quantity="10"
    >
      <template #snapshot="{snapshot}">
            <img
          v-if="snapshot"
          :src="snapshot"
          alt="snapshot"
        >
      </template>
    </VueVideoThumbnail>
   </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueVideoThumbnail from 'vue-video-thumbnail'

export default {
  components: {
    VueVideoThumbnail
  },
  props: {
    percentage: {
      type: Number,
      default: 50
    },
    source: {
      type: String,
      default: ''
    }
  },
  data: function() {
    return {
      height: 225,
      width: 400
    }
  },
  computed: {
    ...mapGetters({
      sources: 'video/sources'
    }),
    src: function() {
      if (this.source) return this.source
      const sources = Object.values(this.sources)
      return sources[1] || sources[0] || ''
    }
  },
  mounted: function() {
    this.height = this.$refs.box.clientHeight
    this.width = this.$refs.box.clientWidth
  }
}
</script>

<style>

</style>
