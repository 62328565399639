<template>
  <div>
    <vue-toastr ref="toastr"></vue-toastr>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AudioControl from '../../proseEditor/AudioControl'
import store from '@/services/store'
import { myProseEditor } from '@/view/voiceEditor/proseEditor/util/utility'
// require('vue-toastr/src/vue-toastr.less')
export default {
  name: 'AudioController',
  components: {
    'vue-toastr': () => import('vue-toastr')
  },
  data () {
    return {
      audioControl: null,
      timerId: null
    }
  },
  computed: {
    ...mapGetters({
      currentSpeed: 'audio/currentSpeed',
      editMode: 'editor/editMode',
      isDemo: 'editor/isDemo',
      isPlaying: 'editor/isPlaying',
      masterTranscriptVolume: 'editor/masterTranscriptVolume',
      proseEditor: 'editor/proseEditor',
      isProseEditorInitialised: 'editor/isProseEditorInitialised',
      videoSourcesReady: 'video/sourcesReady',
      audioSourcesReady: 'editor/sourcesReady'
    }),
    isSourcesReady: function() {
      return this.videoSourcesReady && this.audioSourcesReady
    }
  },
  props: {
    imageUrlList: Object,
    videoUrlList: Object,
    urlList: Object,
    loading: {
      type: String, default: 'true'
    },
    playerType: {
      type: String,
      default: 'video'
    }
  },
  methods: {
    backward: function () {
      this.audioControl.stopPlayer()
      myProseEditor.skipAndSelectNode(-5)
      this.audioControl.startPlayer(true)
    },
    forward: function () {
      this.audioControl.stopPlayer()
      myProseEditor.skipAndSelectNode(5)
      this.audioControl.startPlayer(true)
    },
    setSpeed (speed) {
      // console.log('speed is', speed)
      this.audioControl.setSpeed(speed)
    }
  },
  watch: {
    isSourcesReady: function (newVal) {
      if (newVal) {
        store.commit('editor/setPlayerLoadProgress', 1)
      } else {
        store.commit('editor/setPlayerLoadProgress', 0)
      }
    },
    currentSpeed: function (speed) {
      this.setSpeed(speed)
    },
    currentTime: function (newtime) {
      // console.log('currenttime', newtime)
      // store.commit('editor/setCurrentTime', newtime)
    },
    editMode: function (mode) {
      if (this.loading === 'true') return
      this.audioControl.updateMode(mode)
    },
    // update current time by a timer
    // more performant than actual parsing
    // isPlaying (val) {
    //   var vm = this
    //   if (val) {
    //     if (vm.timerId !== '') {
    //       clearInterval(vm.timerId)
    //     }
    //     vm.timerId = setInterval(increaseTime, 1000 / this.currentSpeed)
    //   } else {
    //     if (vm.timerId !== '') {
    //       clearInterval(vm.timerId)
    //     }
    //   }
    //   function increaseTime () {
    //     // console.log('do something here')
    //     store.commit('doc/incrementCurrentTime')
    //   }
    // },
    isProseEditorInitialised: function () {
      this.audioControl = myProseEditor
      if (myProseEditor.audioControl == null) {
        this.audioControl = new AudioControl(myProseEditor.isAutoScroll)
        this.audioControl.isDemo = this.isDemo
        // #TODO Remove editor/setAudioControl, preserved for demo usecase
        store.commit('editor/setAudioControl', this.audioControl)
        this.audioControl.initProseEditorView(myProseEditor.view)
        this.audioControl.initMusicAudioBufferList(myProseEditor.musicAudioBufferList)
        myProseEditor.setAudioControl(this.audioControl)
        this.audioControl.updateMode(this.editMode)
      }
    },
    // used by both homeprose &  spext-doc
    urlList: function (urlList) {
      if (Object.keys(urlList).length > 0) this.audioControl.initPlayerWithURLList(urlList, this.masterTranscriptVolume)
    },
    videoUrlList: function (videoUrlList) {
      store.commit('video/setSourcesReady', false)
      this.audioControl.initVideoUrls(videoUrlList)
    },
    imageUrlList: function (imageUrlList) {
      this.audioControl.initImageUrls(imageUrlList)
    },
    playerType: function(val) {
      if (val === 'audiogram') {
        store.commit('editor/setDocProgress', 82)
        store.commit('video/setSourcesReady', true)
      }
    }
  },
  beforeDestroy() {
    if (this.timerId !== '') {
      clearInterval(this.timerId)
    }
  }
}
</script>
