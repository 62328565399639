import Tone from 'tone'
import uuidv1 from 'uuid/v1'
import { TextSelection } from 'prosemirror-state'
import {
  moveCursorToBoundary,
  getNextNode,
  selectNodeAfter,
  getSnappedSelectionForLocation
} from '../../util/utility'
import { splitBlockAtLocation } from '../../commands'
/**
 * when music file is dropped on prosemirror doc
 * @param {} view
 * @param {*} event
 * @param {*} musicAudioBufferList
 */
export function dropHandler (view, event, musicAudioBufferList) {
  if (
    !(
      event.dataTransfer &&
      event.dataTransfer.files &&
      event.dataTransfer.files.length > 0
    )
  ) {
    return
  }

  // filter if the file is audio or not
  let audios = filterAudios(event.dataTransfer.files)
  if (audios.length === 0) return
  event.preventDefault()
  let insertPos
  // create the selection
  if (view.state.selection.anchor === view.state.selection.head) {
    insertPos = view.posAtCoords({ left: event.clientX, top: event.clientY })
    view.dispatch(
      view.state.tr.setSelection(
        new TextSelection(
          view.state.doc.resolve(insertPos.pos),
          view.state.doc.resolve(insertPos.pos)
        )
      )
    )
    moveCursorToBoundary(view)
    if (event.target.localName !== 'span') {
      console.log(event.target.localName)
      splitBlockAtLocation(insertPos.pos - 1, insertPos.pos, view.state, view.dispatch, view)
    }
  }

  let { from } = view.state.selection
  insertPos = { pos: from, inside: -1 }
  // load audio into prosemirror here
  loadAudio(view, audios, insertPos, musicAudioBufferList)
}

export function loadAudio (view, audios, insertPos, musicAudioBufferList) {
  /**
   * This fun load audio into prosedoc
   * create audio notes into the doc
   */
  audios.forEach(audio => {
    let reader = new FileReader()
    let uniqueSourceId = uuidv1()
    reader.onload = e => {
      var player = new Tone.Player(e.target.result, function () {
        let musicId = 'music-' + uniqueSourceId

        // create new audio node
        let audioNode = view.state.schema.node(
          'music',
          { title: audio.name },
          view.state.schema.text(audio.name),
          [
            view.state.schema.mark('info', {
              uid: musicId,
              asource: uniqueSourceId,
              astart: 0,
              aend: player.buffer.duration,
              class: 'localaudio'
            })
          ]
        )
        let silenceCount = player.buffer.duration / 0.1
        let transaction
        let doc = view.state.doc
        let newPosition = 0
        if (view.state.selection.anchor !== view.state.selection.head) {
          let start = doc.resolve(view.state.selection.anchor)
          let end = doc.resolve(view.state.selection.head)
          let selectedDuration = 0
          let i
          for (i = start.pos; selectNodeAfter(doc.resolve(i), view).end.pos < end.pos; i = selectNodeAfter(doc.resolve(i), view).end.pos) {
            let node = getNextNode(i, view)
            if (node == null) { // encountered paragraph
              i = i + 2
              continue
            }
            if ((selectedDuration + nodeDuration(node)) > player.buffer.duration) {
              break
            }
            selectedDuration = selectedDuration + nodeDuration(node)
          }
          let newNoteMark = view.state.schema.mark('note', {
            class: 'note'
          })
          let pos = getSnappedSelectionForLocation(start.pos, i, view)

          transaction = view.state.tr.addMark(pos.start, pos.end, newNoteMark).insert(insertPos.pos, audioNode)
        } else {
          newPosition = insertPos.pos + audioNode.nodeSize
          transaction = view.state.tr.insert(insertPos.pos, audioNode)
          for (let i = 0; i <= silenceCount; i++) {
            let level = Math.random() * 127
            let soundchar = String.fromCharCode(0x200 + level)
            let r = Math.random()
            if (Math.round(r * 10) === 1) {
              soundchar = ' '
            }
            let silenceNode = view.state.schema.text(soundchar, [
              view.state.schema.mark('info', {
                uid: 'mtext-' + uuidv1(),
                astart: 0,
                aend: 0.1,
                class: 'music'
              }),
              view.state.schema.mark('note', {
                class: 'note'
              })
            ])
            transaction = transaction.insert(newPosition, silenceNode)
            newPosition += silenceNode.nodeSize
          }
        }
        view.updateState(view.state.apply(transaction))
        view.focus()
      }).toMaster()
      musicAudioBufferList[uniqueSourceId] = player // update buffer
      // musicAudioBufferList.push(player)
    }
    reader.readAsDataURL(audio)
  })
}
function nodeDuration (textNode) {
  let duration = 0
  textNode.marks.forEach(function (mark) {
    if (mark.type.name === 'deleted') {
      return 0
    }
    if (mark.attrs != null && mark.attrs.uid != null) {
      if (!mark.attrs.uid.includes('music-') && !mark.attrs.uid.includes('endmus-')) {
        duration = mark.attrs.aend - mark.attrs.astart
        // if (mark.attrs.uid.split('-')[0] === 'silence') {
        //   duration = 0.1
        // }
      }
    }
  })
  return duration
}
function filterAudios (files) {
  let audios = []
  for (let i = 0; i < files.length; i++) {
    let file = files[i]
    if (!/audio/i.test(file.type)) continue
    audios.push(file)
  }
  return audios
}
