import { Plugin, TextSelection } from 'prosemirror-state'
import './plusToolbar.css'
import crel from 'crel'
import store from '@/services/store'
import {
  addMarkDeleted,
  calculateTime, doesMusicExists,
  getNextNode,
  isTextSelected,
  selectNodeAfter,
  selectNodeBefore
} from '../util/utility'
import { myProseEditor } from '@/view/voiceEditor/proseEditor/util/utility'
const magicNumberLeft = 16
const magicNumberTop = 25
// import Menu from './Menu'
// Step:1. Make a view component to tie to editor's life cycle
// used when pluign needs to interact with the editor view, or set something up in the DOM
export const plusToolbar = new Plugin({
  view (editorView) {
    return new PlusToolbar(items, editorView)
  }
  // props: {
  //   handleDOMEvents: {
  //     focus: (view, event) => {
  //       console.log(view, event)
  //     }
  //   }
  // }
})

class PlusToolbar {
  constructor (items, view) {
    // console.log('plustoobar: created')
    this.items = items
    this.view = view

    this.tooltip = this.createTooltip()
    this.tooltip.setAttribute('data-intercom-target', 'plusButton')
    items.forEach(({ dom }) => this.tooltip.appendChild(dom))
    if (view.dom.parentNode) {
      view.dom.parentNode.appendChild(this.tooltip)
    }
    this.update(view, null)

    // when someone click on this -> updating the store so vue can takeover the interaction
    this.tooltip.addEventListener('mousedown', e => {
      e.preventDefault()
      window.analytics.track('editor_clickedPlusButton')
      if (store.state.clipper.clips.length) {
        store.dispatch('clipper/openExportModal', true)
        return
      }
      const { x, y } = e.target.getBoundingClientRect()
      store.state.editor.addActionPopoverConfig = {
        show: true,
        isPositionFixed: true,
        transition: 'bounce-in-left',
        style: {
          left: x + 52 + 'px',
          top: y + 10 + 'px',
          'box-shadow': '4px 4px 4px rgba(0, 0, 0, 0.06)'
        },
        closeOnClickOutside: true
      }
      if (document.getElementById('plusButton')) document.getElementById('plusButton').classList.add('active')
      // view.focus()
      // items.forEach(({command, dom}) => {
      //   if (dom.contains(e.target)) { command(view.state, view.dispatch, view) }
      // })
    })
    this.hide()
    // hide toolbar when editor not focused
    if (!store.state.editor.freshDoc) {
      this.view.dom.addEventListener('blur', this.hide.bind(this))
    }
  }

  createTooltip() {
    const tooltip = document.createElement('div')
    tooltip.classList.add('plus-toolbar')
    tooltip.id = 'plusToolbar'
    return tooltip
  }

  // Step:2. Use prosemirror's update cycle to make sure
  // tooltip stays in sync with editor
  // called when views's state is updated
  update (view, lastState) {
    const pathname = window.location.pathname
    if (window._spext_transcriptMode || pathname === '/onboarding' || pathname === '/drive') {
      this.hide()
      return
    }
    if (document._isPillBeingDragged) {
      this.hide()
      return
    }
    if (myProseEditor.isUsedInProject) {
      this.hide()
      return
    }
    const doc = view.state.doc
    const anchor = doc.resolve(view.state.selection.anchor)
    const head = doc.resolve(view.state.selection.head)
    try {
      if (anchor.pos === head.pos) {
        let node = doc.nodeAt(anchor.pos)
        let truth = false
        if (node) {
          if (node.type.name === 'music' && node.attrs.type !== 'music') { // for type = endmusic hide it.
            truth = true
          } else {
            node.marks.forEach(function (mark) {
              if (mark.type.name === 'note') {
                truth = true
              }
            })
          }
          if (truth) {
            this.hide()
            return
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
    // console.log('plusToolbar update')
    // TODO: remove use of store
    // console.log('freshdoc', store.state.editor.freshDoc)
    if (!store.state.editor.freshDoc) {
      this.view.dom.addEventListener('blur', this.hide.bind(this))
    }

    // hide toolbar when playing
    if (!(store.state.app.isMobile && store.state.app.browserIs === 'safari') && myProseEditor && myProseEditor.audioControl && myProseEditor.audioControl.isPlaying) {
      // console.log('plusToolbar hiding 1')
      this.hide()
      return
    }

    // hide toolbar when something is selected
    if (!(store.state.app.isMobile && store.state.app.browserIs === 'safari') && (isTextSelected(view))) {
      // console.log('plusToolbar hiding 2')
      this.hide()
      return
    }

    let state = view.state
    // Don't do anything if the document/selection didn't change
    if (lastState && lastState.doc.eq(state.doc) &&
          lastState.selection.eq(state.selection)) {
      // console.log('hiding, selection did not change')
      return
    }

    // Hide the tooltip if the selection is empty
    // if (state.selection.empty) {
    //   this.hide()
    //   return
    // }
    // if (!this.tooltip.offsetParent) {
    //   console.log('hiding, offsetparent')
    //   return
    // }
    this.tooltip.style.display = ''
    // console.log('showing')
    // Otherwise, reposition it and update its content
    // this.tooltip.style.background = 'transparent'
    let { from } = state.selection
    // These are in screen coordinates
    let start = view.coordsAtPos(from)
    // console.log('start', start)
    // let end = view.coordsAtPos(to)
    //   // The box in which the tooltip is positioned, to use as base
    // Absolute
    if (!this.tooltip.offsetParent) {
      // console.log('plusToolbar hiding 3', this.tooltip)
      return
    }
    let box = this.tooltip.offsetParent.getBoundingClientRect()
    // console.log('box', box)

    let left = (start.left - box.left - magicNumberLeft)
    let top = (start.top - box.top - magicNumberTop)

    // Fixed
    // let left = (start.left - 9)
    // let top = (start.top - 20)

    this.tooltip.style.left = left + 'px'
    this.tooltip.style.top = top + 'px'
    // console.log('plusToolbar should be visible')
  }

  hide (event) {
    if (event && event.relatedTarget) {
      return
    }
    this.tooltip.style.display = 'none'
    if (document.getElementById('plusButton')) document.getElementById('plusButton').classList.remove('active')
  }

  destroy () {
    this.view.dom.removeEventListener('blur', this.hide)
    this.tooltip.remove()
  }
}

const items = [
  { command: showToolbar, dom: icon('plusButtonMarker', 'Add Media') }
]

// Helper function to create menu icons
function icon (text, title) {
  let element = crel('div', {
    'class': text,
    id: 'plusButton'
  })
  return element
}

function showToolbar () {
  console.log('showToolbar')
}
