<template>
  <div
    class="relative bg-black videoPlayer group"
    :class="{
      [`ar-${aspectRatio}`]: true,
      'w-full': (aspectRatio === 'landscape') && (vpRatio <= 1.7778),
      'h-full': (aspectRatio !== 'landscape') || (vpRatio > 1.7778)
    }"
    id="custom-video-renderer"
  >
    <Element
      v-show="mediaReady"
      :debug="debug"
      :autoplay="autoplay"
      :isPlaying="isPlaying"
      :currentTime="currentTime"
      :totalTime="totalTime"
      :scale="scale"
      :class="['relative', zIndexClass]"
      :progress="progress"
      :playerType="type"
    />

    <div
      class="absolute top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center max-w-full max-h-full overflow-hidden"
      v-if="type==='video' && showThumbnail && !isPlaying"
    >
      <Thumbnail
        :percentage="30"
      />
    </div>

    <div
      v-if="enableLogs"
      class="bg-white rounded-br rounded-bl text-primary py-0.25 px-2 top-0 right-0 text-sm z-infinity absolute w-56"
    >
      <div>
        Current time: {{humanLength(currentTime || 0)}}
      </div>
    </div>

    <div
      v-if="enableLogs"
      class="bg-white rounded-br rounded-bl text-primary py-0.25 px-2 top-0 left-0 text-sm z-infinity absolute w-96 max-h-72 overflow-y-auto"
    >
      <div v-for="(msg, i) in logs" :key="i" class="mb-2">
        {{msg}}
      </div>
    </div>

    <div v-if="enableLogs" class="absolute z-inifinity left-0 bottom-0 px-2 py-1 bg-white">
      {{type}}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { humanLength } from '@/utilities/utility'
import Element from '@/components/VideoPlayer/Element/index.vue'
import Thumbnail from '@/components/VideoPlayer/Thumbnail.vue'
import playerInitData, { subtitleStyle } from './playerElementsData'
import getAudiogramTemplate from './template'
// TODO: Make this componant light weight

export default {
  name: 'VideoPlayer',
  components: {
    Element,
    Thumbnail
  },
  props: {
    vpRatio: {
      type: Number,
      default: 1.7778
    },
    debug: {
      type: Boolean,
      default: false
    },
    subtitle: Array,
    autoplay: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    subtitleStyle: Object,
    scale: {
      type: Number,
      default: 1
    },
    showThumbnail: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'video'
    },
    videoPlayerInfo: {
      type: Object,
      default: () => ({})
    },
    zIndexClass: {
      type: String,
      default: 'z-20'
    },
    progress: {
      type: Number,
      default: 0
    },
    enableLogs: {
      type: Boolean,
      default: false
    },
    // we will use this prop to disable unnecessary watchers on the export page
    // false by default to enable dynamic templating functionalities
    playerMode: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      humanLength,
      schedule: null,
      docReady: false,
      initialised: false,
      tempCurrentTime: 0
    }
  },
  computed: {
    ...mapGetters({
      isPlaying: 'editor/isPlaying',
      totalTime: 'editor/totalTime',
      currentTime: 'doc/currentTime',
      isOverMusic: 'doc/isOverMusic',
      videoReady: 'video/sourcesReady',
      audioReady: 'editor/sourcesReady',
      logs: 'video/logs',
      sources: 'video/sources'
    }),
    defaultSubtitleStyle: function() {
      return subtitleStyle[this.type](this.scale)
    },
    activeSubtitle: function() {
      let ctime = this.currentTime
      if (this.tempCurrentTime) {
        ctime = this.tempCurrentTime
      }
      // const t1 = performance.now()
      const { enableSubtitleFormatting } = this.videoPlayerInfo
      let f = ''
      if (this.subtitleStyle || enableSubtitleFormatting) {
        f = this.isOverMusic ? null : this.subtitle.find(el => ctime >= el.start && ctime <= el.end)
      }
      // const t2 = performance.now()
      // console.log('Call to subtitle took ' + (t2 - t1) + ' milliseconds.')
      return f
    },
    mediaReady: function() {
      let isNewDoc = localStorage[this.$route.params.podId]
      if (isNewDoc) {
        return true
      }
      return this.initialised && this.docReady && this.videoReady && this.audioReady
    },
    enableSubtitleFormatting: function() {
      return this.videoPlayerInfo.enableSubtitleFormatting
    },
    aspectRatio: function() {
      return this.videoPlayerInfo.aspectRatio || 'landscape'
    }
  },
  methods: {
    ...mapActions({
      initPlayer: 'video/initPlayer',
      findAndUpdateElement: 'video/findAndUpdateElement',
      toggleState: 'editor/toggleState'
    }),
    transformFontSize: function(style) {
      console.log('THIS IS THE SCALE BEING APPLIED', this.scale)
      let { 'font-size': fontSize, ...rest } = style
      if (this.scale && fontSize.includes('px')) fontSize = `${Number(fontSize.replace('px', '') * this.scale)}px`
      return { ...rest, 'font-size': fontSize }
    },
    async initialisePlayerInfo() {
      this.initialised = false
      if (playerInitData[this.type]) {
        const initData = await playerInitData[this.type](this.scale, this.videoPlayerInfo, 'videoplayer')
        this.initPlayer(initData)
      } else this.initPlayer(playerInitData.video(this.scale))
      this.setActiveSubtitle()
      this.initialised = true
    },
    setActiveSubtitle: function() {
      const { showSampleSubtitle, enableSubtitleFormatting, subtitleStyle: additionalSubtitleStyle, template, aspectRatio } = this.videoPlayerInfo
      const tstyles = getAudiogramTemplate(this.scale, this.videoPlayerInfo)[(template || 'one')][aspectRatio || 'landscape']
      if (showSampleSubtitle) {
        if (this.subtitleStyle || enableSubtitleFormatting) {
          this.findAndUpdateElement({
            find: { by: 'name', value: 'ce-subtitle' },
            update: {
              value: showSampleSubtitle ? (tstyles['ce-subtitle'].value || 'Transcript will be here') : this.activeSubtitle.text,
              style: this.transformFontSize({
                ...this.defaultSubtitleStyle,
                ...this.subtitleStyle,
                ...additionalSubtitleStyle,
                ...(this.type === 'audiogram' ? tstyles['ce-subtitle'].style : {})
              })
            }
          })
        }
      } else if (this.activeSubtitle && this.activeSubtitle.text) {
        if (this.subtitleStyle || enableSubtitleFormatting) {
          this.findAndUpdateElement({
            find: { by: 'name', value: 'ce-subtitle' },
            update: {
              value: showSampleSubtitle ? (tstyles['ce-subtitle'].value || 'Transcript will be here') : this.activeSubtitle.text,
              style: this.transformFontSize({
                ...this.defaultSubtitleStyle,
                ...this.subtitleStyle,
                ...additionalSubtitleStyle,
                ...(this.type === 'audiogram' ? tstyles['ce-subtitle'].style : {})
              })
            }
          })
        }
      }
    }
  },
  watch: {
    activeSubtitle: function(val) {
      const { showSampleSubtitle, enableSubtitleFormatting, subtitleStyle: additionalSubtitleStyle, template, aspectRatio } = this.videoPlayerInfo

      if (!showSampleSubtitle) {
        if (val && val.text) {
          // console.log('sadsad 1', val, this.subtitleStyle, enableSubtitleFormatting)
          const tstyles = getAudiogramTemplate(this.scale, this.videoPlayerInfo)[(template || 'one')][aspectRatio || 'landscape']
          if (this.subtitleStyle || enableSubtitleFormatting) {
            this.findAndUpdateElement({
              find: { by: 'name', value: 'ce-subtitle' },
              update: {
                value: showSampleSubtitle ? (tstyles['ce-subtitle'].value || 'Transcript will be here') : val.text,
                style: this.transformFontSize({
                  ...this.defaultSubtitleStyle,
                  ...this.subtitleStyle,
                  ...additionalSubtitleStyle,
                  ...(this.type === 'audiogram' ? tstyles['ce-subtitle'].style : {})
                })
              }
            })
          }
        } else {
          // console.log('sadsad 2')

          // if (this.type !== 'audiogram') {
          this.findAndUpdateElement({
            find: { by: 'name', value: 'ce-subtitle' },
            update: { value: '' }
          })
          // }
        }
      }
    },
    subtitleStyle: function(val, oldVal) {
      const { showSampleSubtitle, subtitleStyle: additionalSubtitleStyle, template, aspectRatio } = this.videoPlayerInfo
      if (val) {
        const tstyles = getAudiogramTemplate(this.scale, this.videoPlayerInfo)[(template || 'one')][aspectRatio || 'landscape']
        this.findAndUpdateElement({
          find: { by: 'name', value: 'ce-subtitle' },
          update: {
            ...(showSampleSubtitle ? { value: (tstyles['ce-subtitle'].value || 'Transcript will be here') } : {}),
            style: this.transformFontSize({
              ...this.defaultSubtitleStyle,
              ...val,
              ...additionalSubtitleStyle,
              ...(this.type === 'audiogram' ? tstyles['ce-subtitle'].style : {})
            }),
            hidden: false
          }
        })
      }
      if (oldVal && !val) {
        this.findAndUpdateElement({
          find: { by: 'name', value: 'ce-subtitle' },
          update: { hidden: true }
        })
      }
    },
    loading: function(val, oldVal) {
      if (oldVal && !val) this.docReady = true
    },
    mediaReady: function(val, oldVal) {
      if (this.autoplay && !oldVal && val) {
        this.$emit('goToTime', 0)
        // store.commit('video/addLog', `Waiting for video hydration ${Date.now()}`)
        console.log('Cached videos 1', Object.values(this.sources).map(t => Boolean(t)))
        setTimeout(() => {
          this.toggleState(true)
          console.log('Cached videos 2', Object.values(this.sources).map(t => Boolean(t)))
          // store.commit('video/addLog', `Recording started, Bool(window.startRecording) - ${typeof window.startRecording}`)
          if (window.startRecording) {
            window.startRecording()
          }
        }, 30000)
      }
    },
    videoPlayerInfo: function() {
      if (!this.initialised) this.initialisePlayerInfo()
    }
  },
  mounted () {
    if (this.$route.name === 'SpextPlayer') {
      this.spextPlayerInterval = setInterval(() => {
        if (document._spext_currentTime) {
          this.tempCurrentTime = document._spext_currentTime
        }
      }, 2000)
    }
  },
  created: async function() {
    this.initialisePlayerInfo()
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Alfa+Slab+One&family=Anton&family=Archivo&family=Archivo+Black&family=Arimo&family=Bad+Script&family=Ballet&family=Bangers&family=Calistoga&family=Chewy&family=Cinzel&family=Courier+Prime&family=Iceberg&family=Just+Another+Hand&family=Kumar+One+Outline&family=Lobster&family=Oswald&family=Pacifico&family=Permanent+Marker&family=Playfair+Display&family=Poppins&family=Rakkas&family=Roboto&family=Rubik&family=Shrikhand&family=Squada+One&family=Titan+One&family=Work+Sans&family=ZCOOL+KuaiLe&family=Zilla+Slab&display=swap');

.ar-landscape {
  aspect-ratio: 16 / 9;
}

.ar-square {
  aspect-ratio: 1 / 1;
}

.ar-portrait {
  aspect-ratio: 9 / 16;
}

.videoPlayer:not(#fullscreen-ce-player) {
  max-width: 535px;
}
</style>
