import { makeSelection, addMarkDeleted, moveCursorToBoundary } from '../util/utility'
import { copyNode } from '../importedFromProse/prosemirror-view/input'
import { addMarkHideTranscript } from '../util/utilityTranscript'

/**
 * when user click CMD+X
 * @param {*} view - prosemirror object
 * @param {*} event - html event -> used for copynode
 */
export function cut (view, event, isUsedInProject = false) {
  if (isUsedInProject) {
    event.preventDefault()
    return
  }

  let state = view.state
  let dispatch = view.dispatch

  if (window.mode === 'editTranscript') {
    copyNode(view, event) // copy the current selection
    for (let i = 0; i < view.state.selection.ranges.length; i++) {
      let { $from, $to } = view.state.selection.ranges[i]
      addMarkHideTranscript(view.state, view.dispatch, $from.pos, $to.pos) // add a mark to hide transcript
    }
    moveCursorToBoundary(view) // move cursor
    return true
  } else {
    makeSelection(state, dispatch) // slelect the whole word
    copyNode(view, event) // copy selection

    // uses view otherwise makeselectin effect is not seen
    for (let i = 0; i < view.state.selection.ranges.length; i++) {
      let { $from, $to } = view.state.selection.ranges[i]
      addMarkDeleted({ key: 'cut', value: true }, view.state, view.dispatch, $from.pos, $to.pos)
    }
    moveCursorToBoundary(view)
    return true
  }
}
