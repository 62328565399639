<template>
  <div class="stringwave w-full h-full">
    <!-- #TODO: viewBox = 0 0 this.width this.height -->
    <svg viewBox="0 0 365 50" class="chart w-full">
      <path
        fill="None"
        id="wave3"
        :d="generatedPath"
        :stroke="waveColor"
        stroke-width="2px"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'StringWave',
  data() {
    return {
      generatedPath: `M -5 25L -5 25Q 0 10.844607632790911 12.166666666666666
        25.422303816395456Q 24.333333333333332 40 36.5
        30.960135597266124Q 48.666666666666664 21.920271194532248 60.83333333333333
        30.960135597266124Q 73 40 85.16666666666667
        25.715986750841623Q 97.33333333333333 11.431973501683242 109.5
        16.998263770314345Q 121.66666666666667 22.564554038945452 133.83333333333331
        30.761198552314383Q 146 38.95784306568331 158.16666666666666
        28.03940130102431Q 170.33333333333334 17.120959536365305 182.49999999999997
        13.560479768182653Q 194.66666666666666 10 206.83333333333331
        25Q 219 40 231.16666666666666
        30.42603262521819Q 243.33333333333334 20.852065250436382 255.49999999999997
        15.426032625218191Q 267.6666666666667 10 279.8333333333333
        23.130164620630573Q 292 36.26032924126115 304.1666666666667
        28.36494574225596Q 316.3333333333333 20.46956224325077 328.5
        21.566220330886114Q 340.6666666666667 22.66287841852146 352.8333333333333
        28.41461713244054Q 365 34.16635584635962 377.1666666666667
        23.745309302408117`
    }
  },
  props: {
    /*
    scales ~ amplitude array for the wave
    waveformData ~ audio's sampled data
    */
    waveColor: {
      type: String,
      default: '#000228'
    },
    width: {
      type: Number,
      default: 365
    },
    height: {
      type: Number,
      default: 50
    },
    scales: {
      type: Array,
      default: () => new Array(17).fill(0)
    },
    waveformData: {
      type: Array,
      default: () => []
    }
  },
  methods: {},
  computed: {
    waveMax: function () {
      return Math.max(...this.waveformData) || 50
    }
  },
  watch: {
    waveformData() {
      this.scales.shift()
      this.scales.push(
        // -1 or +1  * (audiodata* wavemax) * (streched on 30% of height)
        (((Math.random() > 0.5 ? -1 : 1) * this.waveformData[0]) /
          this.waveMax) *
          (this.height * 0.3)
      )

      let d = ''
      d += `M -5 ${this.height / 2}`
      d += `L -5 ${this.height / 2}`

      for (let i = 0; i < this.scales.length - 1; i++) {
        const scale = this.scales[i]
        d += `Q ${(i * this.width) / 15} ${this.height / 2 + scale} ${
          i * (this.width / 15) + this.width / 30
        }
        ${this.height / 2 + scale + (this.scales[i + 1] - scale) / 2}`
      }
      this.generatedPath = d
    }
  }
}
</script>

<style scoped lang="css">
.stringwave {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(-1, -1);
}
svg {
  transition: 0.2s;
}
#wave3 {
  transition: all 0.2s ease;
}
</style>
