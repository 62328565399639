import { DecorationSet, Decoration, EditorView } from 'prosemirror-view'
import { Node } from 'prosemirror-model'
import { EditorState, Plugin, TextSelection } from 'prosemirror-state'
import { keymap } from 'prosemirror-keymap'
import { undo, redo, history } from 'prosemirror-history'
// import { dropCursor } from 'prosemirror-dropcursor'
// import applyDevTools from 'prosemirror-dev-tools'
import { schema } from './Schema/schema'
import debounce from 'lodash/debounce'
import store from '@/services/store'
import {
  showDeleted,
  setShowDeleted,
  setShowTranscript,
  setAddNoise,
  addNoise,
  setIsDemo,
  isDemo
} from './Marks'
import { splitBlockAfterWord, restore } from './commands'
import {
  handleCopy,
  handlePaste,
  handleCut,
  handleText,
  handleCursor,
  handleDrop,
  handleSelection,
  colorFillerWords,
  transformPasted
} from './plugins'
import './prosemirror.css'
import './musicNote.css'
import './style.less'
import {
  skipAndSelectNode,
  goToPara,
  computeOnDispatchTransaction,
  moveCursorToBoundary,
  calculateTime,
  getParagraphMeta,
  setParagraphMeta,
  selectPosition,
  cleanDocOfOrphanMusicNotes,
  addCommentMark,
  removeCommentMark,
  focusCursorOnLocation,
  computeDeletedTimeInDoc,
  getSnappedSelectionForLocation,
  addNoteMarkAtLocationInTransaction,
  removeMarkAtLocationInTransaction,
  findNodesWithNoMusicReach,
  addMarkAtLocation,
  findAllNodesWithCurrentMusic,
  reComputeMusicReach,
  reRenderBlockModeIfOpened,
  updateBlockModeInfo,
  pinMusicEndPill,
  pinMusicStartPill,
  highlightTextWithPinnedMusic,
  updateMusicPillAttributes,
  isNoteSymbol,
  getNoteId,
  initProsEditor,
  myProseEditor,
  setPlayingSelectionHighlight,
  setupCorrectTranscriptPopup, correctTranscript,
  toggleAddButton
} from './util/utility'
import { selectionToolbar } from './menu/selectionToolbar'
// import { sideToolbar } from './menu/sideToolbar'
import { plusToolbar } from './menu/plusToolbar'
// import { floatingMenu } from './menu/floating'
import { selectAll, baseKeymap, chainCommands, joinBackward, toggleMark } from 'prosemirror-commands'
import { remove, removeThisWord, removePrevWord } from './commands/remove'
import { deleteSelection } from './commands/removeTranscript'

import { selectionToolbarTranscript } from './menu/selectionToolbarTranscript'
import uuidv1 from 'uuid/v1'
import { musicHoverToolbar } from './menu/musicHoverToolbar'
import { SetDocAttr } from './Steps/SetDocAttr'
import { getElementsByAttribute, uniqueUidColor } from '@/utilities/utility'
import { silenceSymbol } from '@/view/voiceEditor/proseEditor/CONSTANTS'
import { snapSelection } from '@/view/voiceEditor/proseEditor/plugins/handleSelection'
const TIMESTAMP = { '.sv': 'timestamp' }
const { Selection } = require('prosemirror-state')
const { Step } = require('prosemirror-transform')
const { collab, sendableSteps, receiveTransaction } = require('prosemirror-collab')
const { compressStepsLossy, compressSelectionJSON, uncompressSelectionJSON, compressStepJSON, uncompressStepJSON } = require('prosemirror-compress')

// when user create a new spext doc
const emptyDocument = Node.fromJSON(schema, {
  content: [],
  type: 'doc'
})
// let historyPlugin = history()
// const noterPlugin = noter(schema.marks.note, emptyDocument, historyPlugin)
const commonKeymap = {
  'Mod-z': undo,
  'Shift-Mod-z': redo
}
const editTranscriptKeymap = {
  ...baseKeymap,
  ...commonKeymap,
  'Mod-b': toggleMark(schema.marks.strong),
  'Mod-i': toggleMark(schema.marks.em),
  'Mod-u': toggleMark(schema.marks.underline),
  'Mod-e': toggleMark(schema.marks.highlight),
  Backspace: chainCommands(deleteSelection, joinBackward),
  Delete: chainCommands(deleteSelection, joinBackward)
}
// TODO: update delete commands
// they act like backspace, but should move forward
// required in windows machine
const editMediaKeymap =
  {
    ...commonKeymap,
    Enter: splitBlockAfterWord,
    Backspace: chainCommands(remove, removeThisWord, removePrevWord, joinBackward),
    Delete: chainCommands(remove, removeThisWord, removePrevWord, joinBackward),
    'Mod-b': restore,
    'Mod-a': selectAll,
    'Shift-Delete': chainCommands(remove, removeThisWord, removePrevWord, joinBackward),
    'Shift-Backspace': chainCommands(remove, removeThisWord, removePrevWord, joinBackward)
  }

// This disables certain shortcuts in clip editor
const projectEditMediaKeymap = {
  Enter: () => true,
  Backspace: () => true,
  Delete: () => true,
  'Mod-a': () => true,
  'Mod-b': () => true,
  'Shift-Delete': () => true,
  'Shift-Backspace': () => true,
  'Mod-Backspace': () => true,
  'Mod-Delete': () => true
}
export default class {
  /* order of mark specification matters,
  right now deleted will take precedence
  if both pasted and deleted applied */
  constructor (elementToMountOn, mode, firebaseRef, clientID, firebase, isDemo, isUsedInProject = false, isAutoScroll, preventMountFlag = false) {
    initProsEditor(this)
    this.preventMountFlag = preventMountFlag
    this.isAutoScroll = isAutoScroll
    this.isUsedInProject = isUsedInProject
    this.firebase = firebase
    this.isDemo = isDemo
    this.width = 2
    this.color = 'black'
    this.cursorPos = null
    this.element = null
    this.timeout = null
    this.audioControl = null
    this.view = null
    this.editorState = null
    this.musicAudioBufferList = {}
    this.clipperActive = false
    this.clickPlugin = new Plugin({
      props: {
        handleDOMEvents: {
          click: async (view, event) => {
            store.commit('editor/setIsPlayerGettingReady', true)
            setPlayingSelectionHighlight(view)
            if (window._spext_transcriptMode) {
              this.showCorrectTranscriptModal()
              return
            }
            // this.removeCustomHighlight()
            // let oid = 'MTNd7878T3MMJFAy24btur6Rs662'
            // let did = '11ec-0308-4cb4ecf0-b868-47417bd702f0'
            // let sss = await createProseDoc(oid, did, firebase)
            // let ss = sss.state
            // let lkey = sss.latestKey
            //  addAudioVideo(ss, 'https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3', 'asddas', 'm-11eb-e660-8597f324-bc71-0242ac110005#!@MTNd7878T3MMJFAy24btur6Rs662', 1, 1, function(state) {
            //
            //   saveDoc(state, oid, did, firebase, lkey)
            // })

            // view.dispatch(view.state.tr.setSelection(
            //   new TextSelection(view.state.doc.resolve(view.state.selection.anchor), view.state.doc.resolve(view.state.selection.anchor)))
            //   .scrollIntoView())
            // view.focus()
            if (this.audioControl) {
              this.audioControl.currentSelectionAnchor = view.posAtCoords({ left: event.clientX, top: event.clientY }).pos
              // let currentNode = this.view.state.doc.nodeAt(this.audioControl.currentSelectionAnchor)
              // let audiAttrs = getMediaAttributes(currentNode.marks)
              // store.commit('video/findAndUpdateElement', {
              //   find: { by: 'name', value: 'ce-video' },
              //   update: {
              //     id: audiAttrs.asource,
              //     value: this.audioControl.videoUrlList[audiAttrs.asource],
              //     type: 'video/mp4',
              //     currentTime: store.state.doc.currentTime
              //   }
              // })
              this.audioControl.playFromHere()
            }
            let isBlockModeOpen = store.state.editor.panelToShow === 'blockmode'
            if (this.blockModeConfigObj) {
              document._isCursorOnMusic = true
              if (!myProseEditor.isUsedInProject && (!isBlockModeOpen || document._pinnedMusicUid !== this.blockModeConfigObj.uid)) {
                store.commit('blockmode/update', this.blockModeConfigObj)
                store.commit('blockmode/open')
                let initialPos = view.state.selection.anchor
                view.dispatch(view.state.tr.setSelection(
                  new TextSelection(view.state.doc.resolve(view.state.doc.content.size), view.state.doc.resolve(view.state.doc.content.size)))
                  .scrollIntoView())
                view.dispatch(view.state.tr.setSelection(
                  new TextSelection(view.state.doc.resolve(initialPos), view.state.doc.resolve(initialPos)))
                  .scrollIntoView())
                // view.focus()
                this.managePinnedPillVisuals()
              } else {
                // if (document._pinnedMusicUid === this.blockModeConfigObj.uid) {
                //   store.dispatch('blockmode/close')
                // }
              }
            } else { // close if clicked outside music text
              document._isCursorOnMusic = false

              // if (store.state.editor.panelToShow === 'blockmode') store.dispatch('blockmode/close')
            }
          },
          dblclick: (view, event) => {
            if (!this.isUsedInProject && store.state.editor.editMode === 'editMedia') {
              this.showCorrectTranscriptModal('dblclick')
            }
          },
          mouseover: (view, event) => {
            if (!myProseEditor.isUsedInProject && !this.clipperActive) {
              const doc = view.state.doc
              let pos = view.posAtCoords({ left: event.clientX, top: event.clientY })
              let currentMusicNode = doc.nodeAt(pos.pos)
              if (currentMusicNode && !this.musicPillDragStarted && !this.isDemo) {
                if (isNoteSymbol(currentMusicNode.marks)) {
                  let noteUid = getNoteId(currentMusicNode.marks)
                  this.applyHoverToMusicArea(noteUid)
                  this.currentMusicUid = noteUid
                } else if (currentMusicNode.attrs.type === 'music') {
                  this.applyHoverToMusicArea(currentMusicNode.attrs.uid)
                  if (!this.leftMusicDrageSetup || (this.currentMusicUid && this.currentMusicUid !== currentMusicNode.attrs.uid)) {
                    this.setupDragEventsForMusicPills('left', currentMusicNode.attrs.uid)
                  }
                  this.leftMusicDrageSetup = true
                  this.currentMusicUid = currentMusicNode.attrs.uid
                } else if (currentMusicNode.attrs.type === 'endMusic') {
                  this.applyHoverToMusicArea(currentMusicNode.attrs.uid)
                  if (!this.rightMusicDrageSetup || (this.currentMusicUid && this.currentMusicUid !== currentMusicNode.attrs.uid)) {
                    this.setupDragEventsForMusicPills('right', currentMusicNode.attrs.uid)
                  }
                  this.rightMusicDrageSetup = true
                  this.currentMusicUid = currentMusicNode.attrs.uid
                } else {
                  if ((this.musicAreaHoverApplied || this.rightMusicDrageSetup || this.leftMusicDrageSetup) && !this.musicPillDragStarted && this.currentMusicUid) {
                    this.pillHoverCleanUp(this.currentMusicUid)
                    this.musicAreaHoverApplied = false
                    this.leftMusicDrageSetup = false
                    this.rightMusicDrageSetup = false
                    this.currentMusicUid = null
                    this.blockModeConfigObj = null
                    document._isCursorOnMusic = false
                  }
                }
              }
            }
          },
          mousedown: (view, event) => {
            toggleAddButton(false)
          },
          mouseup: (view, event) => {
            // if (!window._spext_transcriptMode &&
            //   this.view.state.selection.anchor === this.view.state.selection.head) {
            //   let textSel = snapSelection(view, this.view.state.doc.resolve(this.view.state.selection.anchor), this.view.state.doc.resolve(this.view.state.selection.anchor))
            //   // console.log('asdcasdc', { textSel })
            //   // if (textSel.from === textSel.to) {
            //   //   toggleAddButton(true)
            //   // }
            //   try {
            //     // view.dispatch(
            //     //   view.state.tr.scrollIntoView().setSelection(textSel)
            //     // )
            //     view.dispatch(
            //       view.state.tr.scrollIntoView().setSelection(textSel)
            //     )
            //   } catch (e) {
            //     console.log('caught err', e)
            //   }
            // }
            document.documentElement.style.setProperty('--selection-background', '#F8C45080')
          }
        },
        handleClick(view, pos, event) {
          let textSel = snapSelection(view, view.state.doc.resolve(pos), view.state.doc.resolve(pos))
          if (textSel.from === textSel.to) {
            toggleAddButton(true)
          } else {
            view.dispatch(view.state.tr.setSelection(new TextSelection(view.state.doc.resolve(0))))
            view.dispatch(view.state.tr.setSelection(textSel))
          }
          // console.log({ from: textSel.from, to: textSel.to })
        },
        scrollMargin: 200,
        // scrollingThreshold: 100,
        editable () {
          if (store.state.app.isMobile) {
            return false
          }
          return true
        }
      }
    })
    this.clientID = `${clientID}-${uuidv1()}` // this is for user, while we are doing collaborative editing. It depends on sessions. So new tab will have diff client id
    this.debouncedEmitUpdate = null
    this.debouncedComputeOnDispatchTransaction = debounce(this.registerComputeOnDispatchTransaction, 200)
    // this.checkpointRef = firebaseRef.child('checkpoint')
    this.changesRef = firebaseRef.child('changes') // this is for collaboration, we'll save changes there
    this.selectionsRef = firebaseRef.child('selections') // this is for collaboration to communicate selections
    this.selfSelectionRef = this.selectionsRef.child(clientID)
    this.selfSelectionRef.onDisconnect().remove().catch(function (err) {
      console.log('err in onDisconnect', err.code)
    })
    this.selfChanges = {}
    this.latestKey = 0
    this.selections = {}
    this.comment = {}
    this.proseEditorView(elementToMountOn, mode) // which element to mount on html
    this.contacts = {}
    this.musicAreaHoverApplied = false
    this.leftMusicDrageSetup = false
    this.rightMusicDrageSetup = false
    this.currentMusicUid = null
    // this.pinnedMusicUid = null
  }
  showCorrectTranscriptModal () {
    let view = this.view
    let state = this.view.state
    let textSel = snapSelection(view, this.view.state.doc.resolve(this.view.state.selection.anchor), this.view.state.doc.resolve(this.view.state.selection.head))
    if (textSel.from !== textSel.to) {
      document._correctTranscriptSelection = {
        from: textSel.from,
        to: textSel.to
      }
      try {
        view.dispatch(
          view.state.tr.scrollIntoView().setSelection(textSel)
        )
      } catch (e) {
        console.log('caught err', e)
      }
      let { inputElement, buttonElement } = setupCorrectTranscriptPopup(view, false)

      if (inputElement.value === '') {
        inputElement.value = view.state.doc.resolve(this.view.state.selection.anchor).nodeAfter.text
        textSel = {}
        textSel.from = this.view.state.selection.anchor
        textSel.to = this.view.state.selection.anchor + view.state.doc.resolve(this.view.state.selection.anchor).nodeAfter.nodeSize
      }

      buttonElement.addEventListener('click', e => {
        e.preventDefault()
        let start = textSel.from
        let end = textSel.to
        if (end < start) {
          start = textSel.to
          end = textSel.from
        }
        localStorage.setItem('onBoardingEditTranscriptFirstPopover', true)
        correctTranscript(view, start, end, inputElement)
      })

      inputElement.addEventListener('keyup', function (event) {
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
          event.preventDefault()
          let start = textSel.from
          let end = textSel.to
          if (end < start) {
            start = textSel.to
            end = textSel.from
          }
          correctTranscript(view, start, end, inputElement)
        }
      })
    }
  }
  removeCustomHighlight() {
    let truth = document._customTextSelection
    document._customTextSelection = null
    if (truth) {
      this.view.dispatch(this.view.state.tr.setSelection(
        new TextSelection(this.view.state.doc.resolve(this.view.state.selection.anchor), this.view.state.doc.resolve(this.view.state.selection.anchor)))
        .scrollIntoView())
    }
  }
  applyHoverToMusicArea(uid) {
    document._isCursorOnMusic = true
    if (this.currentMusicUid && this.currentMusicUid !== uid) {
      this.pillHoverCleanUp(this.currentMusicUid)
    }
    if (!this.musicAreaHoverApplied || (this.currentMusicUid && this.currentMusicUid !== uid)) {
      let musicStartPillElement = this.hoverMusicStartPill(uid)
      let musicEndPillElement = this.hoverMusicEndPill(uid)
      this.highlightTextWithMusic(uid)
      reComputeMusicReach(this.view)
      this.updateBlockModeInfo(musicStartPillElement, musicEndPillElement)
    }
    this.musicAreaHoverApplied = true
  }
  updateBlockModeInfo(musicStartPillElement, musicEndPillElement, reset = false) {
    this.blockModeConfigObj = updateBlockModeInfo(musicStartPillElement, musicEndPillElement, this.view, reset)
  }
  setupDragEventsForMusicPills(whichPill, uid) {
    let startPillElement = null
    let endPillElement = null
    let musicPillElement = null // dragged pill
    startPillElement = getElementsByAttribute('uid', 'music-' + uid)
    endPillElement = getElementsByAttribute('uid', 'endmus-' + uid)
    if (startPillElement.length > 0) {
      startPillElement = startPillElement[0]
    }
    if (endPillElement.length > 0) {
      endPillElement = endPillElement[0]
    }
    if (whichPill === 'left') {
      musicPillElement = startPillElement
    } else if (whichPill === 'right') {
      musicPillElement = endPillElement
    }

    if (startPillElement && endPillElement) {
      musicPillElement.onmousedown = (event) => {
        document._selection_background = document.documentElement.style.getPropertyValue('--selection-background')
        let view = this.view
        let pos = view.posAtCoords({ left: event.clientX, top: event.clientY })
        if (!this.dragStarted) {
          this.draggedNodePos = pos
        }
        this.dragStarted = true
        musicPillElement.style.opacity = 0.5
        document.onmouseup = (event) => {
          if (this.musicPillDragStarted) {
            document.documentElement.style.setProperty('--selection-background', document._selection_background)
            let pos1 = view.posAtCoords({ left: event.clientX, top: event.clientY })
            let otherPillElement
            if (whichPill === 'left') {
              otherPillElement = endPillElement
            } else if (whichPill === 'right') {
              otherPillElement = startPillElement
            }
            try {
              let otherPillPosition = view.posAtDOM(otherPillElement)
              if ((whichPill === 'left' && pos1.pos < otherPillPosition) || (whichPill === 'right' && pos1.pos > otherPillPosition)) {
                let node = this.view.state.doc.nodeAt(pos1.pos)
                if (!isNoteSymbol(node.marks) || !(isNoteSymbol(node.marks) && getNoteId(node.marks) !== uid)) {
                  this.commitSelection(pos1.pos, view.posAtDOM(musicPillElement))
                }
              }
              this.updateBlockModeInfo(getElementsByAttribute('uid', 'music-' + uid)[0], getElementsByAttribute('uid', 'endmus-' + uid)[0], true)
              this.updateMusicPillAttributes(this.blockModeConfigObj)
              store.commit('blockmode/update', this.blockModeConfigObj)
              this.managePinnedPillVisuals()
              // reapply hovering etc. by initing these values to

              this.musicAreaHoverApplied = false
              this.leftMusicDrageSetup = false
              this.rightMusicDrageSetup = false
              this.currentMusicUid = null
              this.blockModeConfigObj = null
            } catch (err) {
              console.log(err)
            }
          }
          musicPillElement.style.opacity = 1
          this.draggedNodePos = null
          this.dragStarted = false
          document.onmouseup = null
          document.onmousemove = null
          musicPillElement.onmousedown = null
          this.musicPillDragStarted = document._isPillBeingDragged = false
          this.scheduleRemoval(100)

          if (whichPill === 'right') {
            this.setupDragEventsForMusicPills('right', this.currentMusicUid)
          } else if (whichPill === 'left') {
            this.setupDragEventsForMusicPills('left', this.currentMusicUid)
          }
        }
        document.onmousemove = (event) => {
          this.musicPillDragStarted = document._isPillBeingDragged = whichPill
          let pos = this.view.posAtCoords({ left: event.clientX, top: event.clientY })
          this.dragStarted = true
          if (pos) {
            try {
              let target = pos.pos
              this.makeSelection(target)
              this.setCursor(target, musicPillElement)
            } catch (err) {
              console.log(err)
            }
          }
        }
      }
    }
  }
  pillHoverCleanUp(musicUid) {
    if (!musicUid) {
      musicUid = this.currentMusicUid
    }
    if (musicUid) {
      let musicPillElement = getElementsByAttribute('uid', 'music-' + musicUid)
      if (musicPillElement.length > 0) {
        setTimeout(function () {
          musicPillElement[0].classList.remove('localAudioHover')
        }, 20)
      }
      let endMusicPillElement = getElementsByAttribute('uid', 'endmus-' + musicUid)
      if (endMusicPillElement.length > 0) {
        setTimeout(function () {
          endMusicPillElement[0].classList.remove('localAudioEndHover')
        }, 30)
      }
      let musicTextElements = getElementsByAttribute('data-note_uid', musicUid)
      if (musicTextElements.length > 0) {
        musicTextElements.forEach(element => {
          setTimeout(function () {
            element.classList.remove('musicHover')
          }, 100)
        })
      }
    }
  }
  pillPinnedCleanUp(musicUid) {
    if (!musicUid) {
      musicUid = document._pinnedMusicUid
    }
    if (musicUid) {
      let musicPillElement = getElementsByAttribute('uid', 'music-' + musicUid)
      if (musicPillElement.length > 0) {
        setTimeout(function () {
          musicPillElement[0].classList.remove('localAudioPinned')
        }, 20)
      }
      let endMusicPillElement = getElementsByAttribute('uid', 'endmus-' + musicUid)
      if (endMusicPillElement.length > 0) {
        setTimeout(function () {
          endMusicPillElement[0].classList.remove('localAudioEndPinned')
        }, 30)
      }
      let musicTextElements = getElementsByAttribute('data-note_uid', musicUid)
      if (musicTextElements.length > 0) {
        musicTextElements.forEach(element => {
          setTimeout(function () {
            element.classList.remove('musicPinned')
          }, 10)
        })
      }
    }
    document._pinnedMusicUid = null
  }
  hoverMusicStartPill(uid) {
    let el = getElementsByAttribute('uid', 'music-' + uid)
    if (el.length > 0) {
      setTimeout(function () {
        el[0].classList.add('localAudioHover')
      }, 10)
      return el[0]
    }
  }

  hoverMusicEndPill(uid) {
    let el = getElementsByAttribute('uid', 'endmus-' + uid)
    if (el.length > 0) {
      setTimeout(function () {
        el[0].classList.add('localAudioEndHover')
      }, 10)
      return el[0]
    }
  }
  highlightTextWithMusic(noteUid) {
    let el = getElementsByAttribute('data-note_uid', noteUid)
    if (el.length > 0) {
      el.forEach(element => {
        setTimeout(function () {
          element.classList.add('musicHover')
        }, 10)
      })
    }
  }
  scheduleRemoval(timeout) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.setCursor(null), timeout)
  }
  setCursor(pos, el) {
    if (pos === this.cursorPos) return
    this.cursorPos = pos
    if (pos === null) {
      this.element.remove()
      this.element = null
    } else {
      this.updateOverlay(el)
    }
  }
  updateOverlay(el) {
    let $pos = this.view.state.doc.resolve(this.cursorPos); let rect
    if (!$pos.parent.inlineContent) {
      let before = $pos.nodeBefore; let after = $pos.nodeAfter
      if (before || after) {
        let nodeRect = this.view.nodeDOM(this.cursorPos - (before ? before.nodeSize : 0)).getBoundingClientRect()
        let top = before ? nodeRect.bottom : nodeRect.top
        if (before && after) { top = (top + this.view.nodeDOM(this.cursorPos).getBoundingClientRect().top) / 2 }
        rect = { left: nodeRect.left, right: nodeRect.right, top: top - el.offsetWidth / 2, bottom: top + el.offsetWidth / 2 }
      }
    }
    if (!rect) {
      let coords = this.view.coordsAtPos(this.cursorPos)
      rect = { left: coords.left - el.offsetWidth / 2, right: coords.left + el.offsetWidth / 2, top: coords.top, bottom: coords.bottom }
    }

    let parent = this.view.dom.offsetParent
    if (!this.element) {
      this.element = parent.appendChild(el.cloneNode(true))
      this.element.opacity = 1
      this.element.style.cssText = 'position: absolute; z-index: 50; pointer-events: none; background-color: none' + this.color
    }
    let parentRect = parent === document.body && getComputedStyle(parent).position === 'static'
      ? { left: -pageXOffset, top: -pageYOffset } : parent.getBoundingClientRect()
    this.element.style.left = (rect.left - parentRect.left) + 'px'
    this.element.style.top = (rect.top - parentRect.top - 2) + 'px'
    this.element.style.width = (rect.right - rect.left) + 'px'
    this.element.style.height = (rect.bottom - rect.top + 4) + 'px'
  }
  makeSelection (toPos) {
    let fromPos = this.draggedNodePos.pos
    let view = this.view
    let state = this.view.state
    let dispatch = this.view.dispatch
    let transaction = this.view.state.tr
    const doc = this.view.state.doc
    let startResolvedPos = null
    let endResolvedPos = null
    let currentMusicNode = doc.nodeAt(fromPos)
    if (currentMusicNode.attrs.type === 'music') {
      if (toPos < fromPos) {
        let { start, end } = getSnappedSelectionForLocation(toPos, fromPos, view)
        toPos = start
        startResolvedPos = doc.resolve(toPos)
        endResolvedPos = doc.resolve(fromPos)

        dispatch(state.tr.setSelection(new TextSelection(endResolvedPos, startResolvedPos)))
      } else if (fromPos < toPos) {
        let { start, end } = getSnappedSelectionForLocation(toPos, toPos + doc.nodeAt(toPos).nodeSize, view)
        toPos = start
        startResolvedPos = doc.resolve(fromPos)
        endResolvedPos = doc.resolve(toPos)

        dispatch(state.tr.setSelection(new TextSelection(startResolvedPos, endResolvedPos)))
      }
    } else if (currentMusicNode.attrs.type === 'endMusic') {
      if (fromPos < toPos) {
        let { start, end } = getSnappedSelectionForLocation(toPos, toPos + 1, view)
        toPos = end
        startResolvedPos = doc.resolve(fromPos)
        endResolvedPos = doc.resolve(toPos)

        dispatch(state.tr.setSelection(new TextSelection(startResolvedPos, endResolvedPos)))
      } else if (toPos < fromPos) {
        let { start, end } = getSnappedSelectionForLocation(toPos, toPos + doc.nodeAt(toPos).nodeSize, view)
        toPos = start
        startResolvedPos = doc.resolve(fromPos)
        endResolvedPos = doc.resolve(toPos)

        dispatch(state.tr.setSelection(new TextSelection(startResolvedPos, endResolvedPos)))
      }
    }
  }
  commitSelection (toPos, musicPillPos) {
    let fromPos = this.draggedNodePos.pos
    let view = this.view
    let state = view.state
    let dispatch = view.dispatch
    let transaction = view.state.tr
    const doc = view.state.doc
    let startResolvedPos = null
    let endResolvedPos = null
    let currentMusicNode = doc.nodeAt(fromPos)
    let {
      startPos, endPos
    } = findAllNodesWithCurrentMusic(currentMusicNode, view)

    removeMarkAtLocationInTransaction(startPos, endPos, view, transaction, 'musicNotAvailable')

    if (currentMusicNode.attrs.type === 'music') {
      if (toPos < fromPos) {
        let { start, end } = getSnappedSelectionForLocation(toPos, this.draggedNodePos.pos, view)
        toPos = start
        fromPos = end

        transaction.delete(this.draggedNodePos.pos, this.draggedNodePos.pos + currentMusicNode.nodeSize)
        transaction.insert(toPos, currentMusicNode)
        addNoteMarkAtLocationInTransaction(
          toPos + currentMusicNode.nodeSize,
          fromPos, view, transaction, currentMusicNode.attrs.uid
        )
      } else if (fromPos < toPos) {
        let { start, end } = getSnappedSelectionForLocation(toPos, toPos + doc.nodeAt(toPos).nodeSize, view)
        toPos = start
        transaction.insert(toPos, currentMusicNode)
        removeMarkAtLocationInTransaction(
          this.draggedNodePos.pos + currentMusicNode.nodeSize,
          toPos, view, transaction
        )
        transaction.delete(this.draggedNodePos.pos, this.draggedNodePos.pos + currentMusicNode.nodeSize)
      }
    } else if (currentMusicNode.attrs.type === 'endMusic') {
      if (fromPos < toPos) {
        let { start, end } = getSnappedSelectionForLocation(toPos, toPos + 1, view)
        toPos = end
        transaction.insert(toPos, currentMusicNode)
        addNoteMarkAtLocationInTransaction(fromPos,
          toPos - currentMusicNode.nodeSize,
          view, transaction, currentMusicNode.attrs.uid
        )
        transaction.delete(this.draggedNodePos.pos, this.draggedNodePos.pos + currentMusicNode.nodeSize)
      } else if (toPos < fromPos) {
        let { start, end } = getSnappedSelectionForLocation(toPos, toPos + doc.nodeAt(toPos).nodeSize, view)
        toPos = start
        transaction.delete(this.draggedNodePos.pos, this.draggedNodePos.pos + currentMusicNode.nodeSize)
        transaction.insert(toPos, currentMusicNode)
        removeMarkAtLocationInTransaction(
          toPos, this.draggedNodePos.pos + currentMusicNode.nodeSize,
          view, transaction
        )
      }
    }
    startResolvedPos = doc.resolve(toPos)
    endResolvedPos = doc.resolve(fromPos)
    // transaction.setSelection(new TextSelection(startResolvedPos, startResolvedPos))
    view.dispatch(transaction)

    let {
      nonMusicStartPos, nonMusicEndPos
    } = findNodesWithNoMusicReach(currentMusicNode, view)
    // console.log(nonMusicEndPos, nonMusicStartPos)
    if (nonMusicEndPos > -1 && nonMusicStartPos > -1) {
      addMarkAtLocation(nonMusicStartPos, nonMusicEndPos, view, 'musicNotAvailable', { class: 'musicNotAvailable' })
    }
  }
  updateMusicPillAttributes(blockmodeConfig) {
    try {
      let cursorPos = this.view.state.doc.resolve(blockmodeConfig.location[0])
      const transaction = this.view.state.tr
      updateMusicPillAttributes(blockmodeConfig, this.view, transaction)
      transaction.scrollIntoView().setSelection(
        new TextSelection(cursorPos, cursorPos))
      // this.view.dispatch(transaction)
      this.view.updateState(this.view.state.apply(transaction))
      if (this.audioControl && this.audioControl.isPlaying) {
        this.view.focus()
        this.audioControl.currentSelectionAnchor = blockmodeConfig.location[0]
        return this.audioControl.playFromHere()
      }
      // this.view.updateState(this.view.state.apply(transaction))
    } catch (err) {
      console.log(err)
    }
  }
  audioDataChanged (blockmodeConfig) {
    this.updateMusicPillAttributes(blockmodeConfig)
    pinMusicEndPill(blockmodeConfig.uid)
    pinMusicStartPill(blockmodeConfig.uid)
  }
  managePinnedPillVisuals() {
    let isBlockModeOpen = store.state.editor.panelToShow === 'blockmode'
    if (isBlockModeOpen) {
      if (document._pinnedMusicUid !== this.blockModeConfigObj.uid) {
        this.pillPinnedCleanUp(document._pinnedMusicUid)
      }
      pinMusicEndPill(this.blockModeConfigObj.uid)
      pinMusicStartPill(this.blockModeConfigObj.uid)
      highlightTextWithPinnedMusic(this.blockModeConfigObj.uid)
      document._pinnedMusicUid = this.blockModeConfigObj.uid
    }
    // if (!isBlockModeOpen) {
    //   this.pillPinnedCleanUp(document._pinnedMusicUid)
    // }
  }
  deleteMusic (blockmodeConfig) {
    let musicNode = this.view.state.doc.nodeAt(blockmodeConfig.location[0])
    let startPillNodeSize = musicNode.nodeSize
    musicNode = this.view.state.doc.nodeAt(blockmodeConfig.location[1])
    let endPillNodeSize = musicNode.nodeSize
    let transaction = this.view.state.tr
    transaction.removeMark(blockmodeConfig.location[0] + startPillNodeSize, blockmodeConfig.location[1], this.view.state.schema.marks['note'])
    transaction.delete(blockmodeConfig.location[0], blockmodeConfig.location[0] + startPillNodeSize)
    let newLocation = blockmodeConfig.location[1] - startPillNodeSize
    transaction.delete(newLocation, newLocation + endPillNodeSize)
    this.view.dispatch(transaction)
  }
  setAudioControl (audioControl) {
    this.audioControl = audioControl
  }
  startAudioPlayer (computeSchedule = false) {
    if (this.audioControl) {
      this.audioControl.startPlayer(computeSchedule)
    }
  }
  stopAudioPlayer () {
    if (this.audioControl) {
      this.audioControl.stopPlayer()
    }
  }
  getAudioPlayers () {
    if (this.audioControl) {
      return this.audioControl.players
    }
    return {}
  }
  getMusicPlayers () {
    if (this.audioControl) {
      return this.audioControl.musicAudioBufferList
    }
    return {}
  }
  getVolumeEnvelopeDataList () {
    if (this.audioControl) {
      return this.audioControl.volumeEnvelopeDataList
    }
    return []
  }
  forceRender () {
    /* force rerender  of whole document */
    /** we use this when we force render for example - user chnage the editor - transcript/voice */
    // computationally intesive so reduce its use
    let currentState = this.view.state
    this.view.updateState(this.editorState) // init state
    this.view.updateState(currentState) // current state
    let paragraphMeta = getParagraphMeta(this.view)
    this.paragraphMetaUpdate(paragraphMeta)
  }
  set isDeletedHidden (truth) {
    setShowDeleted(!truth)
    setShowTranscript(!truth)
    this.forceRender()
  }
  get isDeletedHidden () {
    return !showDeleted
  }

  set isReduceNoise (truth) {
    setAddNoise(truth)
    this.forceRender()
  }
  get isReduceNoise () {
    return addNoise
  }

  set IsDemo (payload) {
    setIsDemo(payload)
  }
  get IsDemo () {
    return isDemo
  }

  setContent (content = {}, next = () => null) {
    /**
     * with collab
     * content is coming from firebase
     * We already have schema defined
     * apply to create the state
     */
    let { doc: tDoc, k: latestKey = -1 } = content
    let doc = Node.fromJSON(this.editorState.schema, tDoc)
    this.latestKey = Number(latestKey)

    let state = EditorState.create({
      schema: this.editorState.schema,
      doc: doc,
      plugins: this.editorState.plugins
    })

    this.view.updateState(state)
    // let audioSourceSet = getMusicAndAudioSource(this.view)
    // this.audioSourceUpdate(Array.from(audioSourceSet))

    // changeref is firebase listen, when change come, we apply to state
    return this.changesRef.startAt(null, String(this.latestKey + 1)).once('value').then(
      // change coming from firebase is stored in snapshot
      function (snapshot) {
        // progress(2 / 2)
        // const view = this_.view = constructView({ stateConfig, updateCollab, selections })
        // const editor = view.editor || view

        const changes = snapshot.val() // get the data here
        if (changes) {
          const steps = []
          const stepClientIDs = []
          const placeholderClientId = '_oldClient' + Math.random()
          const keys = Object.keys(changes)
          this.latestKey = Math.max(...keys)
          for (let key of keys) {
            const compressedStepsJSON = changes[key].s
            steps.push(...compressedStepsJSON.map(this.compressedStepJSONToStep, this))
            stepClientIDs.push(...new Array(compressedStepsJSON.length).fill(placeholderClientId))
          }
          this.view.dispatch(receiveTransaction(this.view.state, steps, stepClientIDs)) // applying the change here
        }
        this.loadingState('false')

        if (this.view.state.doc.attrs.volume !== null) {
          this.updateVolume(Number(this.view.state.doc.attrs.volume)) // update the volume here
        }

        // Requirement: shows Label & plus button
        this.showBlinkingCursor()

        function updateClientSelection (snapshot) {
          const clientID = snapshot.key
          if (clientID !== this.clientID.split('-')[0]) {
            const compressedSelection = snapshot.val()
            if (compressedSelection) {
              try {
                this.selections[clientID] = Selection.fromJSON(this.view.state.doc, uncompressSelectionJSON(compressedSelection))
              } catch (error) {
                console.warn('updateClientSelection', error)
              }
            } else {
              delete this.selections[clientID]
            }
            this.view.dispatch(this.view.state.tr)
          }
        }
        this.selectionsRef.on('child_added', updateClientSelection.bind(this))
        this.selectionsRef.on('child_changed', updateClientSelection.bind(this))
        this.selectionsRef.on('child_removed', updateClientSelection.bind(this))

        this.changesRef.startAt(null, String(this.latestKey + 1)).on(
          'child_added',
          function (snapshot) {
            this.latestKey = Number(snapshot.key)
            const { s: compressedStepsJSON, c: clientID } = snapshot.val()
            const steps = (
              clientID === this.clientID
                ? this.selfChanges[this.latestKey]
                : compressedStepsJSON.map(this.compressedStepJSONToStep, this))
            const stepClientIDs = new Array(steps.length).fill(clientID)
            this.view.dispatch(receiveTransaction(this.view.state, steps, stepClientIDs)) // selection is received and applied on prosemirror doc
            delete this.selfChanges[this.latestKey]
            this.setDirty(Object.keys(this.selfChanges) > 0)
          }.bind(this))

        if (typeof next === 'function') next()
        return Object.assign({
          destroy: this.destroy.bind(this)
        }, this)
      }.bind(this)).catch(function (err) {
      this.loadingState('error')
      console.log('err', err)
    }.bind(this))
  }

  skipAndSelectNode (numberOfTextNodesToSkip) {
    skipAndSelectNode(numberOfTextNodesToSkip, this.view)
  }
  goToPara (paraIndex) {
    goToPara(paraIndex, this.view)
  }
  goToTime (time) {
    let blockList = this.computeArrangement()
    /** get the text block at a time */
    let blockAtTime = this.getTextBlockAtTime(time, blockList) // get the block at that time

    focusCursorOnLocation(blockAtTime.location - 1, this)
  }
  getLocationFromTime (time) {
    let blockList = this.computeArrangement()
    /** get the text block at a time */
    let blockAtTime = this.getTextBlockAtTime(time, blockList) // get the block at that time
    return blockAtTime.location
  }
  getNextNode(position) {
    try {
      return this.view.state.doc.resolve(position).nodeAfter
    } catch (e) {
      return {}
    }
  }
  getTextBlockAtTime (time, blockList) {
    /**
     * Traverse through the blocklist and pick block for the current time
     */
    let index = 0
    for (let i = 0; i < blockList.length; i++) {
      if (blockList[i].id !== 'music' && blockList[i].time > time) {
        index = i - 1
        break
      }
    }
    return blockList[index]
  }

  computeArrangement () {
    /**
     * traverse whole doc, find text and music elements
     * create blocks and store in blockList
     */
    let scrollTimeCounter = 0
    let sizeTravelled = 0 // track size travelled when navigating prosmirror doc.
    let audioLocationInGlobalTimeline = 0.0 // its a location in time units in overall timeline of document
    let audioSchedule = []
    let anchorLocation = 0 // start location of node
    this.view.state.doc.content.forEach(paraNode => {
      anchorLocation = sizeTravelled
      sizeTravelled += 2 // para jump costs size two, refer prosemirror doc
      paraNode.content.forEach(node => {
        anchorLocation = sizeTravelled
        sizeTravelled += node.nodeSize
        node.marks.every(mark => {
          if (mark.type.name === 'deleted') {
            return false
          }
          if (mark.attrs != null && mark.attrs.uid != null) {
            let duration = mark.attrs.aend - mark.attrs.astart
            // if (mark.attrs.uid.split('-')[0] === 'silence') { // user added silence has uid which containts text silence.
            //   duration = 0.1
            // }

            if (mark.attrs.uid.includes('music-')) { // music head's uid starts with 'music-'
              scrollTimeCounter++
              let tempsize = sizeTravelled
              let nodeAfter = this.getNextNode(tempsize)
              let audioduration = 0
              let tempEndDuration = 0
              let seenNoteMark = false
              while (true) { // to calculate total music duration applied in the document , either with waveforms or music behind text.
                if (nodeAfter == null || nodeAfter.marks == null) {
                  break
                }
                seenNoteMark = false
                tempEndDuration = 0
                nodeAfter.marks.every(mark => {
                  if (mark.type.name === 'deleted') { // deleted node
                    seenNoteMark = true
                    return false
                  }
                  if (mark.type.name === 'note') { // text with music  or waveform
                    seenNoteMark = true
                  } else if (mark.type.name === 'info') { // normal text
                    tempEndDuration = mark.attrs.aend - mark.attrs.astart
                  }
                  return true
                })
                if (!seenNoteMark) { // if note mark is ended, music is ended. break the while loop
                  break
                } else {
                  audioduration += tempEndDuration // build the total music duration applied in the doc
                }
                tempsize += nodeAfter.nodeSize
                nodeAfter = this.getNextNode(tempsize)
                if (nodeAfter == null) {
                  tempsize += 2
                  nodeAfter = this.getNextNode(tempsize)
                }
              } // end of while loop
              audioduration = Math.min(
                audioduration,
                mark.attrs.aend - mark.attrs.astart
              ) // trim the music to the actual music duration if calculated music duration is more.
              if (sizeTravelled === tempsize) { // corner case, should not occur.
                audioduration = mark.attrs.aend - mark.attrs.astart
              }
              let index = mark.attrs.uid.split('-')[1] // uid is in the form 'sometext-uid', get the uid part out
              audioSchedule.push({
                time: audioLocationInGlobalTimeline,
                id: 'music',
                node: node,
                starttime: mark.attrs.astart,
                endtime: mark.attrs.aend,
                duration: audioduration,
                location: anchorLocation,
                markStartLocation: sizeTravelled,
                markEndLocation: tempsize,
                index: audioSchedule.length,
                actualAudioDuration: mark.attrs.aend, // - mark.attrs.astart,
                musicIndex: index
              })
              if (sizeTravelled === tempsize) {
                audioLocationInGlobalTimeline =
                  audioLocationInGlobalTimeline + duration
              }
            } else if (!mark.attrs.uid.includes('endmus-')) { // for non music nodes
              audioSchedule.push({
                time: audioLocationInGlobalTimeline,
                id: mark.attrs.uid,
                node: node,
                duration: duration,
                location: anchorLocation,
                index: audioSchedule.length
              })
              audioLocationInGlobalTimeline =
                audioLocationInGlobalTimeline + duration
            }
          }
          return true
        })
      })
    })
    return audioSchedule
  }

  // Pass document that is fetched from database
  insertDocAtPos (content, ownerId, podId) {
    let vm = this
    let { doc: tDoc, k: latestKey = -1 } = content
    let ndoc = Node.fromJSON(schema, tDoc)
    let state = EditorState.create({
      schema: this.editorState.schema,
      doc: ndoc,
      plugins: this.editorState.plugins
    })
    this.firebase.database().ref(ownerId + '/podcast/' + podId + '/realtime/').child('changes').startAt(null, String(latestKey + 1)).once('value').then(
      // change coming from firebase is stored in snapshot
      function (snapshot) {
        // progress(2 / 2)
        // const view = this_.view = constructView({ stateConfig, updateCollab, selections })
        // const editor = view.editor || view

        const changes = snapshot.val() // get the data here
        if (changes) {
          try {
            const steps = []
            const stepClientIDs = []
            const placeholderClientId = '_oldClient' + Math.random()
            const keys = Object.keys(changes)
            latestKey = Math.max(...keys)
            for (let key of keys) {
              const compressedStepsJSON = changes[key].s
              steps.push(...compressedStepsJSON.map(vm.compressedStepJSONToStep, vm))
              stepClientIDs.push(...new Array(compressedStepsJSON.length).fill(placeholderClientId))
            }
            state = state.apply(receiveTransaction(state, steps, stepClientIDs)) // applying the change here
          } catch (e) {
            console.log(e)
          }
        }
        let transaction = vm.migrateTransaction(state)
        if (transaction) {
          state = state.apply(transaction)
        }
        // console.log('asdsadasdasd', ndoc, state.doc)
        ndoc = state.doc
        let time = calculateTime(ndoc, 1)
        moveCursorToBoundary(vm.view) // the cursor would be at the end of the word and then add new doc
        let { from } = vm.view.state.selection
        let insertPos = from
        vm.view.dispatch(
          vm.view.state.tr.insert(
            insertPos,
            ndoc
          ).setMeta('pastedTime', time) // "x" sec of doc is pasted notification flow from here
        )
        vm.view.focus()
      })
    // console.log('ndoc', ndoc)
  }

  //
  addNewParagraph () {
    splitBlockAfterWord(this.view.state, this.view.dispatch, this.view)
  }

  // delete audio
  // chainCommand is a prosemirror fun
  deleteAudio () {
    return chainCommands(remove, removeThisWord, removePrevWord, joinBackward)(this.view.state, this.view.dispatch, this.view)
  }

  // restore audio
  restoreAudio () {
    return restore(this.view.state, this.view.dispatch, this.view)
  }

  proseEditorView (elementToMountOn, mode) {
    if (this.preventMountFlag) {
      elementToMountOn = null
    }
    /**
     * This is a proxy function for prose editor view.
     * apply plugins for a mode
     * when you develop a new mode, you can list plugins here
     */
    // console.log('mode is', mode)

    if (mode === 'editMedia') {
      let voiceEditorPlugins = [
        this.clickPlugin,
        history(),
        handleText(!this.isUsedInProject),
        handleCursor(),
        handleSelection(),
        handleCut(this.isUsedInProject),
        handleCopy(),
        handlePaste(this.isUsedInProject),
        // dropCursor(),
        handleDrop(this.musicAudioBufferList),
        keymap(this.isUsedInProject ? projectEditMediaKeymap : editMediaKeymap),
        // sideToolbar,
        // floatingMenu,
        colorFillerWords(),
        collab({ clientID: this.clientID }),
        plusToolbar
        // noterPlugin
      ]
      if (!store.state.app.isMobile && !this.isDemo) {
        voiceEditorPlugins.push(musicHoverToolbar)
        voiceEditorPlugins.push(selectionToolbar)
      }
      // update the state after describing the plugins
      this.editorState = EditorState.create({
        doc: emptyDocument,
        plugins: voiceEditorPlugins
      })
    } else if (mode === 'editTranscript') {
      this.editorState = EditorState.create({
        doc: emptyDocument,
        plugins: [
          this.clickPlugin,
          handleSelection(),
          transformPasted(),
          handleText(),
          handleCut(),
          history(),
          keymap(editTranscriptKeymap),
          selectionToolbarTranscript,
          colorFillerWords(),
          collab({ clientID: this.clientID })
        ]
      })
    }

    this.view = new EditorView(elementToMountOn, {
      /**
       * Here we get the prosemirror view
       * we intercept the transactions and pass them to vue
       * these functions will be callback to register functions in NewProseEditor.vue
       */
      state: this.editorState,
      dispatchTransaction: transaction => {
        // console.log('dispatchTransaction')
        // let newState = this.view.state.apply(transaction) // apply transaction here
        // this.view.updateState(newState)
        // this.updateCollab(transaction, newState)
        // inform vue for following ones

        // Checks if clipper is active,
        // if so, only allows clipper interactions;
        // if other action tried, opens edit warning modal
        if (this.clipperActive) {
          const allow = transaction.meta.pointer ||
            Object.keys(transaction.meta).length === 0 ||
            transaction.meta.collab$ ||
            transaction.getMeta('clipper') ||
            transaction.getMeta('addToHistory') === false
          const disallow = transaction.getMeta('edited') || transaction.getMeta('docEdited') || transaction.getMeta('correction')
          if (!allow || disallow) {
            // This is important because we in case there is a clip selected, then it
            // first needs to be removed from the DOM.
            setTimeout(() => store.dispatch('clipper/openExportModal', true), 100)
            return
          }
        }

        // If clipper is inactive, and someone uses undo, this will
        // prevent clip hover or clip pills to reappear.
        if (transaction.meta.history$) {
          const disallowClips = transaction.steps.length &&
            transaction.steps[0]?.slice?.content?.content[0]?.type?.name === 'clip'
          const disallowHover = transaction.steps.length &&
            transaction.steps[0]?.mark?.type?.name === 'clipHoverHighlight'
          if (disallowClips || disallowHover) return
        }

        if (transaction.getMeta('pastedTime')) {
          this.pasteNotifyCallback(transaction.getMeta('pastedTime'))
        } else if (transaction.getMeta('cut')) {
          this.cutNotifyCallback()
        } else if (transaction.getMeta('remove')) {
          this.removeNotifyCallback(transaction.getMeta('remove'))
          reComputeMusicReach(this.view)
        } else if (transaction.getMeta('copy')) {
          this.copyNotifyCallback()
        } else if (transaction.getMeta('edited')) {
          this.editNotifyCallback()
        } else if (transaction.getMeta('addTextAttempt')) {
          this.textAddAttemptCallback()
        }
        if (this.audioControl && !this.audioControl.isPlaying) {
          // this.timeNotifyCallback(computeTotalDuration(this.view))
          // Cost: 25 // fps dropping to 5 from 30
          // this.cursorTimeNotifyCallback(computeCurrentCursorTime(this.view))
          // Cost: 8 //fps dropping to 22 from 30
          // this.currentParaNotifyCallback(calculateParaFromLocation(this.view))
          // let audioSourceSet = getMusicAndAudioSource(this.view)
          // this.audioSourceUpdate(Array.from(audioSourceSet))

          this.debouncedComputeOnDispatchTransaction()
          // cleanDocOfOrphanMusicNotes(this.view)
        }
        if (transaction.docChanged || transaction.getMeta('docEdited')) {
          reRenderBlockModeIfOpened(this.view, transaction)
          // this.debouncedEmitUpdate()
        }
        let newState = this.view.state.apply(transaction) // apply transaction here
        this.view.updateState(newState)
        if (!window.projects && !this.clipperActive && (!window._spext_exportPlayer || window._spext_exportPlayer_isAutoPilot)) this.updateCollab(transaction, newState)
        if (!store.state.app.isMobile) {
        }
      },
      decorations: ({ doc }) => {
        /**
         * prose mirror as control of html
         * we are showing user selections based on client id
         */
        let vm = this
        let selectionDecorations = Object.entries(this.selections).map(
          function ([ clientID, { from, to } ]) {
            if (from === to) {
              let elem = document.createElement('span') // take control of html
              elem.id = `cu-${clientID}`
              elem.style.borderLeft = `2px solid ${uniqueUidColor(clientID)}`
              elem.style.position = 'relative'
              let child = document.createElement('span')
              child.style.position = 'absolute'
              child.style.borderLeft = `7px solid transparent`
              child.style.borderRight = `7px solid transparent`
              child.style.borderBottom = `7px solid ${uniqueUidColor(clientID)}`
              child.style.top = '20px'
              child.style.left = '-8px'
              // contacts we have on prosemirror object
              // get name of user from there
              child.setAttribute('guide-title', vm.contacts[clientID] ? vm.contacts[clientID].displayName : 'Guest')
              child.style.setProperty('--guide-left', '50%')
              child.style.setProperty('--guide-top', '10px')
              child.style.setProperty('--guide-color', uniqueUidColor(clientID))
              child.style.setProperty('--guide-padding', '0px 10px')
              child.style.setProperty('--guide-fsize', '14px')
              child.style.setProperty('--guide-fweight', 'bold')
              child.style.setProperty('--guide-transform', 'translate(-50%, 0%)')
              elem.appendChild(child)
              // when user has not made any selection, just clicked somewhere then call this
              return Decoration.widget(from, elem) // Decoration is prosemirror fun
            } else {
              // when something is selected
              return Decoration.inline(from, to, {
                style: `background-color: ${uniqueUidColor(clientID, 0.2)};`
              })
            }
          }, this
        )
        if (this.comment.from) {
          selectionDecorations.push(Decoration.inline(this.comment.from, this.comment.to, {
            style: `background-color: rgba(255, 233, 168,1);`
          }))
        }
        if (document._customTextSelection) {
          selectionDecorations.push(Decoration.inline(document._customTextSelection.from, document._customTextSelection.to, {
            class: 'selectedClip'
          }))
        }
        if (document._playingSelection) {
          selectionDecorations.push(Decoration.inline(document._playingSelection.from, document._playingSelection.to, {
            class: 'playbackSelection'
          }))
        }

        if (document._correctTranscriptSelection) {
          selectionDecorations.push(Decoration.inline(document._correctTranscriptSelection.from, document._correctTranscriptSelection.to, {
            class: 'correctTranscriptSelection'
          }))
        }
        // if (document._searchSelection && document._searchSelection.length > 0) {
        //   document._searchSelection.forEach(selection => {
        //     selectionDecorations.push(Decoration.inline(selection.from, selection.to, {
        //       class: 'searchHighlight'
        //     }))
        //   })
        // }
        return DecorationSet.create(doc, selectionDecorations)
      }
    })
    // applyDevTools(this.view)
  }
  clip(start, end, title, location) {
    this.clipNotifyCallback(start, end, title, location)
    this.view.dispatch(
      this.view.state.tr.setSelection(new TextSelection(this.view.state.doc.resolve(location.start), this.view.state.doc.resolve(location.start)))
    )
  }
  /***
   * Mention all callback functions for NewProseEditor.vue
   */
  registerNotifyClipped (callback) {
    this.clipNotifyCallback = callback
  }
  registerNotifyCopied (callback) {
    this.copyNotifyCallback = callback
  }
  registerNotifyCut (callback) {
    this.cutNotifyCallback = callback
  }
  registerNotifyEdit (callback) {
    this.editNotifyCallback = callback
  }
  registerNotifyRemove (callback) {
    this.removeNotifyCallback = callback
  }
  registerNotifyPaste (callback) {
    this.pasteNotifyCallback = callback
  }
  registerTextAddAttempt (callback) {
    this.textAddAttemptCallback = callback
  }
  registerNotifyTotalTime (callback) {
    this.timeNotifyCallback = callback
  }
  registerNotifyTimeAtCursor (callback) {
    this.cursorTimeNotifyCallback = callback
  }
  registerEmitUpdate (callback) {
    this.emitUpdate = callback
    this.debouncedEmitUpdate = debounce(this.emitUpdateOnDispatch, 500)
  }
  registerAudioSourceUpdate (callback) {
    this.audioSourceUpdate = callback
  }
  // triggers on view update
  registerNotifycurrentPara (callback) {
    this.currentParaNotifyCallback = callback
  }

  registerCommentThreadsAndPositionsUpdate (callback) {
    this.commentThreadsAndPositionsUpdate = callback
  }

  registerDirty (callback) {
    this.setDirty = callback
  }

  updateCommentPos () {
    let { from, to } = this.view.state.selection
    this.comment.from = from
    this.comment.to = to
    this.view.dispatch(this.view.state.tr)
  }
  resetCommentPos () {
    if (this.comment.from) {
      this.comment = {}
      this.view.dispatch(this.view.state.tr)
    }
  }

  switchToTranscriptMode () {
    /***
     *  when user switch the mode from ui
     *  we reconf the editor and tell plugins to load
     */
    // console.log('switchToTranscriptMode')
    this.forceRender()
    const newState = this.view.state.reconfigure({
      plugins: [
        this.clickPlugin,
        transformPasted(),
        handleSelection(),
        handleText(),
        handleCut(),
        history(),
        keymap(editTranscriptKeymap),
        selectionToolbarTranscript,
        colorFillerWords(),
        collab({ clientID: this.clientID })
      ]
    })
    this.view.updateState(newState)
    this.showBlinkingCursor()
    // console.log('switched')
  }

  showBlinkingCursor () {
    let vm = this
    setTimeout(function () {
      // console.log('focusing')
      // show blinking cursor
      vm.view.focus()
      // show plusToolbar at right position (1st)
      selectPosition(vm.view, 2, 2)
      selectPosition(vm.view, 1, 1)
    }, 10)
  }

  switchToVoiceMode () {
    /***
     *  when user switch the mode from ui
     *  we reconf the editor and tell plugins to load
     */
    // console.log('switchToVoiceMode')
    this.forceRender()
    let voiceEditorPlugins = [
      this.clickPlugin,
      history(),
      handleText(),
      handleCursor(),
      handleSelection(),
      handleCut(),
      handleCopy(),
      handlePaste(),
      // dropCursor(),
      handleDrop(this.musicAudioBufferList),
      keymap(editMediaKeymap),
      // sideToolbar,
      // floatingMenu,
      colorFillerWords(),
      collab({ clientID: this.clientID }),
      plusToolbar
      // noterPlugin
    ]
    if (!store.state.app.isMobile) {
      voiceEditorPlugins.push(selectionToolbar)
      voiceEditorPlugins.push(musicHoverToolbar)
    }
    const newState = this.view.state.reconfigure({
      plugins: voiceEditorPlugins
    })
    this.view.updateState(newState)
    this.showBlinkingCursor()
  }

  // triggers on view update
  registerParagraphMetaUpdate (callback) {
    this.paragraphMetaUpdate = callback
  }

  setParagraphMeta (pos, meta) {
    setParagraphMeta(this.view, pos, meta)
  }

  addCommentMark (id) {
    addCommentMark(this.view, id)
  }

  removeCommentMark (id) {
    removeCommentMark(this.view, id)
  }

  addAttributeToDocument (key, val) {
    const transaction = this.view.state.tr
      .step(new SetDocAttr(key, val))
    this.view.dispatch(transaction)
  }

  registerMasterVolume (callback) {
    this.updateVolume = callback
  }

  registerComputeOnDispatchTransaction () {
    // console.time('computeOnDispatch')
    let res = computeOnDispatchTransaction(this.view)
    this.timeNotifyCallback(res[0])
    this.cursorTimeNotifyCallback(res[1])
    store.commit('doc/setElapsedTime', res[1])
    this.currentParaNotifyCallback(res[2])
    this.audioSourceUpdate(Array.from(res[3]), Array.from(res[5]))
    if (res[6]) {
      // store.commit('doc/setIsOverMusic', true)
    }
    this.commentThreadsAndPositionsUpdate(res[4])
    this.audioControl.computeScheduleFromWorker(this.view.state)
    let paragraphMeta = getParagraphMeta(this.view)
    this.paragraphMetaUpdate(paragraphMeta)
    // console.timeEnd('computeOnDispatch')
    // cleanDocOfOrphanMusicNotes(this.view)
  }

  emitUpdateOnDispatch () {
    // console.log('dispatchTransaction: emitUpdate')
    // console.time('emitUpdate')
    this.emitUpdate(this.view.state.doc.toJSON())
    // console.timeEnd('emitUpdate')
  }

  // triggers on view update
  registerShowBlockMode (callback) {
    this.showBlockMode = callback
  }

  registerLoadingState (callback) {
    this.loadingState = callback
  }

  updateContacts (contacts) {
    this.contacts = contacts
  }

  updateCollab ({ docChanged, mapping }, newState) {
    /**
     * write collab doc to firebase when there is any change
     */
    if (docChanged) {
      for (let clientID in this.selections) {
        this.selections[clientID] = this.selections[clientID].map(newState.doc, mapping)
      }
      let deletedTimeInMilliSeconds = computeDeletedTimeInDoc(this.view.state.doc.toJSON())
      store.commit('editor/setTimeDeleted', deletedTimeInMilliSeconds * 1000)
    }

    const sendable = sendableSteps(newState)
    if (sendable) {
      const { steps, clientID } = sendable

      // save changes & checkpoint
      this.changesRef.child(this.latestKey + 1).transaction(
        function (existingBatchedSteps) {
          if (!existingBatchedSteps) {
            this.selfChanges[this.latestKey + 1] = steps
            this.setDirty(Object.keys(this.selfChanges) > 0)
            return {
              s: compressStepsLossy(steps).map( // third party lib help in compressing the steps
                function (step) {
                  return compressStepJSON(step.toJSON())
                }),
              c: clientID,
              t: TIMESTAMP
            }
          }
        }.bind(this),
        function (error, committed, snapshot) {
          // console.log('error, committed, { key }', error, committed, key)
          if (error) {
            console.warn('updateCollab', error, sendable)
          } else if (committed) {
            let { key } = snapshot
            if (key % 20 === 0) { // after 20 change we create a checkpoint
              key = Number(key)
              console.log('committed', key)
              const { doc } = newState.toJSON()
              // console.log('doc', doc)
              // check registerEmitUpdate for callback definition
              this.emitUpdate({ doc, k: key, t: TIMESTAMP }) // emit here for checkpoint
            }
          }
        }.bind(this),
        false)
    }

    // save selection changes here
    const selectionChanged = !newState.selection.eq(this.selection)
    if (selectionChanged) {
      this.selection = newState.selection
      this.selfSelectionRef.set(compressSelectionJSON(this.selection.toJSON())).catch(function (err) {
        console.log('unable to write', err.code)
      })
    }
  }

  compressedStepJSONToStep (compressedStepJSON) {
    const uncompressedStepJSON = uncompressStepJSON(compressedStepJSON)
    if (uncompressedStepJSON['stepType'] === 'SetDocAttr') {
      return SetDocAttr.fromJSON(uncompressedStepJSON)
    }
    return Step.fromJSON(this.editorState.schema, uncompressedStepJSON)
  }

  destroy () {
    this.changesRef.off() // switch off listeners
    this.selectionsRef.off()
    this.selfSelectionRef.remove()
    this.view.destroy()
  }
  migrateDoc () {
    let transaction = null
    transaction = this.migrateTransaction(this.view.state)
    if (transaction) {
      this.view.dispatch(
        transaction
      )
    }
  }
  migrateTransaction(state) {
    let transaction = null
    console.log('doc version _pr', state.doc.attrs.docVersion)
    let currentDocVersion = state.doc.attrs.docVersion ? state.doc.attrs.docVersion : 1
    if (currentDocVersion === 1) {
      transaction = this.migrateToDocVersion2(state)
    }
    return transaction
  }
  migrateToDocVersion2(state) {
    let musicID = null
    let musicName = ''
    let fadeIn = {}
    let fadeOut = {}
    let astart = 0
    let aend = 0
    let asource = ''
    let sizeTravelled = 0
    let anchorLocation = 0
    let noteStartLoc = -1
    let musicNode = null
    let musicNodeLocation = -1
    let noteEndLoc = -1
    let isWaveNote = false
    let waveStartLocation = -1
    let waveEndLocation = -1
    let silenceNode = null
    let silenceStartLocation = -1
    let silenceAstart = 0
    let silenceAend = 0
    let silenceAsource = ''
    let silenceEndLocation = -1

    let transaction = state.tr
    state.doc.content.forEach((para) => {
      anchorLocation = sizeTravelled
      sizeTravelled += 2

      para.content.forEach((text) => {
        anchorLocation = sizeTravelled
        sizeTravelled += text.nodeSize
        let token = false
        if (text.type.name === 'silence' || text.text === '_ ') {
          // console.log(text.text, 'asjkdhkjashdsa', text.type.name)

          silenceNode = text
          silenceStartLocation = anchorLocation - 1
          silenceEndLocation = silenceStartLocation + text.nodeSize
        }
        text.marks.forEach(mark => {
          if (mark.attrs != null && mark.attrs.uid != null && mark.attrs.uid.includes('music-')) {
            token = true
            musicName = text.content.content[0].text
            fadeIn = text.attrs.fadeIn
            fadeOut = text.attrs.fadeOut
            musicID = mark.attrs.uid.split('music-')[1]
            musicNodeLocation = anchorLocation - 1
            astart = mark.attrs.astart
            aend = mark.attrs.aend
            asource = mark.attrs.asource
            noteStartLoc = -1
            noteEndLoc = -1
            musicNode = text
            // console.log('asjkdhkjashdsa', musicName, fadeIn, fadeOut, musicID, text.attrs, musicNodeLocation)

            let tempsize = sizeTravelled
            let nodeAfter = this.getNextNode(tempsize)
            let audioduration = 0
            let tempEndDuration = 0
            let seenNoteMark = false
            while (true) { // to calculate total music duration applied in the document , either with waveforms or music behind text.
              if (nodeAfter == null || nodeAfter.marks == null) {
                break
              }
              seenNoteMark = false
              tempEndDuration = 0
              nodeAfter.marks.every(mark => {
                if (mark.type.name === 'deleted') { // deleted node
                  seenNoteMark = true
                  return false
                }
                if (mark.type.name === 'note') { // text with music  or waveform
                  seenNoteMark = true
                } else if (mark.type.name === 'info') { // normal text
                  tempEndDuration = mark.attrs.aend - mark.attrs.astart
                }
                return true
              })
              if (!seenNoteMark) { // if note mark is ended, music is ended. break the while loop
                break
              } else {
                audioduration += tempEndDuration // build the total music duration applied in the doc
              }
              tempsize += nodeAfter.nodeSize
              nodeAfter = this.getNextNode(tempsize)
              if (nodeAfter == null) {
                tempsize += 2
                nodeAfter = this.getNextNode(tempsize)
              }
            } // end of while loop
            audioduration = Math.min(
              audioduration,
              mark.attrs.aend - mark.attrs.astart
            ) // trim the music to the actual music duration if calculated music duration is more.
            if (sizeTravelled === tempsize) { // corner case, should not occur.
              audioduration = mark.attrs.aend - mark.attrs.astart
            }
            fadeIn.duration = audioduration * fadeIn.duration / 100
            fadeOut.duration = audioduration * fadeOut.duration / 100
          }
          if (mark.type.name === 'note') {
            if (text.text.length === 1 && (text.text.charCodeAt() >= 512 || text.text.charCodeAt() <= 639)) {
              // console.log(text.text, text.text.charCodeAt(), 'asjkdhkjashdsa')
              isWaveNote = true
              waveStartLocation = anchorLocation - 1
              waveEndLocation = waveStartLocation + text.nodeSize
            }
            token = true
            if (musicID) {
              if (noteStartLoc === -1) {
                noteStartLoc = anchorLocation
              }
            }
            noteEndLoc = anchorLocation + text.nodeSize
          }
          if (silenceNode && mark.type.name === 'info') {
            silenceAstart = mark.attrs.astart
            silenceAend = mark.attrs.aend
            silenceAsource = mark.attrs.asource
          }
        })
        if (silenceNode) {
          let newSilenceNode = this.view.state.schema.text(silenceSymbol, [
            this.view.state.schema.mark('silence', {
              uid: 'silence-' + uuidv1(),
              asource: silenceAsource,
              astart: silenceAstart,
              aend: silenceAend
            }),
            this.view.state.schema.mark('info', {
              uid: 'silence-' + uuidv1(),
              asource: silenceAsource,
              astart: silenceAstart,
              aend: silenceAend
            })
          ])
          // transaction.replaceWith(
          //   silenceStartLocation, silenceEndLocation, newSilenceNode
          // )
          // sizeTravelled = sizeTravelled - (silenceNode.nodeSize - newSilenceNode.nodeSize)
          silenceNode = null
        }
        if (isWaveNote) {
          let newSilenceNode = this.view.state.schema.text(silenceSymbol, [
            this.view.state.schema.mark('silence', {
              uid: 'silence-' + uuidv1(),
              astart: 0,
              aend: 0.1
            }),
            this.view.state.schema.mark('info', {
              uid: 'silence-' + uuidv1(),
              astart: 0,
              aend: 0.1
            })
          ])
          transaction.replaceWith(
            waveStartLocation, waveEndLocation, newSilenceNode
          )
          isWaveNote = false
        }
        if (!token && musicID) {
          // console.log('asjkdhkjashdsa', musicID, musicName, fadeIn, fadeOut)
          if (noteStartLoc > -1 && noteEndLoc > -1) {
            let noteMark = this.view.state.schema.mark('note', {
              class: 'note',
              note_uid: musicID
            })
            let audioEndNode = this.view.state.schema.node(
              'music',
              {
                title: musicName,
                type: 'endMusic',
                uid: musicID,
                fadeIn: fadeIn,
                fadeOut: fadeOut
              },
              null,
              [
                this.view.state.schema.mark('info', {
                  uid: 'endmus-' + musicID,
                  asource: asource,
                  astart: astart,
                  aend: aend,
                  class: 'localaudioend'
                })
              ]
            )
            if (musicNodeLocation !== -1) {
              // console.log('asjkdhkjashdsa', musicID, musicName, fadeIn, fadeOut, astart, aend, musicNode.content.content[0].text)
              transaction.setNodeMarkup(
                musicNodeLocation, // For custom node views, this function is passed into the constructor.  It'll return the position of the node in the document.
                undefined, // No node type change
                {
                  type: 'music',
                  title: musicName,
                  uid: musicID,
                  fadeIn: fadeIn,
                  fadeOut: fadeOut
                },
                [
                  this.view.state.schema.mark('info', {
                    uid: 'music-' + musicID,
                    asource: asource,
                    astart: astart,
                    aend: aend,
                    class: 'localaudio'
                  })
                ]
              )
              musicNodeLocation = -1
            }

            transaction.setMeta('addToHistory', false).removeMark(noteStartLoc - 1, noteEndLoc - 1, this.view.state.schema.marks['note'])
              .addMark(noteStartLoc - 1, noteEndLoc - 1, noteMark)
            transaction.insert(noteEndLoc - 1, audioEndNode)
            sizeTravelled = sizeTravelled + audioEndNode.nodeSize
            noteStartLoc = -1
            noteEndLoc = -1
            musicID = null
          }
        }
      })
    })
    try {
      transaction.step(new SetDocAttr('docVersion', 2))
      return transaction
    } catch (err) {
      console.log(err)
    }
  }
}
