<template>
  <div class="relative w-full h-full overflow-hidden">
    <TextBlock
      v-for="text in texts"
      :id="text.id"
      :key="text.id"
      :element="text"
    />

    <ImageBlock
      v-for="image in images"
      v-show="!image.hidden"
      :id="image.id"
      :key="image.id"
      :element="image"
    />
    <span v-if="playerType.includes('video')">
      <VideoBlock
      v-show="!video.hidden"
      v-for="video in videos"
      :id="video.id"
      :key="video.id"
      :element="video"
      :isPlaying="isPlaying && !video.hidden"
      :debug="debug"
      />
    </span>
    <CSSBlock
      v-for="node in nodes"
      v-show="!node.hidden"
      :id="node.id"
      :key="node.id"
      :element="node"
      :debug="debug"
    />

    <AudioWave
      v-for="wave in waves"
      v-show="!wave.hidden"
      :id="wave.id"
      :key="wave.id"
      :element="wave"
      :isPlaying="isPlaying"
      :debug="debug"
      :scale="scale"
      :currentTime="currentTime"
      :totalTime="totalTime"
      :progress="progress"
    />

    <Heading
      v-for="heading in headings"
      v-show="!heading.hidden"
      :id="heading.id"
      :key="heading.id"
      :element="heading"
      :debug="debug"
      :scale="scale"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TextBlock from './TextBlock.vue'
import ImageBlock from './ImageBlock.vue'
import VideoBlock from './VideoBlock.vue'
import CSSBlock from './CSSBlock.vue'
import AudioWave from './AudioWave.vue'
import Heading from './Heading.vue'

export default {
  name: 'ClipPreview',
  components: {
    TextBlock,
    ImageBlock,
    VideoBlock,
    CSSBlock,
    AudioWave,
    Heading
  },
  props: {
    debug: Boolean,
    isPlaying: Boolean,
    totalTime: Number,
    currentTime: Number,
    scale: {
      type: Number,
      default: 1
    },
    progress: {
      type: Number,
      default: 0
    },
    playerType: {
      type: String,
      default: 'video'
    },
    log: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      schedule: null
    }
  },
  computed: {
    ...mapGetters({
      texts: 'video/texts',
      images: 'video/images',
      videos: 'video/videos',
      nodes: 'video/nodes',
      waves: 'video/waves',
      headings: 'video/headings'
    })
  }
}
</script>

<style scoped lang="css">
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

</style>
