/**
 * All audio related functions here
 */
import axios from 'axios'
import Tone from 'tone'
import { selectNode, loadAudioWithIndex, replaceAudio, loadImageWithIndex } from './util/utility'
import CONSTANTS from '../../../constants/CONSTANTS'
import { toWav } from './util/audioToWav'
import store from '@/services/store'
import { updatePlayerFade, setVolumeEnvelopeDataList, setBlockList } from './musicBlockInteraction'
import videoPlayer from '@/components/VideoPlayer/api'
// eslint-disable-next-line import/no-webpack-loader-syntax
import worker from 'workerize-loader!./workers/audioSchedule'
import { getInfoMarkFromTextNode } from '@/view/voiceEditor/proseEditor/Nodes/textNode'

export default class {
  constructor (isAutoScroll) {
    this.audioScheduleWorker = worker()
    /* audio Speed */
    this.playbackRateAndPitchShift = {
      0.5: {
        shift: 12
      },
      0.8: {
        shift: 4
      },
      1: {
        shift: 0
      },
      1.2: {
        shift: -3
      },
      1.5: {
        shift: -7
      }
    }
    this.isAutoScroll = isAutoScroll
    this.musicPlayerGlobalVolume = 0
    this.isPlaying = false

    // holds where to play audio from
    this.currentSelectionAnchor = null

    this.volumeEnvelopeDataList = []

    /* This will hold schedule for Tone-js to play audio */
    this.audioSchedule = []

    /* This will hold schedule for highlighting word being played by Tone-js */
    this.textSchedule = []

    this.timeFromWhereAudioStartedPlaying = 0
    this.totalTime = 0 // Total time of the audio in text editor.
    /* selected index of speed configuration from playbackRateAndPitchShift array */
    this.selectedSpeed = 1

    /* Tone.Part is a collection Tone.Events which can be started/stopped and looped as a single unit. */

    /* to schedule audio */
    this.audioPart = null

    /* to schedule text highlight */
    this.textPart = null

    this.currentTime = 0

    /** shows status of buffer */
    this.playerLoadProgress = 0

    this.players = null
    this.playersName = []
    this.mediaMissing = 0
    this.diffValue = 0
    /** these variables are for demo */
    this.isDemo = false
    this.checkPoint1 = false
    this.checkPoint2 = false
    this.checkPoint3 = false
    this.playingComplete = false
    this.videoUrlList = {}
    this.imageUrlList = {}
    this.timerInterval = null
  }
  initProseEditorView (proseEditorView) {
    // console.log('updated view', proseEditorView)
    this.view = proseEditorView
  }

  updateMode (mode) {
    this.mode = mode
  }

  initMusicAudioBufferList (musicAudioBufferList) {
    this.musicAudioBufferList = musicAudioBufferList
  }
  loadMusic (volume) {
    /**
     * add  audio files and update volume
     */
    let vm = this
    for (let [key, value] of Object.entries(this.musicAudioBufferList)) {
      try {
        vm.players.get(key)
      } catch (e) {
        vm.players.add(key, value.buffer, (player) => {
          console.log('Music Buffer Loaded - ' + player.buffer.duration)
          player.volume.value = volume
          this.setSpeed(this.selectedSpeed, player)
        })
      }
    }
  }
  // not used in prod
  addLocalAudioToPlayerList (audioSourceId, audioBuffer) {
    this.players.add(audioSourceId, audioBuffer)
  }
  async initImageUrls (imageUrlList) {
    if (Object.keys(imageUrlList).length > 0) {
      console.log('image sources resolve start')
      this.imageUrlList = imageUrlList
      const sourceURLs = []
      Object.keys(imageUrlList).forEach(key => sourceURLs.push({
        id: key,
        url: imageUrlList[key]
      }))
      // console.log('sourceurls', sourceURLs)
      sourceURLs.forEach(source => videoPlayer.updateImage({
        id: source.id,
        src: source.url,
        hidden: true
      }))
    }
    store.commit('editor/setDocProgress', 10)
  }
  async initVideoUrls (videoUrlList) {
    store.commit('video/setSourcesLoading', true)

    if (Object.keys(videoUrlList).length > 0) {
      const cachedSources = store.state.video.sources
      console.log('video sources resolve start', videoUrlList)
      this.videoUrlList = videoUrlList
      const sourceURLs = []
      Object.keys(videoUrlList).forEach(key => sourceURLs.push({
        id: key,
        url: videoUrlList[key]
      }))
      const toLoadUrls = sourceURLs.reverse()
      const sources = {}
      const sourcesReadyMap = {}
      store.commit('editor/setDocProgress', 20)
      // TODO: cache key to asource and value to video value
      const downloadWeight = 72 / toLoadUrls.length
      const results = await Promise.all(toLoadUrls.map(({ url }) => new Promise(async (resolve, reject) => {
        let percentCompleted = 0
        const { data: file } = await axios.get(url, {
          responseType: 'blob',
          onDownloadProgress: progressEvent => {
            const newPercentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
            const percentDiff = newPercentCompleted - percentCompleted
            store.commit('editor/setDocProgress', downloadWeight * percentDiff / 100)
            percentCompleted = newPercentCompleted
          }
        })

        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })))
      results.forEach((res, i) => {
        sources[toLoadUrls[i].url] = res
        sourcesReadyMap[toLoadUrls[i].id] = res
      })

      store.commit('video/cacheSources', sources)
      store.commit('video/setSourcesReadyMap', sourcesReadyMap)

      sourceURLs.forEach(source => videoPlayer.updateVideo({
        id: source.id,
        src: source.url,
        type: 'video/mp4',
        currentTime: 0,
        hidden: true,
        showSubtitles: true
      }, false))
      // store.commit('video/addLog', 'Video sources resolved')
      store.commit('video/setSourcesReady', true)
      store.commit('video/setSourcesLoading', false)
    } else {
      store.commit('editor/setDocProgress', 70)
      store.commit('video/setSourcesReady', true)
      store.commit('video/setSourcesLoading', false)
    }
  }
  initPlayerWithURLList (urlList, volume) {
    /* Initializing the Tonejs players example
      players = new Tone.Players({
        name1: url1,
        name2: url2
      })
    */
    store.commit('editor/setSourcesLoading', true)
    if (Object.keys(urlList).length > 0) {
      let vm = this
      if (vm.players == null) {
        vm.players = new Tone.Players(urlList, () => {
          // store.commit('video/addLog', `Audio sources resolved ${Date.now()}`)
          store.commit('editor/setSourcesReady', true)
          store.commit('editor/setSourcesLoading', false)
          store.commit('editor/setDocProgress', 20)
        })

        // eslint-disable-next-line no-unused-vars
        for (let [key, value] of Object.entries(urlList)) {
          this.playersName.push(key)
        }
        // console.log(this.playersName)
        vm.players.volume.value = volume
        // downloading the audio
        Tone.Buffer.on('progress', function (p) {
          vm.playerLoadProgress = p // progress shown on frontend
          // store.commit('editor/setPlayerLoadProgress', p)
        })

        this.shift = new Tone.PitchShift(
          this.playbackRateAndPitchShift[this.selectedSpeed].shift
        )
        this.setSpeed(this.selectedSpeed)
        vm.players.connect(this.shift)

        const toneWaveform = new Tone.Waveform(32)
        vm.players.connect(toneWaveform)
        window._spext_waveform = toneWaveform

        vm.shift.toMaster()
      } else { // resolve only new urls and load
        for (let [key, value] of Object.entries(urlList)) {
          try {
            vm.players.get(key)
          } catch (e) {
            vm.players.add(key, value, (player) => {
              // store.commit('video/addLog', `Buffer Loaded - ${player.buffer.duration}`)
              player.volume.value = volume
              this.playersName.push(key)
              this.setSpeed(this.selectedSpeed)
            })
          }
        }

        store.commit('editor/setSourcesReady', true)
        store.commit('editor/setSourcesLoading', false)
        store.commit('editor/setDocProgress', 20)
      }
    }
  }
  setSpeed (selectedSpeed) {
    this.selectedSpeed = selectedSpeed
    /* Set the player speed */
    this.playersName.forEach((key) => {
      this.players.get(key).playbackRate = this.selectedSpeed
    })
    this.shift.pitch = this.playbackRateAndPitchShift[this.selectedSpeed].shift
  }
  getNextNode (state, position) {
    try {
      return state.doc.resolve(position).nodeAfter
    } catch (err) {
      console.log('err', err)
      return null
    }
  }
  computeScheduleFromWorker(state) {
    if (window.location.pathname.includes('/audio/')) {
      if (this.players) {
        store.commit('editor/setIsPlayerGettingReady', true)
        // const t0 = performance.now()
        let volume = state.doc.attrs.volume || 0
        this.loadMusic(volume)
        this.currentSelectionAnchor = this.view.state.selection.anchor
        this.currentSelectionAnchor = this.currentSelectionAnchor - this.diffValue
        let jsonDoc = this.view.state.doc.toJSON()
        // console.log('tonejs: ', this.players)
        this.audioScheduleWorker.computeSchedule(jsonDoc, this.currentSelectionAnchor, store.state.editor.splitVideoPaths).then((obj) => {
          this.audioSchedule = obj.audioSchedule
          if (this.audioSchedule) {
            this.audioSchedule.forEach(sch => {
              if (sch.tonejs && this.players) {
                sch.tonejs = this.players.get(sch.tonejs)
              }
            })
          }
          this.textSchedule = obj.textSchedule
          this.totalTime = obj.totalTime
          this.timeFromWhereAudioStartedPlaying = obj.timeFromWhereAudioStartedPlaying
          setBlockList(this.audioSchedule)
          setVolumeEnvelopeDataList(this.volumeEnvelopeDataList)
          updatePlayerFade()
          store.commit('editor/setIsPlayerGettingReady', false)
        })

        // setBlockList(this.audioSchedule)
        // setVolumeEnvelopeDataList(this.volumeEnvelopeDataList)
        // updatePlayerFade()
        // const t1 = performance.now()
        // console.log(`Call to doSomething took ${t1 - t0} milliseconds.`)
      }
    } else {
      store.commit('editor/setIsPlayerGettingReady', false) // set to false in proseeditor on click, need to be undone.
    }
  }
  /* traverse prosemirror doc and creates schedule */
  computeSchedule (state) {
    const t0 = performance.now()
    let volume = state.doc.attrs.volume || 0
    this.loadMusic(volume)
    // console.log('currentSelectionAnchor audio', this.currentSelectionAnchor, state.selection.anchor)
    this.currentSelectionAnchor = this.currentSelectionAnchor - this.diffValue
    // console.log('currentSelectionAnchor', this.currentSelectionAnchor)
    // var t0 = performance.now();
    let sizeTravelled = 0
    let audioLocationInGlobalTimeline = 0.0
    this.audioSchedule = []
    this.textSchedule = []
    this.totalTime = 0
    this.timeFromWhereAudioStartedPlaying = 0
    let musicMark = null
    let imageMark = null
    let isImageScheduled = false
    let musicSizeTravelled = null
    let imageSizeTravelled = null
    let musicNode = null
    let imageNode = null
    // very important for both editMedia & editTranscript to work together
    // marks added inside transcript editor shouldn't affect the audio play schedule
    state = state.apply(state.tr.removeMark(0, state.doc.nodeSize - 2, state.schema.marks.strong.create()))
    state = state.apply(state.tr.removeMark(0, state.doc.nodeSize - 2, state.schema.marks.em.create()))
    state = state.apply(state.tr.removeMark(0, state.doc.nodeSize - 2, state.schema.marks.underline.create()))
    state = state.apply(state.tr.removeMark(0, state.doc.nodeSize - 2, state.schema.marks.highlight.create()))
    state = state.apply(state.tr.removeMark(0, state.doc.nodeSize - 2, state.schema.marks.transcript.create()))
    state = state.apply(state.tr.removeMark(0, state.doc.nodeSize - 2, state.schema.marks.hideTranscript.create()))
    let ids = {}
    // start node traversal here
    state.doc.content.forEach((paraNode) => {
      // console.log('paraNode', paraNode)
      sizeTravelled += 2
      paraNode.content.forEach((textNode) => {
        // console.log('textNode', textNode)
        let location = sizeTravelled - 1
        sizeTravelled += textNode.nodeSize
        // console.log('sizeTravelled', sizeTravelled)
        if (textNode.attrs.type !== 'endMusic' && textNode.attrs.type !== 'image' &&
          textNode.attrs.type !== 'endImage') {
          textNode.marks.every((mark) => {
            // console.log('mark is', mark)

            if (mark.type.name === 'deleted' || mark.attrs.uid in ids) {
              // console.log('returning mark is', mark)
              return false
            }
            if (mark.attrs.image_mark_uid) {
              imageMark = mark
            }
            if (mark.attrs != null && mark.attrs.uid != null) {
              // console.log('mark is', mark)
              let duration = mark.attrs.aend - mark.attrs.astart
              // if (mark.attrs.uid.split('-')[0] === 'silence') {
              //   duration = 0.1
              // }
              if (mark.attrs.uid.includes('music-')) {
                musicMark = mark
                musicSizeTravelled = sizeTravelled
                musicNode = textNode
              }
              // compute schedule only from the cursor anchor till the end
              if (sizeTravelled > this.currentSelectionAnchor) {
                if (mark.attrs.uid.includes('music-') || musicMark != null) {
                  // console.log('includes music')
                  let skipDuration = 0
                  if (musicMark == null) {
                    musicMark = mark
                    musicNode = textNode
                  } else {
                    let nodeAfter = this.getNextNode(state, musicSizeTravelled)
                    // console.log('nodeAfter', nodeAfter)
                    while ((nodeAfter !== null) && (musicSizeTravelled < sizeTravelled)) {
                      nodeAfter.marks.forEach(function (mark) {
                        if (mark.type.name === 'info') {
                          skipDuration += mark.attrs.aend - mark.attrs.astart
                        }
                      })
                      // find continuous music section
                      musicSizeTravelled += nodeAfter.nodeSize
                      nodeAfter = this.getNextNode(state, musicSizeTravelled)
                      if (nodeAfter == null) {
                        musicSizeTravelled += 2
                        nodeAfter = this.getNextNode(state, musicSizeTravelled)
                      }
                    }
                    // console.log('skipDuration', skipDuration)
                  }
                  let tempSize = sizeTravelled
                  let nodeAfter = this.getNextNode(state, tempSize)
                  let audioDuration = null
                  let tempEndDuration = 0
                  let seenNoteMark = false
                  while (true) {
                    if (nodeAfter == null || nodeAfter.marks == null) {
                      break
                    }
                    seenNoteMark = false
                    tempEndDuration = 0
                    nodeAfter.marks.every(function (mark) {
                      if (mark.type.name === 'deleted') {
                        seenNoteMark = true
                        return false
                      }
                      if (mark.type.name === 'note') {
                        seenNoteMark = true
                      } else if (mark.type.name === 'info') {
                        tempEndDuration = mark.attrs.aend - mark.attrs.astart
                      }
                      return true
                    })
                    if (!seenNoteMark) {
                      break
                    } else {
                      if (audioDuration == null) {
                        audioDuration = 0
                      }
                      audioDuration += tempEndDuration
                    }
                    tempSize += nodeAfter.nodeSize
                    nodeAfter = this.getNextNode(state, tempSize)
                    if (nodeAfter == null) {
                      tempSize += 2
                      nodeAfter = this.getNextNode(state, tempSize)
                    }
                  } // end of while loop
                  if (audioDuration != null) {
                    audioDuration = Math.min(
                      audioDuration,
                      musicMark.attrs.aend - musicMark.attrs.astart
                    )
                  }
                  if (sizeTravelled === tempSize && audioDuration != null) {
                    audioDuration = musicMark.attrs.aend - musicMark.attrs.astart
                  }
                  if (audioDuration == null) {
                    audioDuration = 0
                  }

                  let index = musicMark.attrs.uid.split('-')[1]
                  // write the schedule here
                  try {
                    this.audioSchedule.push({
                      time: audioLocationInGlobalTimeline,
                      tonejs: this.players.get(musicMark.attrs.asource), // this.musicAudioBufferList[index],
                      audioSourceIndex: musicMark.attrs.asource,
                      id: 'music',
                      node: musicNode,
                      startTimeOfNodeInAudioSource: musicMark.attrs.astart,
                      endTimeOfNodeInAudioSource: musicMark.attrs.aend,
                      duration: audioDuration,
                      skipDuration: skipDuration,
                      musicIndex: index,
                      sizeTravelled: sizeTravelled,
                      fadeInDuration: musicNode.attrs.fadeIn.duration,
                      fadeOutDuration: musicNode.attrs.fadeOut.duration
                    })
                  } catch (e) {
                    // console.log('e', e)
                    // Media is missing
                    // console.log(e)
                    this.mediaMissing += 1
                    store.commit('editor/setNoOfMediaMissing', this.mediaMissing)
                  }

                  if (sizeTravelled === tempSize) {
                    audioLocationInGlobalTimeline =
                      audioLocationInGlobalTimeline + duration
                  }
                  musicMark = null
                  musicNode = null
                } else {
                  let videoSourceIndex = mark.attrs.asource
                  let startTimeOfNodeInVideoSource = mark.attrs.astart
                  // let endTimeOfNodeInAudioSource = mark.attrs.aend
                  if (store.state.editor.splitVideoPaths) {
                    let splitMap = store.state.editor.splitVideoPaths[videoSourceIndex]
                    if (splitMap) {
                      for (let i = 0; i < splitMap.length; i++) {
                        if (startTimeOfNodeInVideoSource >= splitMap[i].st && startTimeOfNodeInVideoSource <= splitMap[i].en) {
                          videoSourceIndex = splitMap[i].file
                          startTimeOfNodeInVideoSource = startTimeOfNodeInVideoSource - splitMap[i].st
                          // endTimeOfNodeInAudioSource = endTimeOfNodeInAudioSource - splitMap[i].st
                        }
                      }
                    }
                  }
                  // create last schedule item
                  let lastScheduledItem = this.audioSchedule[0]
                  // console.log('lastScheduledItem', lastScheduledItem)
                  if (this.audioSchedule.length > 1) {
                    lastScheduledItem = this.audioSchedule[this.audioSchedule.length - 1] // get the last item
                  }
                  if (
                    lastScheduledItem != null && lastScheduledItem.audioSourceIndex === mark.attrs.asource &&
                    lastScheduledItem.endTimeOfNodeInAudioSource ===
                    mark.attrs.astart && lastScheduledItem.videoSourceIndex === videoSourceIndex
                  ) {
                    lastScheduledItem.endTimeOfNodeInAudioSource = mark.attrs.aend // update the end time of last node
                  } else {
                    // schedule for non music audio

                    this.audioSchedule.push({
                      time: audioLocationInGlobalTimeline,
                      id: mark.attrs.uid,
                      audioSourceIndex: mark.attrs.asource,
                      // node: textNode,
                      location: location,
                      startTimeOfNodeInAudioSource: mark.attrs.astart,
                      endTimeOfNodeInAudioSource: mark.attrs.aend,
                      videoSourceIndex: videoSourceIndex,
                      startTimeOfNodeInVideoSource: startTimeOfNodeInVideoSource
                    })
                  }
                  ids[mark.attrs.uid] = ''
                  // ids.push(mark.attrs.uid)

                  // TODO: temp for handling clips, need to enhance image handling later.
                  if (imageMark && !isImageScheduled) {
                    this.audioSchedule.push({
                      time: audioLocationInGlobalTimeline,
                      id: 'image',
                      mark: imageMark,
                      // node: textNode,
                      location: location,
                      startTimeOfNodeInAudioSource: mark.attrs.astart,
                      endTimeOfNodeInAudioSource: mark.attrs.aend,
                      index: this.textSchedule.length
                    })
                    isImageScheduled = true
                  }
                  this.textSchedule.push({
                    time: audioLocationInGlobalTimeline,
                    id: mark.attrs.uid,
                    mark: imageMark,
                    // node: textNode,
                    location: location,
                    startTimeOfNodeInAudioSource: mark.attrs.astart,
                    endTimeOfNodeInAudioSource: mark.attrs.aend,
                    index: this.textSchedule.length
                  })
                  audioLocationInGlobalTimeline =
                    audioLocationInGlobalTimeline + duration
                  this.totalTime += duration
                }
                imageMark = null
                imageNode = null
                musicMark = null
              } else {
                if (!mark.attrs.uid.includes('music-') && !mark.attrs.uid.includes('endmus-')) {
                  this.timeFromWhereAudioStartedPlaying += duration
                  this.totalTime = this.timeFromWhereAudioStartedPlaying
                }
              }
            }
            return true
          })
        }
        if (textNode.attrs.type === 'endMusic') {
          musicMark = null
          musicSizeTravelled = null
          musicNode = null
        }
      })
    })
    // console.log('ids', ids)
    // console.log('audioSchedule is', this.audioSchedule)
    // console.log('textSchedule is', this.textSchedule.length)

    // these calls are for block mode
    setBlockList(this.audioSchedule)
    setVolumeEnvelopeDataList(this.volumeEnvelopeDataList)
    updatePlayerFade()
    const t1 = performance.now()
    console.log('Call to computeschedule took ' + (t1 - t0) + ' milliseconds.')
  }

  generateAudioPart () {
    /**
     * how to play the audio part
     * music and non music
     * in non music calculate duration and also add some fadein fadeout
     */
    this.audioPart = new Tone.Part((time, event) => {
      if (event.id === 'image') {
        // console.log(event, 'admxckjhfsd')
        if (event.mark) {
          const { source: imageSourceId, position, image_mark_uid: imageId } = event.mark.attrs
          videoPlayer.updateImage({
            id: imageId,
            src: this.imageUrlList[imageSourceId], // image source
            position: position || {},
            hidden: false
          })
        }
      }
      if (event.id === 'music') {
        // store.commit('doc/setIsOverMusic', true)
        // console.log('admxckjccchfsd', event)
        // event.tonejs.start(Tone.now(), 0, event.duration)
        try {
          let player = this.players.get(event.audioSourceIndex)
          if (this.videoUrlList[event.audioSourceIndex]) {
            videoPlayer.updateVideo({
              id: event.audioSourceIndex,
              // TODO: improve scheduler, so that we dont need temp id
              tempId: event.musicIndex || event.audioSourceIndex,
              src: this.videoUrlList[event.audioSourceIndex],
              type: 'video/mp4',
              currentTime: event.startTimeOfNodeInAudioSource + event.skipDuration,
              showSubtitles: true,
              hidden: false
            })
          } else {
            let nextNode = this.view.state.doc.nodeAt(event.sizeTravelled)
            let markAttrs = false
            if (nextNode) {
              let markAttrs = getInfoMarkFromTextNode(nextNode).attrs
            }
            if (markAttrs && markAttrs.uid.split('silence-').length > 0) {
              videoPlayer.updateVideo({})
            }
          }

          player.fadeIn = event.fadeInDuration
          player.fadeOut = event.fadeOutDuration
          player.start(
            Tone.now(),
            event.startTimeOfNodeInAudioSource + event.skipDuration,
            event.duration
          )
        } catch (e) {
          console.log(e.name, e.message, e)
        }
      } else if (event.id !== 'image' && event.id.split('-')[0] !== 'silence' && event.id.split('-')[0] !== 'mtext') {
        // store.commit('doc/setIsOverMusic', false)
        try {
          let player = this.players.get(event.audioSourceIndex)
          let videoSourceIndex = event.videoSourceIndex
          let videoCurrentTime = event.startTimeOfNodeInVideoSource
          videoPlayer.updateVideo({
            id: videoSourceIndex,
            // TODO: improve scheduler, so that we dont need temp id
            tempId: event.musicIndex || videoSourceIndex,
            src: this.videoUrlList[videoSourceIndex],
            type: 'video/mp4',
            currentTime: videoCurrentTime,
            showSubtitles: true,
            hidden: false
          })
          player.fadeIn = 0.1
          player.fadeOut = 0.1
          player.start(
            '+0.01',
            event.startTimeOfNodeInAudioSource,
            event.endTimeOfNodeInAudioSource - event.startTimeOfNodeInAudioSource // duration
          )
        } catch (e) {
          console.log(e.name, e.message, e)
        }
      } else {
        // videoPlayer.updateVideo({})
      }
    }, this.audioSchedule)
  }

  startPlayer (computeSchedule = false) {
    /**
     *  when we click on the play button run this function
     */
    const t0 = performance.now()
    // document._selection_background = document.documentElement.style.getPropertyValue('--selection-background')
    // document.documentElement.style.setProperty('--selection-background', 'transparent')
    let vm = this
    this.isPlaying = true
    // #TODO enable it to take various store instead of global store only

    const increaseTime = () => {
      const toneElapsedTime = Tone.Transport.getSecondsAtTime()
      const currentElapsedTime = store.state.doc.elapsedTime
      document._spext_currentTime = currentElapsedTime + toneElapsedTime
      // console.log(window.location.href)
      store.commit('doc/setCurrentTime', currentElapsedTime + toneElapsedTime)
      if (!window.location.pathname.includes('/player/')) {
        // store.commit('doc/setCurrentTime', currentElapsedTime + toneElapsedTime)
      } else {
        if (document._spext_currentTime > this.totalTime) {
          setTimeout(() => {
            this.stopPlayer()
            // store.commit('video/addLog', `Doc endedm, recording stopped, Bool(window.stopRecording) - ${typeof window.stopRecording}`)
            if (window.stopRecording) window.stopRecording()
          }, 1000)
        }
      }
    }
    this.timerInterval = setInterval(increaseTime, 1000 / this.selectedSpeed)
    store.commit('editor/setIsAudioPlaying', vm.isPlaying)
    this.currentSelectionAnchor = this.view.state.selection.anchor
    if (computeSchedule) {
      this.computeSchedule(this.view.state)
    }

    // var t0 = performance.now();
    let startTextIndex = 0
    Tone.context.resume().then(() => {
      /* provide audioSchedule to audioPart and play audio when event occurs according to audioSchedule */
      this.generateAudioPart()
      const t1 = performance.now()
      console.log('Call to generateAudioPart took ' + (t1 - t0) + ' milliseconds.')
      /* provide textSchedule to textPart and highlight text when event occurs according to textSchedule */
      this.textPart = new Tone.Part(
        (time, event) => {
          if (event.mark) {
            const { source: imageSourceId, position, image_mark_uid: imageId } = event.mark.attrs
            videoPlayer.updateImage({
              id: imageId,
              src: this.imageUrlList[imageSourceId], // image source
              position: position || {},
              hidden: false
            })
          }
          //  console.log('generating text event')
          //  console.log('time', time)
          //  console.log('event', event.time)

          selectNode(event.location, this.view, false, this.isAutoScroll) // highlight the word

          // isDemo
          if (this.isDemo) {
            if (event.id === `${CONSTANTS.demoMediaid}-112`) {
              if (!this.checkPoint1) {
                this.checkPoint1 = true
                this.stopPlayer()
              }
            } else if (event.id === `${CONSTANTS.demoMediaid}-171`) {
              if (!this.checkPoint2) {
                this.checkPoint2 = true
                this.stopPlayer()
              }
            } else if (event.id === `${CONSTANTS.demoMediaid}-148`) {
              if (!this.checkPoint3) {
                this.checkPoint3 = true
              }
            }
          }

          if (!CONSTANTS.LOOP) {
            this.currentSelectionAnchor = event.location
          }
          //  document.querySelector('.tooltip').style.display = 'none'

          // update current time
          this.currentTime =
            (this.timeFromWhereAudioStartedPlaying + event.time) *
             this.selectedSpeed
          // #todo make it to inititalise store also
          // store.commit('editor/setCurrentTime', this.currentTime)
          // console.log("currentTime:::: " + this.currentTime);

          // to update the next text part for highlighter
          for (let i = 0; i < 5; i++) {
            if (startTextIndex < this.textSchedule.length - 1) {
              startTextIndex++
              this.textPart.add(this.textSchedule[startTextIndex])
            }
          }

          // check if reached end
          if (event.index === (this.textPart.length - 1)) {
            setTimeout(function () {
              vm.stopPlayer()
              // store.commit('video/addLog', `Doc endedm, recording stopped, Bool(window.stopRecording) - ${typeof window.stopRecording}`)
              if (window.stopRecording) window.stopRecording()
              store.commit('editor/setDocEndedAt', Date.now())

              if (!vm.playingComplete) {
                vm.playingComplete = true
              }
            }, 1000 * (event.endTimeOfNodeInAudioSource - event.startTimeOfNodeInAudioSource) + 1000) // check end only after sometime
          }
        },
        [this.textSchedule[0]]
      )

      const t2 = performance.now()
      console.log('Call to generatetextPart took ' + (t2 - t1) + ' milliseconds.')
      /* Set the part speed same as player speed */
      this.textPart.playbackRate = this.selectedSpeed

      this.audioPart.start(0)
      if (!window.location.pathname.includes('/player/')) {
        this.textPart.start(0)
      }
      const t3 = performance.now()
      console.log('Call to startplayer took ' + (t3 - t2) + ' milliseconds.')
      /* https://tonejs.github.io/docs/r13/Transport */
      Tone.Transport.start('+0.1')
    }).catch(function (err) {
      console.log(err)
    })
    // var t1 = performance.now();
    // console.log("Call to startplayer took " + (t1 - t0) + " milliseconds.");
    // document._selection_background = document.documentElement.style.getPropertyValue('--selection-background')
    // document.documentElement.style.setProperty('--selection-background', 'transparent')
  }
  exportWav (filename, volume) {
    this.computeSchedule(this.view.state)
    // wrapper around OfflineAudioContext
    // In contrast with a standard AudioContext, an OfflineAudioContext doesn't render the audio to the device hardware;
    // instead, it generates it, as fast as it can, and outputs the result to an AudioBuffer.
    Tone.Offline(() => {
      let players = new Tone.Players()
      this.playersName.forEach((key) => {
        players.add(key, this.players.get(key).buffer)
      })
      this.players = players
      this.players.volume.value = volume
      this.players.toMaster()
      this.generateAudioPart()
      this.audioPart.start(0)
      Tone.Transport.start('+0.1')
    }, this.totalTime).then((buffer) => {
      let anchor = document.createElement('a') // create an html element
      // anchor.setAttribute("id", "wavdownload");
      anchor.setAttribute('id', 'downloadLink')
      // anchor.style = 'display: none'
      console.log('Converting to Wav file ' + performance.now())
      let wav = toWav(buffer.get()) // start export process here
      console.log('Converted to Wav file ' + performance.now())
      let blob = new window.Blob([new DataView(wav)], {
        type: 'audio/wav'
      })
      let url = window.URL.createObjectURL(blob) // create the url here
      anchor.href = url
      anchor.download = `${filename}.wav`
      document.body.appendChild(anchor)
      anchor.click()
      window.URL.revokeObjectURL(url)
      xhr.setRequestHeader('content-type', 'application/octet-stream')
      xhr.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          console.log('downloading file')
        }
      }
      // let fileWav = toWav(buffer.get())
      xhr.send(wav)
    })
  }
  stopPlayer () {
    clearInterval(this.timerInterval)
    Tone.Master.mute = false
    var vm = this
    vm.isPlaying = false
    store.commit('editor/setIsAudioPlaying', vm.isPlaying)
    Tone.Transport.stop()
    if (vm.audioPart !== null) {
      vm.audioPart.stop()
    }
    if (vm.textPart !== null) {
      vm.textPart.stop()
    }
    // vm.player[0].stop()
    if (vm.players) {
      vm.players.stopAll(1)
    }
    // this.musicAudioBufferList.forEach((player) => {
    //   player.stop()
    // })
    try {
      selectNode(this.currentSelectionAnchor, this.view, true, this.isAutoScroll)
    } catch (err) {
      console.log(err)
    }
    // document.documentElement.style.setProperty('--selection-background', document._selection_background)
    // moveCursorToBoundary(this.view)
  }

  playFromHere () {
    /** if user click somewhere in transcript play from there */
    if (this.isPlaying) {
      this.stopPlayer()
      this.startPlayer(true)
      return true
    }
    return false
  }

  addMedia (url, name, source, isReplacing) {
    if (isReplacing) {
      new Tone.Player(url, (player) => {
        this.musicAudioBufferList[source] = (player)
        replaceAudio(this.view, this.musicAudioBufferList, name, source)
      }).toMaster()
    } else {
      new Tone.Player(url, (player) => {
        this.musicAudioBufferList[source] = (player)
        loadAudioWithIndex(this.view, this.musicAudioBufferList, name, source)
      }).toMaster()
      // loadImageWithIndex(this.view, name, source, {
      //   top: 10,
      //   right: 20,
      //   zindex: 30
      // })
    }
    // let uniqueSourceId = uuidv1()
  }
}
