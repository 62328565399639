import { Plugin } from 'prosemirror-state'
import './selectionToolbar.css'
import { remove, restore } from '../commands'
import crel from 'crel'
import store from '@/services/store'
import {
  computeTimeAtPos, correctTranscript,
  doesMusicExists,
  isMarkDeleted,
  myProseEditor, setupCorrectTranscriptPopup
} from '@/view/voiceEditor/proseEditor/util/utility'
import { getNextWords } from '@/view/voiceEditor/proseEditor/util/editorSearchUtility'
import { getInfoMarkFromTextNode } from '@/view/voiceEditor/proseEditor/Nodes/textNode'
import { clipPill } from '../CustomNodes'
const uuidv1 = require('uuid/v1')
const magicNumber = 100

/***
 * Similar to selectionToolbarTranscript
 * See that for comments
 */

// Step:1. Make a view component to tie to editor's life cycle
// used when pluign needs to interact with the editor view, or set something up in the DOM
export const selectionToolbar = new Plugin({
  view (editorView) {
    if (myProseEditor.isUsedInProject) {
      return new SelectionToolbar(itemForClipper, editorView)
    }

    return new SelectionToolbar(items, editorView)
  }
})

class SelectionToolbar {
  constructor (items, view) {
    this.items = items
    this.itemDoms = []
    this.view = view
    this.addMusicElementDeleted = false
    this.tooltip = document.createElement('div')
    this.tooltip.className = 'selection-toolbar'
    this.tooltip.id = 'selectionToolbar'
    items.forEach(({ dom }) => {
      this.itemDoms.push(this.tooltip.appendChild(dom))
    })
    if (view.dom.parentNode) {
      view.dom.parentNode.appendChild(this.tooltip)
    }
    this.update(view, null)

    this.tooltip.addEventListener('mousedown', e => {
      e.preventDefault()
      view.focus()
      items.forEach(({ command, dom }) => {
        if (dom.contains(e.target)) { command(view.state, view.dispatch, view) }
      })
    })

    // hide toolbar when editor not focused
    this.view.dom.addEventListener('blur', this.hide.bind(this))
  }

  // Step:2. Use prosemirror's update cycle to make sure tooltip stays in sync with editor
  // called when views's state is updated
  update (view, lastState) {
    const pathname = window.location.pathname
    if (window._spext_transcriptMode || pathname === '/onboarding' || pathname === '/drive') {
      this.hide()
      return
    }
    // TODO: remove use of store
    if ((myProseEditor && myProseEditor.audioControl && myProseEditor.audioControl.isPlaying) || document._isPillBeingDragged || document._musicJustAdded) {
      this.hide()
      document._musicJustAdded = false
      return
    }
    // Conditions to avoid showing the clipper toolbar
    const uids = Object.keys(clipPill.PillEvents)
    if ((uids.length && uids.some(uid => clipPill.PillEvents[uid].dragStarted)) || clipPill.isHovered) {
      this.hide()
      return
    }
    const doc = view.state.doc
    const anchor = doc.resolve(view.state.selection.anchor)
    const head = doc.resolve(view.state.selection.head)
    if (anchor.pos === head.pos) {
      // nothing is selected
      // return false
    } else {
      const slice = view.state.selection.content() // get selection
      if (doesMusicExists(slice) && !myProseEditor.isUsedInProject) {
        if (!this.addMusicElementDeleted) {
          this.tooltip.removeChild(this.itemDoms[0])
          this.addMusicElementDeleted = true
        }
      } else {
        if (this.addMusicElementDeleted) {
          this.tooltip.prepend(this.items[0].dom)
          this.addMusicElementDeleted = false
        }
      }
    }

    let state = view.state
    // Don't do anything if the document/selection didn't change
    if (lastState && lastState.doc.eq(state.doc) &&
          lastState.selection.eq(state.selection)) return

    // Hide the tooltip if the selection is empty
    if (state.selection.empty) {
      this.hide()
      return
    }
    let showTranscriptBtnTruth = true
    let isSameSourceSelection = null
    let lastTime = -1.0
    if (state.selection.content() && state.selection.content().content &&
      state.selection.content().content.content) {
      state.selection.content().content.content.forEach(node => {
        node.content.forEach(textNode => {
          let infoMark = getInfoMarkFromTextNode(textNode).attrs
          if (infoMark) {
            if (lastTime >= 0 && lastTime !== infoMark.astart) {
              showTranscriptBtnTruth = false
            }
            lastTime = infoMark.aend
            if (isSameSourceSelection === null) {
              isSameSourceSelection = infoMark.asource
            } else if (isSameSourceSelection === infoMark.asource) {
              isSameSourceSelection = infoMark.asource
            } else {
              showTranscriptBtnTruth = false
              isSameSourceSelection = false
            }
          }
        })
      })
    }

    if (!showTranscriptBtnTruth) {
      if (document.getElementById('correctTranscriptButton')) {
        document.getElementById('correctTranscriptButton').style.display = 'none'
      }
    } else {
      if (document.getElementById('correctTranscriptButton')) {
        document.getElementById('correctTranscriptButton').style.display = 'inline'
      }
    }
    // if (window.getSelection().toString().split(' ').length > 2 || (window.getSelection().toString().includes(' ') && window.getSelection().toString().includes(silenceSymbol))) {
    //   document.getElementById('correctTranscriptButton').style.display = 'none'
    //   return
    // } else {
    //   document.getElementById('correctTranscriptButton').style.display = 'inline'
    // }
    if (!state.selection.empty) {
      let anchor = state.selection.anchor // - 2
      if (state.selection.anchor > state.selection.head) {
        anchor = state.selection.head
      }
      if (anchor < 0) {
        anchor = state.selection.anchor
      }
      let textNode = state.doc.nodeAt(anchor)
      // console.log('czasdasdas', textNode.text, state.selection.anchor, state.selection.head)
      if (textNode && isMarkDeleted(textNode.marks)) {
        if (document.getElementById('tpDeleteMedia') && document.getElementById('tpRestoreMedia')) {
          document.getElementById('tpDeleteMedia').style.display = 'none'
          document.getElementById('tpRestoreMedia').style.display = 'inline'
        }
      } else {
        if (document.getElementById('tpDeleteMedia') && document.getElementById('tpRestoreMedia')) {
          document.getElementById('tpDeleteMedia').style.display = 'inline'
          document.getElementById('tpRestoreMedia').style.display = 'none'
        }
      }
    }

    const clipperIcon = document.getElementById('editorClipperIcon')
    if (!store.state.clipper.clipper) {
      if (clipperIcon) {
        document.getElementById('editorClipperIcon').style.display = 'none'
      }
    } else {
      if (clipperIcon) {
        document.getElementById('editorClipperIcon').style.display = 'inline'
      }
    }

    // Otherwise, reposition it and update its content
    this.tooltip.style.display = ''

    // Method A of selection
    let { from, to } = state.selection
    let start = view.coordsAtPos(from)
    let end = view.coordsAtPos(to)
    let left = Math.min((start.left + end.left) / 2, end.left + 3)
    let top = Math.max((start.top + end.top) / 2, end.top + 3) - 40
    this.tooltip.style.left = left - 20 + 'px'
    this.tooltip.style.top = top - 4 + 'px'

    // // Mehtod B of selection
    // let { anchor: from, head: to } = state.selection
    // const nodeAtPos = view.nodeDOM(to)
    // let left, top
    // if (nodeAtPos && nodeAtPos.parentNode) {
    //   left = nodeAtPos.parentNode.offsetLeft
    //   top = nodeAtPos.parentNode.offsetTop
    // } else {
    //   return
    // }
    // this.tooltip.style.left = left + (to > from ? (-10) : (10)) + 'px'
    // this.tooltip.style.top = top - 45 + 'px'

    if (!window._spext_editorScrollTop) {
      window._spext_editorScrollTop = 0
    }
    window.selectionToolbar = window._spext_editorScrollTop + top
  }

  hide (event) {
    if (event && event.relatedTarget) {
      return
    }
    this.tooltip.style.display = 'none'
    if (document.getElementById('correctTranscriptId')) {
      document.getElementById('correctTranscriptId').remove()
    }
  }

  destroy () {
    this.view.dom.removeEventListener('blur', this.hide)
    this.tooltip.remove()
  }
}

const items = [
  // { command: playFromHere, dom: icon('toolbarIcon toolbarPlay', 'Play Media') },
  { command: showAddMediaModal, dom: icon('toolbarIcon toolbarAdd', 'Add Media', 'tpAddMedia') },
  // { command: cut, dom: icon('toolbarIcon toolbarCut', 'Cut Audio') },
  // { command: copy, dom: icon('toolbarIcon toolbarCopy', 'Copy Audio') },
  { command: removeProxy, dom: icon('toolbarIcon toolbarDelete', 'Remove Media', 'tpDeleteMedia') },
  { command: restoreProxy, dom: icon('toolbarIcon toolbarRestore', 'Restore Media', 'tpRestoreMedia') },
  { command: showCommentBox, dom: icon('toolbarIcon toolbarComment', 'Add Comment', 'tpComment') },
  { command: showCorrectTranscriptModal, dom: icon2('toolbarIcon toolbarCorrectTranscript', 'Correct Transcript') },
  { command: clip, dom: icon('toolbarIcon toolbarClip', 'Clip', 'editorClipperIcon') }
]
const itemForClipper = [
  { command: clip, dom: icon('toolbarIcon toolbarClip', 'Clip', 'manualclippericon') }
]

// Helper function to create menu icons
function icon (text, name, id = '') {
  return crel('button', {
    id: id,
    class: 'text-base text-white p-2 bg-primary hover:bg-primary-900 rounded-lg',
    title: name
  },
  crel('div', { class: text })
  )
}
function icon2 (text, name) {
  return crel('button', {
    id: 'correctTranscriptButton',
    class: 'text-base text-white p-2 bg-primary hover:bg-primary-900 rounded-lg',
    title: name
  },
  crel('div', { class: text })
  )
}
function cut () {
  document.execCommand('cut')
}

function clip (state, dispatch, view) {
  let start = state.selection.anchor
  let end = state.selection.head
  if (end < start) {
    start = state.selection.head
    end = state.selection.anchor
  }
  let starTime = computeTimeAtPos(start, view)
  let endTime = computeTimeAtPos(end, view)
  myProseEditor.clip(starTime, endTime, getNextWords(start, 5, true).text, { start, end })
}

function copy () {
  document.execCommand('copy')
}

function showAddMediaModal () {
  // this.tooltip.style.display = 'none'
  document.getElementById('selectionToolbar').style.display = 'none'
  if (store.state.clipper.clips.length) {
    store.dispatch('clipper/openExportModal', true)
    return
  }
  store.dispatch('dialogs/openModal', { name: 'AddFile',
    props: {
      soundMode: true
    } })
}

function playFromHere(state, dispatch, view) {
  myProseEditor.audioControl.startPlayer()
}
function removeProxy(state, dispatch, view) {
  remove(state, dispatch, view)
}
function restoreProxy(state, dispatch, view) {
  restore(state, dispatch, view)
}
function showCorrectTranscriptModal (state, dispatch, view) {
  let { inputElement, buttonElement } = setupCorrectTranscriptPopup(view, true)
  buttonElement.addEventListener('click', e => {
    e.preventDefault()
    let start = state.selection.anchor
    let end = state.selection.head
    if (end < start) {
      start = state.selection.head
      end = state.selection.anchor
    }
    correctTranscript(view, start, end, inputElement)
  })

  inputElement.addEventListener('keyup', function(event) {
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      event.preventDefault()
      let start = state.selection.anchor
      let end = state.selection.head
      if (end < start) {
        start = state.selection.head
        end = state.selection.anchor
      }
      correctTranscript(view, start, end, inputElement)
    }
  })
}
function showCommentBox (state, dispatch, view) {
  document.getElementById('selectionToolbar').style.display = 'none'
  store.state.comment.newComment = {
    id: 'ct-' + uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1'),
    pos: window.pageYOffset + view.coordsAtPos(state.selection.from).top - magicNumber + 20
  }
}
