<template>
  <div
    class="flex h-full w-full items-center justify-center"
    :style="style"
  >
    <video
      :id="`videoblock-${element.id}`"
      name="media"
      preload="auto"
      muted
      :src="element.value"
      class="h-full w-full object-cover"
    />

    <div
      v-if="debug"
      class="bg-white rounded-tl text-primary py-0.25 px-2 right-0 bottom-0 text-3xs absolute"
    >
      Video time: {{currentTime}}
    </div>
  </div>
</template>

<script>
import store from '@/services/store'
import { mapGetters } from 'vuex'

export default {
  name: 'VideoBlock',
  props: {
    debug: Boolean,
    element: Object,
    isPlaying: Boolean
  },
  data() {
    return {
      player: null
    }
  },
  computed: {
    ...mapGetters({
      sources: 'video/sources',
      docEndedAt: 'editor/docEndedAt'
    }),
    src: function() {
      return this.sources[this.element.value] // || this.element.value
    },
    currentTime: function() {
      return this.element.currentTime
    },
    tempId: function() {
      const { tempId, hidden, updateCount } = this.element
      return `${tempId}-${updateCount}`
    },
    meta: function() {
      return this.element.meta
    },
    style: function() {
      const { size, position, cover, style } = this.element
      const { x, y } = position
      let transform = ''
      if (x) transform += 'translateX(-50%) '
      if (y) transform += 'translateY(50%)'
      const styles = {
        position: 'absolute',
        ...x ? { left: `${x}%` } : {},
        ...y ? { bottom: `${y}%` } : {},
        ...size,
        ...cover ? {} : { transform },
        ...style
      }

      return styles
    }
  },
  methods: {
    playVideo: function(callee = '') {
      if (this.element.value && this.player.paused) {
        this.player.play()
        // store.commit('video/addLog', `player.play() called by ${callee}`)
      }
    },
    pauseVideo: function(callee = '') {
      if (this.element.value) {
        this.player.pause()
        // store.commit('video/addLog', `player.pause() called by ${callee}`)
      }
    }
  },
  mounted: function() {
    this.player = document.getElementById(`videoblock-${this.element.id}`)
    this.player.src = this.src
    this.player.currentTime = this.currentTime
    if (this.isPlaying) this.playVideo('mounted')
  },
  watch: {
    src: function(val) {
      this.player.src = val
      // store.commit('video/addLog', `Video src changed`)
    },
    isPlaying: function(val, oldVal) {
      if (val !== oldVal) {
        if (val) this.playVideo('isPlaying watcher')
        else this.pauseVideo('isPlaying watcher')
      }
    },
    // currentTime: function(val) {
    //   this.player.currentTime = val
    //   this.playVideo('currentTime watcher')
    // },
    tempId: function() {
      this.player.currentTime = this.currentTime
      this.playVideo('tempId watcher')
    },
    docEndedAt: function() {
      this.pauseVideo('docEndedAt watcher')
    }
  }
}
</script>

<style>

</style>
