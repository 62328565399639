import { TextSelection, NodeSelection } from 'prosemirror-state'
import { canSplit } from 'prosemirror-transform'
import { Fragment } from 'prosemirror-model'
import { moveCursorToBoundary } from '../util/utility'
import CONSTANTS from '@/constants/CONSTANTS'
/**
 * imported from prosemirror-commands/splitBlock
 * TODO: move cursor to correct position
 * TODO: when text selected move all selection to correct pos
 */
export function splitBlockAfterWord (state, dispatch, view) {
  /**
   * modified for having 2 para created when user click enter key
  */
  // moveCursorToBoundary();
  let { $from, $to } = state.selection
  if ($to.textOffset === 0) {

  } else if ($to.nodeAfter !== null) {
    $to = state.doc.resolve($to.pos + $to.nodeAfter.nodeSize)
  } else {
    $to = state.doc.resolve($to.pos + 2)
  }
  $from = $to
  if (
    state.selection instanceof NodeSelection &&
      state.selection.node.isBlock
  ) {
    if (!$from.parentOffset || !canSplit(state.doc, $from.pos)) return false
    if (dispatch) dispatch(state.tr.split($from.pos).scrollIntoView())
    return true
  }

  if (!$from.parent.isBlock) return false

  if (dispatch) {
    let atEnd = $to.parentOffset === $to.parent.content.size
    let tr = state.tr
    // if (state.selection instanceof TextSelection) tr.deleteSelection();
    let deflt =
        $from.depth === 0
          ? null
          : $from.node(-1).contentMatchAt($from.indexAfter(-1)).defaultType
    let types = atEnd && deflt ? [{ type: deflt }] : null
    let can = canSplit(tr.doc, $from.pos, 1, types)
    if (
      !types &&
        !can &&
        canSplit(tr.doc, tr.mapping.map($from.pos), 1, deflt && [{ type: deflt }])
    ) {
      types = [{ type: deflt }]
      can = true
    }
    if (can) {
      tr.split(tr.mapping.map($from.pos), 1, types) // create one para here
      // when user click enter, two para should be created
      if (CONSTANTS.EXTRA_PARA) {
      // Required for floating menu
        const currentDom = view.domAtPos($from.pos)
        const isActive = currentDom.node.innerHTML === '<br>' &&
        currentDom.node.tagName === 'P' &&
        currentDom.node.parentNode === view.dom
        if (!isActive) {
        // Make 1 more enter
          tr.split(tr.mapping.map($from.pos), 1, types) // another one here
        }
      }

      if (
        !atEnd &&
          !$from.parentOffset &&
          $from.parent.type !== deflt &&
          $from
            .node(-1)
            .canReplace(
              $from.index(-1),
              $from.indexAfter(-1),
              Fragment.from(deflt.create(), $from.parent)
            )
      ) { tr.setNodeMarkup(tr.mapping.map($from.before()), deflt) }
    }
    dispatch(tr.setSelection(new TextSelection(tr.doc.resolve($to.pos + 2))).scrollIntoView())
  }
  return true
}

export function splitBlockAtLocation (from, to, state, dispatch, view) {
  // get "$from object and to object" by integer
  let $from = state.doc.resolve(from)
  let $to = state.doc.resolve(to)

  if (
    state.selection instanceof NodeSelection &&
    state.selection.node.isBlock
  ) {
    if (!$from.parentOffset || !canSplit(state.doc, $from.pos)) return false
    if (dispatch) dispatch(state.tr.split($from.pos).scrollIntoView())
    return true
  }

  if (!$from.parent.isBlock) return false

  if (dispatch) {
    let atEnd = $to.parentOffset === $to.parent.content.size
    let tr = state.tr
    // if (state.selection instanceof TextSelection) tr.deleteSelection();
    let deflt =
      $from.depth === 0
        ? null
        : $from.node(-1).contentMatchAt($from.indexAfter(-1)).defaultType
    let types = atEnd && deflt ? [{ type: deflt }] : null
    let can = canSplit(tr.doc, $from.pos, 1, types)
    if (
      !types &&
      !can &&
      canSplit(tr.doc, tr.mapping.map($from.pos), 1, deflt && [{ type: deflt }])
    ) {
      types = [{ type: deflt }]
      can = true
    }
    if (can) {
      let offSet = 0
      if ($from.nodeAfter === null) {
        offSet = 2
      } else {
        offSet = $from.nodeAfter.nodeSize
      }
      tr.split(tr.mapping.map($from.pos + offSet), 1, types)
      if (
        !atEnd &&
        !$from.parentOffset &&
        $from.parent.type !== deflt &&
        $from
          .node(-1)
          .canReplace(
            $from.index(-1),
            $from.indexAfter(-1),
            Fragment.from(deflt.create(), $from.parent)
          )
      ) { tr.setNodeMarkup(tr.mapping.map($from.before()), deflt) }
    }
    dispatch(tr) // dispatch(tr.scrollIntoView())
  }
  moveCursorToBoundary(view)
  return true
}
