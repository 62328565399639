import { Plugin } from 'prosemirror-state'
import './selectionToolbar.css'
import crel from 'crel'
import store from '@/services/store'
import { toggleMark } from 'prosemirror-commands'
import { schema } from '../Schema/schema'
import { getSliceContent, myProseEditor } from '../util/utility'
const uuidv1 = require('uuid/v1')

// Step:1. Make a view component to tie to editor's life cycle
// used when pluign needs to interact with the editor view, or set something up in the DOM
export const selectionToolbarTranscript = new Plugin({
  view (editorView) {
    return new SelectionToolbarTranscript(items, editorView)
  }
})

class SelectionToolbarTranscript {
  constructor (items, view) {
    this.items = items
    this.view = view

    this.tooltip = document.createElement('div') // create div
    // this.tooltip.className = 'tooltip'
    this.tooltip.className = 'selection-toolbar' // tell class name
    this.tooltip.id = 'selectionToolbar'
    items.forEach(({ dom }) => this.tooltip.appendChild(dom))
    view.dom.parentNode.appendChild(this.tooltip)
    this.update(view, null)

    // what happen when you click
    this.tooltip.addEventListener('mousedown', e => {
      e.preventDefault()
      view.focus()
      items.forEach(({ command, dom }) => {
        if (dom.contains(e.target)) { command(view.state, view.dispatch, view) }
      })
    })

    // hide toolbar when editor not focused
    this.view.dom.addEventListener('blur', this.hide.bind(this))
  }

  // Step:2. Use prosemirror's update cycle to make sure tooltip stays in sync with editor
  // called when views's state is updated
  update (view, lastState) {
    // TODO: remove use of store
    // if audio is playing dont show the toolbar
    if (myProseEditor && myProseEditor.audioControl && myProseEditor.audioControl.isPlaying) {
      this.hide()
      return
    }
    let state = view.state
    // Don't do anything if the document/selection didn't change
    if (lastState && lastState.doc.eq(state.doc) &&
          lastState.selection.eq(state.selection)) return

    // Hide the tooltip if the selection is empty
    if (state.selection.empty) {
      this.hide()
      return
    }

    // Otherwise, reposition it and update its content
    this.tooltip.style.display = ''
    let { from, to } = state.selection
    // These are in screen coordinates
    let start = view.coordsAtPos(from)
    let end = view.coordsAtPos(to)
    // console.log('start,end', start, end)
    // The box in which the tooltip is positioned, to use as base
    // let box = this.tooltip.offsetParent.getBoundingClientRect()
    // console.log('box', box)
    // Find a center-ish x position from the selection endpoints (when
    // crossing lines, end may be more to the left)
    // let left = Math.max((start.left + end.left) / 2, start.left + 3)
    // this.tooltip.style.left = (left - box.left) + 'px'
    // this.tooltip.style.bottom = (box.bottom - start.top) + 'px'
    // this.tooltip.textContent = to - from

    // check if item is to be made active or not
    // this.items.forEach(({command, dom}) => {
    //   let active = command(this.view.state, null, this.view)
    //   dom.style.display = active ? '' : 'none'
    // })

    // placing of toolbar
    let left = Math.min((start.left + end.left) / 2, end.left + 3)
    let top = (start.top - 40)
    // console.log('setting left,top', left, top)
    this.tooltip.style.left = left + 'px'
    this.tooltip.style.top = top + 'px'
    window.selectionToolbar = window.scrollY + top
  }

  // function to hide the toolbar
  hide (event) {
    if (event && event.relatedTarget) {
      return
    }
    this.tooltip.style.display = 'none'
  }

  destroy () {
    this.view.dom.removeEventListener('blur', this.hide)
    this.tooltip.remove()
  }
}

// these are the itmes goes to constructor
// each item is command and dom element
const items = [
  { command: toggleMark(schema.marks.strong), dom: icon('toolbarIcon toolbarBold', 'Bold') },
  { command: toggleMark(schema.marks.em), dom: icon('toolbarIcon toolbarItalic', 'Italic') },
  { command: toggleMark(schema.marks.underline), dom: icon('toolbarIcon toolbarUnderline', 'Underline') },
  { command: toggleMark(schema.marks.highlight), dom: icon('toolbarIcon toolbarEdit', 'Highlight') },
  { command: showCommentBox, dom: icon('toolbarIcon toolbarComment', 'Add Comment') },
  { command: shareOnFacebook, dom: icon('toolbarIcon toolbarFb', 'Share on Facebook') },
  { command: tweet, dom: icon('toolbarIcon toolbarTwitter', 'Tweet') }
  // { command: shareAsVideo, dom: icon('fal fa-video', 'Share as Video') },

]

// Helper function to create menu icons
function icon (text, name) {
  let element = crel('button', { 'class': 'text-base text-white p-2 bg-primary hover:bg-primary-900 first:rounded-l-lg last:rounded-r-lg', 'title': name },
    crel('div', { 'class': text })
  )
  return element
}

function tweet () {
  let selectedText = window.getSelection().toString()
  console.log('tweeett:' + selectedText)
  window.open('https://twitter.com/intent/tweet?via=Spext_App&text=' + selectedText)
}

function shareOnFacebook () {
  let selectedText = window.getSelection().toString()
  console.log('tweeett:' + selectedText)
  window.open('https://www.facebook.com/sharer/sharer.php?u=http://spext.co&quote=' + selectedText)
}

function shareAsVideo (state) {
  let slice = state.selection.content()
  // console.log('slice is', slice)
  let json = getSliceContent(slice)
  // console.log('json is', json)
  let res = []
  json.forEach(function (item) {
    res.push([item.text, item.start, item.end])
  })
  // console.log('res is', res)
  store.dispatch('modal/showShareModal', res)
}

function showCommentBox (state, dispatch, view) {
  document.getElementById('selectionToolbar').style.display = 'none'
  store.state.comment.newComment = {
    id: 'ct-' + uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1'),
    pos: window.pageYOffset + view.coordsAtPos(state.selection.from).top - 130
  }
}
