<template>
  <div class="relative flex w-full h-full bg-primary-200">
    <div
      class="h-full transition-all duration-400 ease-in-out"
      :style="{'background-color': waveColor, width: `${progress}%`}"
    />
  </div>
</template>

<script>
export default {
  name: 'ProgressWaveform',
  data: function() {
    return {
      currentProgress: 25
    }
  },
  props: {
    progress: {
      type: Number,
      default: 0
    },
    waveColor: {
      type: String,
      default: '#949BA8'
    },
    currentTime: {
      type: Number,
      default: 200
    },
    totalTime: {
      type: Number,
      default: 50
    }
  },
  mounted: function() {
    // if (this.progress > 0) this.currentProgress = this.progress
  },
  watch: {
    // currentTime: function(newVal) {
    //   this.currentProgress = newVal * 100 / this.totalTime
    // }
  }
}
</script>

<style>

</style>
