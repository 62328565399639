import { Plugin } from 'prosemirror-state'
import { moveCursorToBoundary } from '../util/utility'
// import { addMarkTranscript } from '../util/utilityTranscript'
import { createSilenceNode, createSilenceNodeWithMusicMark } from '../Nodes/silenceNode'
import store from '@/services/store'
/**
 * When user enter some text from the keyboard
 */
export function handleText (canEdit = false) {
  return new Plugin({
    props: {
      handleTextInput: function (view, from, to, text) {
        console.log('textEditHappening', view, from, to, text)
        if (window.mode === 'editTranscript') {
          view.dispatch(
            view.state.tr.insert(
              view.state.selection.head,
              view.state.schema.text(text, [
                view.state.schema.mark('transcript')
              ])
            ))
          return true
          // this is where we add silence node when user click spacebar
        } else {
          // these two conditions are not same and refer to different char which represent space
          if (text.includes(' ') || text.includes(' ')) { // second condition checks for space is nonbreaking space(uni- 160)
            moveCursorToBoundary(view)
            let newPosition = view.state.selection.head
            let node = view.state.doc.resolve(newPosition)
            let isMusicBefore = false
            if (node.nodeBefore != null) {
              let marks = node.nodeBefore.marks
              marks.forEach(mark => {
                if (mark.type.name === 'note' || (mark.attrs != null && mark.attrs.uid != null && mark.attrs.uid.includes('music-'))) {
                  isMusicBefore = mark.attrs.note_uid
                  if (!isMusicBefore) {
                    isMusicBefore = mark.attrs.uid.split('music-')[1]
                  }
                }
              })
            }
            let silenceNode = null
            if (isMusicBefore) {
              silenceNode = createSilenceNodeWithMusicMark(view, isMusicBefore) // create new nodes
            } else {
              silenceNode = createSilenceNode(view)
            }
            view.dispatch(
              view.state.tr.insert(
                newPosition,
                silenceNode
              ).setMeta('edited', true)
            )
            // when + or = is clicked we show add file popover
          } else if (text.includes('+') || text.includes('=')) {
            store.dispatch('dialogs/openModal', { name: 'AddFile',
              props: {
                soundMode: true
              } })
          } else {
            view.dispatch(view.state.tr.setMeta('addTextAttempt', true)) // trigger warning of audio editor
          }
          return true
        }
      },
      ...!canEdit ? {
        handleKeyDown: function (view, event) {
          return true
        }
      } : {}
    }

  })
}
