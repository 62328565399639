import { Plugin } from 'prosemirror-state'
import { cut } from '../commands/cut'
/**
 * cut command
 */
export function handleCut (isUsedInProject = false) {
  return new Plugin({
    props: {
      handleDOMEvents: {
        cut: function (view, event) {
          cut(view, event, isUsedInProject)
        }

      }
    }
  })
}
