import { makeSelection, addMarkDeleted, calculateTime, moveCursorToLeftBoundary, getTextSelectionForLocation, makeSelectionPrevWord } from '../util/utility'
import { TextSelection, AllSelection } from 'prosemirror-state'
/**
 * Edit media mode -
 * action to perfrom when user clicks backspace while not selected something
 * as well as while selected something
 * Used in chain commands in proseEditor.js - backspace and delete editMediaKeymap
 */

export function remove (state, dispatch, view) {
  /**
   * action to perfrom when user clicks backspace when selected something
   */
  // console.log('remove pressed')
  localStorage.setItem('onBoardingEditTranscriptFirstPopover', true)
  const doc = view.state.doc
  const anchor = doc.resolve(view.state.selection.anchor)
  const head = doc.resolve(view.state.selection.head)
  if (anchor.pos === head.pos) {
    // nothing is selected
    return false
  }
  const slice = view.state.selection.content() // get selection
  let time = calculateTime(slice, -1) // calculate the time

  if (view.state.selection instanceof TextSelection || view.state.selection instanceof AllSelection) { // TextSelection is prosemirror class
    for (let i = 0; i < view.state.selection.ranges.length; i++) {
      let { $from, $to } = view.state.selection.ranges[i]
      console.log('TCL: removeOnBackspace -> $from, $to', $from, $to)
      addMarkDeleted(
        { key: 'remove', value: time },
        view.state,
        view.dispatch,
        $from.pos,
        $to.pos
      )
    }
  }
  moveCursorToLeftBoundary(view)
  return true
}

export function removeForLocation (from, to, view) {
  /**
   * depreciated
   */
  // console.log('remove pressed 4')

  view.dispatch(view.state.tr.setSelection(getTextSelectionForLocation(from, to, view)))
  const slice = view.state.selection.content()
  let time = calculateTime(slice, -1)

  if (view.state.selection instanceof TextSelection) {
    for (let i = 0; i < view.state.selection.ranges.length; i++) {
      let { $from, $to } = view.state.selection.ranges[i]
      addMarkDeleted(
        { key: 'remove', value: time },
        view.state,
        view.dispatch,
        $from.pos,
        $to.pos
      )
    }
  }
  return true
}

export function removeThisWord (state, dispatch, view) {
  /**
   * When no selection and backspace/delete is used
   * It will make selection ( select the current word) and delete the word
   */
  const doc = view.state.doc
  const anchor = doc.resolve(view.state.selection.anchor)
  const head = doc.resolve(view.state.selection.head)
  if (anchor.nodeBefore != null && anchor.nodeBefore.attrs != null && anchor.nodeBefore.attrs.fadeIn != null) { // music head
    // view.dispatch(
    //   view.state.tr.setMeta('addToHistory', false).delete(anchor.pos - anchor.nodeBefore.nodeSize, anchor.pos)
    // )
    moveCursorToLeftBoundary(view)
    return true
  }
  if (anchor.nodeBefore === null || (anchor.textOffset === 0 && head.textOffset === 0)) {
    return false
  }
  if (anchor.pos === head.pos) {
    // nothing is selected select the word
    makeSelection(view.state, view.dispatch)
  }
  const slice = view.state.selection.content()
  let time = calculateTime(slice, -1)

  if (view.state.selection instanceof TextSelection) {
    for (let i = 0; i < view.state.selection.ranges.length; i++) {
      let { $from, $to } = view.state.selection.ranges[i]
      addMarkDeleted(
        { key: 'remove', value: time },
        view.state,
        view.dispatch,
        $from.pos,
        $to.pos
      )
    }
  }
  moveCursorToLeftBoundary(view)
  return true
}

export function removePrevWord (state, dispatch, view) {
  /**
   * When no selection and backspace/delete is used
   * It will make selection previous ( select the previous word) and delete the word
   */
  // console.log('remove pressed 3')
  const doc = view.state.doc

  const anchor = doc.resolve(view.state.selection.anchor)
  const head = doc.resolve(view.state.selection.head)
  if (anchor.nodeBefore === null) {
    return false
  }
  if (anchor.pos === head.pos) {
    // nothing is selected  and at the boundry select the previous word
    makeSelectionPrevWord(view.state, view.dispatch)
  }
  const slice = view.state.selection.content()
  let time = calculateTime(slice, -1)

  if (view.state.selection instanceof TextSelection) {
    for (let i = 0; i < view.state.selection.ranges.length; i++) {
      let { $from, $to } = view.state.selection.ranges[i]
      addMarkDeleted(
        { key: 'remove', value: time },
        view.state,
        view.dispatch,
        $from.pos,
        $to.pos
      )
    }
  }
  moveCursorToLeftBoundary(view)
  return true
}
