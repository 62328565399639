import { Plugin } from 'prosemirror-state'
import { dropHandler } from './dropHandler'
export function handleDrop (musicAudioBufferList) {
  return new Plugin({
    props: {
      handleDOMEvents: {
        drop: function (view, event) {
          dropHandler(view, event, musicAudioBufferList)
          return true
        }
      }
    }
  })
}
