import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

function tippyTour ({
  start,
  end,
  content,
  arrow = true,
  interactive = true
}) {
  tippy(document.querySelector(start), {
    content: content,
    arrow: arrow,
    animation: 'scale',
    interactive: interactive,
    theme: 'spext',
    onShow (instance) {
      // console.log(instance)
      let closeBtn = instance.popper.getElementsByClassName('tour-close')[0]
      closeBtn.onclick = function () {
        console.log('here')
        instance.destroy()
      }
      $(end).on('click', function () {
        // console.log('tour', vm.tour)
        instance.destroy()
      })
      return true
    },
    onHide (instance) {
      return false
    }
  }).show()
}

function getContent ({
  type = 'tippyTourIntroPopover',
  text = ''
}) {
  const elm = document.getElementById(type)
  if (!elm) return ''
  return elm.innerHTML.replace('content-here', text)
}

export function tippyTourAddClickPlay (isMobile) {
  let text = isMobile ? 'Hi, make sure your phone<br>is not in silent mode.<br><br>Click play to edit a sample file' : 'Click play to edit a sample file'
  let content = getContent({
    text: text
  })
  const element = document.querySelector('.audio-controls')
  if (!content) return
  tippy(element, {
    content: content,
    arrow: true,
    animation: 'scale',
    interactive: false,
    theme: 'spext',
    trigger: 'manual',
    onShow (instance) {
      document && document.body && document.body.classList.add('hide-tooltips')
      element.onclick = function () {
        instance.destroy()
      }
      $('body').keyup(function(e) {
        if (e.keyCode === 32) {
          instance.destroy()
        }
      })
      return true
    },
    onHide(instance) {
      document && document.body && document.body.classList.remove('hide-tooltips')
    }
  }).show()
}

export function tippyTourDeleteAudio () {
  let text = '💡 Select the ‘uh’ & hit delete'
  let content = getContent({
    text: text
  })

  const element = document.querySelector('.footershadow')
  const hideElement = document.querySelector('.homeprose')
  if (!content) return
  const instance = tippy(element, {
    content: content,
    arrow: false,
    animation: 'scale',
    interactive: false,
    theme: 'spext',
    trigger: 'manual',
    onShow (instance) {
      document && document.body && document.body.classList.add('hide-tooltips')
      hideElement.onclick = function () {
        instance.destroy()
      }
      return true
    },
    onHide(instance) {
      document && document.body && document.body.classList.remove('hide-tooltips')
      return false
    }
  })

  instance && instance.show()
}

export function tippyTourHearTheCut () {
  let text = 'Click play to hear the cut'
  let content = getContent({
    text: text
  })
  const element = document.querySelector('.audio-controls')
  if (!content) return
  const instance = tippy(element, {
    content: content,
    arrow: true,
    animation: 'scale',
    interactive: false,
    theme: 'spext',
    trigger: 'manual',
    onShow (instance) {
      document && document.body && document.body.classList.add('hide-tooltips')
      element.onclick = function () {
        instance.destroy()
      }
      $('body').keyup(function(e) {
        if (e.keyCode === 32) {
          instance.destroy()
        }
      })
      return true
    },
    onHide(instance) {
      document && document.body && document.body.classList.remove('hide-tooltips')
    }
  })

  instance && instance.show()
}

export function tippyTourNoiseReduction () {
  let text = 'Click to turn noise reduction on'
  let content = getContent({
    text: text
  })
  const element = document.querySelector('#reduceNoiseButton')
  const instance = tippy(element, {
    content: content,
    arrow: true,
    animation: 'scale',
    interactive: false,
    theme: 'spext',
    trigger: 'manual',
    onShow (instance) {
      document && document.body && document.body.classList.add('hide-tooltips')
      element.onclick = function () {
        instance.destroy()
      }
      return true
    },
    onHide(instance) {
      document && document.body && document.body.classList.remove('hide-tooltips')
    }
  })

  instance && instance.show()
}

export function tippyTourSignUp () {
  let text = 'Click here to sign up'
  let content = getContent({
    text: text
  })
  tippyTour({
    start: '#signUpTour',
    end: '#signUpTour',
    content: content
  })
}
