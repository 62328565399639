<template>
  <div class="container w-full h-full">
    <div
      v-for="(wave, i) in waveformData"
      :key="i"
      class="waveLine"
      :style="{
          'background':`${waveColor}80`,
          'height': `${((200/waveformData.length)*(i) +(wave * 50 / waveMax))/3}%`,
          'width': `${((200/waveformData.length)*(i) + (wave * 50 / waveMax))/3}%`,
        }"
    />
  </div>
</template>

<script>
export default {
  name: 'CircleAnimation',
  props: {
    waveColor: {
      type: String,
      default: '#949BA8'
    },
    scale: {
      type: Number,
      default: 0.21111
    },
    waveformData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    waveMax: function () {
      return (Math.max(...this.waveformData) || 50)
    }
  }
}
</script>

<style scoped lang="css">
.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.waveLine {
  border-radius: 50%;
  position: absolute;
  max-height: 83.33%;
  max-width: 83.33%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: all 0.1s linear;
}
</style>
