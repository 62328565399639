<template>
  <div
    class="transition ease-in-out duration-300"
    v-show="!element.hidden"
    :style="style"
  >
    <ProgressWave
      v-if="type === 'one'"
      :scale="scale"
      :progress="progress"
      :waveColor="waveColor"
      :currentTime="currentTime"
      :totalTime="totalTime"
    />

    <div v-if="type === 'two'" class="flex items-center justify-center w-full h-full">
      <SineWave
        :scale="scale"
        :waveformData="[(waveformData.reduce((partial_sum, a) => partial_sum + a, 0)) /waveformData.length ] || []"
        :waveColor="waveColor"
        :isPlaying="isPlaying"
      />
    </div>

    <div v-if="type === 'three'" class="flex items-center justify-center w-full h-full">
      <CircularWave
        :scale="scale"
        :waveformData="[
          waveformData[0],
          waveformData[12],
          waveformData[15],
          waveformData[20],
          waveformData[26],
          waveformData[31],
        ]"
        :waveColor="waveColor"
      />
    </div>

    <div
      v-if="type === 'four'"
      class="flex items-end justify-center w-full h-full"
    >
      <LayerWave
        :scale="scale"
        :waveformData="waveformData"
        :waveColor="waveColor"
      />
    </div>

    <div v-if="type === 'five'" class="flex items-center justify-center w-full h-full">
      <StringWave
        :scale="scale"
        :waveformData="waveformData"
        :waveColor="waveColor"
      />
    </div>

    <div v-if="type === 'six'" class="w-full h-full">
      <LinesWave
        :scale="scale"
        :waveformData="waveformData "
        :waveColor="waveColor"
      />
    </div>
  </div>
</template>

<script>
import SineWave from './Waves/SineWave.vue'
import CircularWave from './Waves/CircularWave.vue'
import LinesWave from './Waves/LinesWave.vue'
import StringWave from './Waves/StringWave.vue'
import LayerWave from './Waves/LayerWave.vue'
import ProgressWave from './Waves/ProgressWave.vue'

export default {
  name: 'AudioWave',
  components: {
    SineWave,
    CircularWave,
    LinesWave,
    StringWave,
    LayerWave,
    ProgressWave
  },
  props: {
    element: Object,
    isPlaying: {
      type: Boolean,
      default: false
    },
    scale: {
      type: Number,
      default: 1
    },
    currentTime: {
      type: Number,
      default: 0
    },
    totalTime: {
      type: Number,
      default: 1000
    },
    progress: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    waveformInterval: null,
    waveformData: [112.84020233154297, 90.67565155029297, 85.04977416992188, 73.63545227050781, 32.41103744506836, 66.17774200439453, 51.123046875, 65.50215148925781, 93.47029113769531, 81.38009643554688, 116.3044204711914, 72.50342559814453, 63.71933364868164, 75.23441314697266, 31.274625778198242, 32.943077087402344, 82.90155792236328, 62.78493881225586, 18.04741859436035, 51.8176383972168, 20.096820831298828, 37.28361129760742, 40.66984176635742, 69.02503204345703, 26.647010803222656, 97.50051879882812, 95.6621322631836, 36.10494613647461, 5.966311454772949, 2.0853474140167236, 30.516693115234375, 1.9834959506988525],
    animationFrame: null
  }),
  computed: {
    meta: function() {
      return this.element.meta
    },
    type: function() {
      return this.element.waveType || 'four'
    },
    waveColor: function() {
      return this.element.waveColor || '#000228'
    },
    style: function() {
      const { size, position, cover, style } = this.element
      const { x, y } = position
      let transform = ''
      if (x) transform += 'translateX(-50%) '
      if (y) transform += 'translateY(50%)'
      const styles = {
        position: 'absolute',
        ...size,
        ...cover ? {} : { transform },
        ...style,
        ...x ? { left: `${x}%` } : {},
        ...y ? { bottom: `${y}%` } : {}
      }

      return styles
    }
  },
  methods: {
    getWaveformData: function() {
      this.waveformData = [...(window._spext_waveform.getValue() || []).map(t => 400 * Math.abs(t))]

      // setTimeout(() => window.requestAnimationFrame(this.getWaveformData), 70)
    }
  },
  watch: {
    isPlaying: function(val, oldVal) {
      if (!oldVal && val === true && window._spext_waveform) {
        // this.animationFrame = window.requestAnimationFrame(this.getWaveformData)
        this.waveformInterval = setInterval(() => {
          this.waveformData = [...(window._spext_waveform.getValue() || []).map(t => 400 * Math.abs(t))]
        }, 120)
      } else {
        // window.cancelAnimationFrame(this.animationFrame)
        clearInterval(this.waveformInterval)
      }
    }
  }
}
</script>

<style>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
