import { Plugin } from 'prosemirror-state'
import { moveCursorToBoundary, calculateTime, isNoteSymbol, getNoteId } from '../util/utility'
import { Slice, Fragment } from 'prosemirror-model'
import { schema } from '../Schema/schema'
import { getNoteIndex } from '@/view/voiceEditor/proseEditor/util/editorSearchUtility'
import store from '@/services/store'
/**
 * If user has copied something from outside the doc,
 * then don't allow them to paste
 * If user has copied from doc only then first update cursor position & then paste
 */
export function handlePaste (isUsedInProject = false) {
  return new Plugin({
    props: {
      handlePaste: function (view, event, slice) {
        // console.log('called')
        if (isUsedInProject || slice.content.content[0].isText) {
          // * outsideText
          return true
        }
        // * move cursor to boundary(place where text needs to be placed)
        // * then paste text
        store.dispatch('blockmode/close')
        moveCursorToBoundary(view)
        let siblingNode = view.state.doc.nodeAt(view.state.selection.anchor)
        let nodeBefore = null
        if (!siblingNode) { // end of paragraph case
          nodeBefore = view.state.doc.nodeAt(view.state.selection.anchor - 2)
        }
        let noteUid = null
        if (siblingNode && siblingNode.attrs.type === 'endMusic') {
          noteUid = siblingNode.attrs.uid
        } else if ((siblingNode && isNoteSymbol(siblingNode.marks)) || (nodeBefore && isNoteSymbol(nodeBefore.marks))) {
          if (siblingNode && isNoteSymbol(siblingNode.marks)) {
            noteUid = getNoteId(siblingNode.marks)
          } else {
            noteUid = getNoteId(nodeBefore.marks)
          }
        }
        let time = calculateTime(slice, 1)
        slice.content.forEach(function (node, offset, index) {
          node.forEach(function (subNode, offset, index) {
            subNode.marks.splice(0, 0, view.state.schema.mark('pasted')) // apply mark
            if (isNoteSymbol(subNode.marks)) {
              let noteMarkIndex = getNoteIndex(subNode.marks)
              subNode.marks.splice(noteMarkIndex, 1)
            }
            if (noteUid) {
              subNode.marks.push(view.state.schema.mark('note', {
                class: 'note',
                note_uid: noteUid
              }))// apply mark
            }
            // if (subNode.text === '_ ') {
            //   subNode.marks.splice(0, 0, view.state.schema.mark('silence'))
            // }
          })
        })
        view.dispatch(view.state.tr.setMeta('pastedTime', time)) // update time on the screen
        if (time > 0) {
          return false
        } else {
          return true
        }
      },
      // The below function disallows any plain text pasting
      transformPastedText() {
        return ''
      }
    }
  })
}

/**
 * used in editTranscript
 * when pasting content dont paste useless content
 */

const dontPaste = ['deleted', 'silence', 'hideTranscript', 'nonText', 'note']
const fillerWords = ['uh ', 'um ']
export function transformPasted (view) {
  return new Plugin({
    props: {
      transformPasted: function (slice) {
        // console.log('slice', slice.content.textBetween(0, slice.size))
        let children = []
        slice.content.descendants(function (node, start, parent, index) {
          // console.log('node', node.toJSON())
          // console.log('here', node, node.type.name, start, parent, index)
          if (node.type.name === 'text' && parent.type.name === 'paragraph') {
            let dirty = node.marks.some(function (mark) {
              return dontPaste.indexOf(mark.type.name) >= 0
            })
            if (!dirty && fillerWords.indexOf(node.text) < 0) {
              node.marks = []
              node.marks.push(schema.mark('transcript')) // add mark here
              children.push(node)
            }
          }
        })
        // console.log('children', children)
        slice = new Slice(Fragment.from(children), 1, 1)
        // console.log('slice', slice)
        // temp.content.textBetween(0, temp.size)
        // slice = new Slice(Fragment.from(view.state.schema.text(text)), 1, 1)
        return slice
      }
    }
  })
}
