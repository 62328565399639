var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"linewaves w-full h-full"},[_vm._l((_vm.waveScales),function(s,i){return _c('div',{key:i,staticClass:"waveLine",style:({
      'min-height': ((s * 100 / _vm.waveMax) + "%"),
      'max-height': ((s * 100 / _vm.waveMax) + "%"),
      'min-width': ((14 * _vm.scale) + "px"),
      'max-width': ((14 * _vm.scale) + "px"),
      'margin-right': ((18 * _vm.scale) + "px"),
      'border-radius': ((8 * _vm.scale) + "px"),
      'background': _vm.waveColor
    })})}),_vm._v(" "),_vm._l((_vm.waveScales),function(s,i){return _c('div',{key:i,staticClass:"waveLine",style:({
      'min-height': ((s * 100 / _vm.waveMax) + "%"),
      'max-height': ((s * 100 / _vm.waveMax) + "%"),
      'min-width': ((14 * _vm.scale) + "px"),
      'max-width': ((14 * _vm.scale) + "px"),
      'margin-right': ((18 * _vm.scale) + "px"),
      'border-radius': ((8 * _vm.scale) + "px"),
      'background': _vm.waveColor
    })})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }