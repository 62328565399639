<template>
  <div class="sinewave w-full h-full">
    <svg viewBox="0 0 390 100" class="chart">
      <path
        fill="None"
        id="path-svg"
        :stroke="waveColor"
        stroke-width="1px"
        :d="pathD1"
      ></path>
      <path
        fill="None"
        id="path-svg"
        :stroke="`${waveColor}A8`"
        stroke-width="1px"
        :d="pathD2"
      ></path>
      <path
        fill="None"
        id="path-svg"
        :stroke="`${waveColor}63`"
        stroke-width="1px"
        :d="pathD3"
      ></path>

      <path
        fill="None"
        id="path-svg"
        :stroke="waveColor"
        stroke-width="1px"
        :d="pathD4"
      ></path>
      <path
        fill="None"
        id="path-svg"
        :stroke="`${waveColor}A8`"
        stroke-width="1px"
        :d="pathD5"
      ></path>
      <path
        fill="None"
        id="path-svg"
        :stroke="`${waveColor}63`"
        stroke-width="1px"
        :d="pathD6"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Sine',
  data() {
    return {
      amplitude1: 0.9,
      amplitude2: 0.6,
      amplitude3: 0.3,
      offsetleft: 0,
      offsetright: 0,
      var2: 2,
      var3: 4,
      interval: null
    }
  },
  props: {
    isPlaying: {
      type: Boolean,
      default: false
    },
    waveColor: {
      type: String,
      default: '#949BA8'
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 50
    },
    scale: {
      type: Number,
      default: 0.21111
    },
    waveformData: {
      type: Array,
      default: () => [0]
    }
  },
  methods: {
    addOffset() {
      this.offsetleft -= 0.3
      this.offsetright += 0.3
      this.var2 += 0.01
      this.var3 += 0.02
      if (this.interval) clearInterval(this.interval)
      this.interval = setInterval(
        this.addOffset,
        Math.min(100 / this.amplitude, 10)
      )
    }
  },
  computed: {
    pathD1: function () {
      var d1 = 'M0 50 '
      for (let x = -2; x <= 2; x += 0.02) {
        const y =
          this.amplitude1 *
          Math.sin(8 * x - this.offsetleft / 2) *
          (4 / (4 + x ** 2)) ** 4
        d1 += `${(x + 2) * 50} ${y * 20 + 50} `
      }
      return d1
    },
    pathD2: function () {
      var d2 = 'M0 50 '
      for (let x = -2; x <= 2; x += 0.02) {
        const y =
          this.amplitude2 *
          0.66 *
          Math.sin(8 * x - (this.offsetleft - this.var2) / 2) *
          (4 / (4 + x ** 2)) ** 4
        d2 += `${(x + 2) * 50} ${y * 20 + 50} `
      }
      return d2
    },
    pathD3: function () {
      var d3 = 'M0 50 '
      for (let x = -2; x <= 2; x += 0.02) {
        const y =
          this.amplitude3 *
          0.33 *
          Math.sin(8 * x - (this.offsetleft - this.var3) / 2) *
          (4 / (4 + x ** 2)) ** 4
        d3 += `${(x + 2) * 50} ${y * 20 + 50} `
      }
      return d3
    },
    pathD4: function () {
      var d1 = 'M200 50 '
      for (let x = -2; x <= 2; x += 0.02) {
        const y =
          this.amplitude1 *
          Math.sin(8 * x - (this.offsetright) / 2) *
          (4 / (4 + x ** 2)) ** 4
        d1 += `${(x + 2) * 50 + 190} ${y * 20 + 50} `
      }
      return d1
    },
    pathD5: function () {
      var d2 = 'M200 50 '
      for (let x = -2; x <= 2; x += 0.02) {
        const y =
          this.amplitude2 *
          0.66 *
          Math.sin(8 * x - (this.offsetright + this.var2) / 2) *
          (4 / (4 + x ** 2)) ** 4
        d2 += `${(x + 2) * 50 + 190} ${y * 20 + 50} `
      }
      return d2
    },
    pathD6: function () {
      var d3 = 'M200 50 '
      for (let x = -2; x <= 2; x += 0.02) {
        const y =
          this.amplitude3 *
          0.33 *
          Math.sin(8 * x - (this.offsetright + this.var3) / 2) *
          (4 / (4 + x ** 2)) ** 4
        d3 += `${(x + 2) * 50 + 190} ${y * 20 + 50} `
      }
      return d3
    }
  },
  watch: {
    waveformData: function (n) {
      this.amplitude1 = Math.max(0.1, Math.min(n[0] / 15, 2.5))
      setTimeout(() => {
        this.amplitude2 = Math.max(0.1, Math.min(n[0] / 15, 2.5))
      }, 100)
      setTimeout(() => {
        this.amplitude3 = Math.max(0.1, Math.min(n[0] / 15, 2.5))
      }, 200)
    },
    isPlaying: function (val) {
      if (val) {
        this.addOffset()
      } else {
        clearInterval(this.interval)
      }
    }
  }
}
</script>

<style scoped lang="css">
.sinewave {
  display: flex;
  align-items: center;
  justify-content: center;
}
#path-svg {
  transition: all 0.05s linear;
}
</style>
